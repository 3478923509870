import React, { useEffect, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { LogLine, OnePager } from "../../../types/fastApiTypes";
import { Button } from "../../catalyst/button";
import { ArrowPathIcon } from "@heroicons/react/16/solid";

export interface GenerateOnePagerProps {
  onePager: OnePager | null;
  status: string;
  setOnePager: React.Dispatch<React.SetStateAction<OnePager | null>>;
  generateOnePager: (payload: any) => void;
  logline: LogLine | null;
}

const GenerateOnePager: React.FC<GenerateOnePagerProps> = ({
  onePager,
  status,
  setOnePager,
  generateOnePager,
  logline,
}) => {
  const memoizedOnePager = useMemo(() => onePager, [onePager]);

  useEffect(() => {
    if (logline && onePager === null && status === "") {
      generateOnePager({ logline: logline?.log_line || "" });
    }
  }, [onePager, logline, generateOnePager, setOnePager]);

  const handleRegenerate = () => {
    if (logline) {
      setOnePager(null);
    }
  };

  return (
    <>
      <Dialog.Title as="h3" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        One Pager
        <Button color="light" className="ml-2" onClick={handleRegenerate}>
          <ArrowPathIcon className="h-4 w-4" />
        </Button>
      </Dialog.Title>
      {status !== "" && <LoadingSpinner />}
      {memoizedOnePager && (
        <div className="mt-4 max-h-[calc(90vh-200px)] overflow-y-auto">
          <DynamicForm data={memoizedOnePager} setData={setOnePager} />
        </div>
      )}
    </>
  );
};

export default GenerateOnePager;
