import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { errorAtom } from "../states/storyState.tsx";

interface ErrorModalProps {}

const ErrorModal: FC<ErrorModalProps> = () => {
  const [errorMessage, setErrorMessage] = useRecoilState(errorAtom);
  let [isOpen, setIsOpen] = useState<boolean>(false); // Explicitly defining the state variable type.

  const closeModal = (): void => {
    setErrorMessage(null);
    setIsOpen(false);
  };

  useEffect(() => {
    if (errorMessage != null) {
      setIsOpen(true);
    }
  }, [errorMessage]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    An error occurred
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{errorMessage}</p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-black
                      px-4 py-2 text-sm font-medium text-white hover:bg-sb-red-600
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ErrorModal;
