import React from "react";
import { Bar, BarChart } from "recharts";

type SparklineProps = {
  lineColor?: string;
};

const Sparkline: React.FC<SparklineProps> = ({ lineColor = "#000000" }) => {
  return (
    <BarChart
      data={[
        { value: 10 },
        { value: 15 },
        { value: 5 },
        { value: 20 },
        { value: 10 },
        { value: 10 },
        { value: 15 },
        { value: 5 },
        { value: 20 },
        { value: 10 },
      ]}
      width={100}
      height={40}
    >
      <Bar dataKey="value" fill={lineColor} />
    </BarChart>
  );
};

export default Sparkline;
