import React, { useEffect, useRef, useState } from "react";
import { formatRGBAColor } from "../../utils/color.ts";

export interface SimpleTextProps {
  text_overlay_text: string;
  text_overlay_color: string;
  text_overlay_coordinates: string;
  canvasWidth: number;
  canvasHeight: number;
  onPositionChange?: (newPosition: TextPosition) => void;
  enabledDelayedFade?: boolean;
  isTextMovable?: boolean;
}

export interface TextPosition {
  percentFromLeft: number;
  percentFromTop: number;
}

const SimpleText: React.FC<SimpleTextProps> = ({
  text_overlay_text,
  text_overlay_coordinates,
  text_overlay_color,
  canvasWidth,
  canvasHeight,
  onPositionChange,
  enabledDelayedFade = false,
  isTextMovable = true,
}) => {
  if (!text_overlay_coordinates) return null;
  if (!text_overlay_color) return null;

  const propsPosition = JSON.parse(text_overlay_coordinates) as TextPosition;
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<TextPosition>({
    percentFromLeft: propsPosition.percentFromLeft,
    percentFromTop: propsPosition.percentFromTop,
  });
  const [isSelected, setIsSelected] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(1);
  const [currentText, setCurrentText] = useState(text_overlay_text);
  const [previousText, setPreviousText] = useState("");
  const [fadeState, setFadeState] = useState<"fadeOut" | "fadeIn" | null>(null);

  const handleMouseDown = (event: React.MouseEvent) => {
    if (isTextMovable) {
      setIsDragging(true);
      setIsSelected(true);
      event.stopPropagation();
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging || !isTextMovable) return;

    const canvasRect = containerRef.current?.getBoundingClientRect();
    const textRect = textRef.current?.getBoundingClientRect();
    if (!canvasRect || !textRect) return;

    // Calculate the new position relative to the canvas dimensions
    const mouseX = event.clientX - canvasRect.left;
    const mouseY = event.clientY - canvasRect.top;

    const newLeft = (mouseX / canvasRect.width) * canvasWidth;
    const newTop = (mouseY / canvasRect.height) * canvasHeight;

    // Ensure the text stays within the bounds of the canvas, accounting for text dimensions
    const constrainedLeft = Math.max(
      0,
      Math.min((newLeft / canvasWidth) * 100, 100 - (textRect.width / canvasWidth) * 100),
    );
    const constrainedTop = Math.max(
      0,
      Math.min((newTop / canvasHeight) * 100, 100 - (textRect.height / canvasHeight) * 100),
    );

    const newPosition = {
      percentFromLeft: constrainedLeft,
      percentFromTop: constrainedTop,
    };

    setPosition(newPosition);

    // Notify the parent about the position change
    if (onPositionChange) {
      onPositionChange(newPosition);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleCanvasMouseDown = () => {
    setIsSelected(false);
  };

  useEffect(() => {
    const initPosition = () => {
      setPosition({
        percentFromLeft: propsPosition.percentFromLeft,
        percentFromTop: propsPosition.percentFromTop,
      });
    };

    initPosition();
  }, [JSON.stringify(propsPosition)]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    if (enabledDelayedFade && text_overlay_text !== currentText) {
      setFadeState("fadeOut");
      setPreviousText(currentText);
      setOpacity(0);

      const fadeOutTimeout = setTimeout(() => {
        setFadeState("fadeIn");
        setCurrentText(text_overlay_text);
        setOpacity(1);
      }, 500);

      return () => {
        clearTimeout(fadeOutTimeout);
      };
    } else {
      setCurrentText(text_overlay_text);
    }
  }, [text_overlay_text, enabledDelayedFade]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: 10,
      }}
      onMouseDown={handleCanvasMouseDown}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          ref={containerRef}
          style={{
            width: canvasWidth,
            height: canvasHeight,
            position: "relative",
            whiteSpace: "pre-wrap",
          }}
        >
          <div
            ref={textRef}
            style={{
              position: "absolute",
              left: `${position.percentFromLeft}%`,
              top: `${position.percentFromTop}%`,
              userSelect: isSelected && isTextMovable ? "text" : "none",
              color: formatRGBAColor(text_overlay_color),
              fontWeight: "bold",
              pointerEvents: "auto",
              cursor: isTextMovable ? "grab" : "auto",
              border: isSelected && isTextMovable ? "2px dashed #000" : "none",
              opacity: opacity,
              transition: enabledDelayedFade ? "opacity 0.5s ease-in-out" : "none",
            }}
            className="text-7xl font-sans"
            onMouseDown={handleMouseDown}
          >
            {fadeState === "fadeOut" ? previousText : currentText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleText;
