import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../../catalyst/button.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import GenerateImages from "./GenerateImages.tsx";

export interface GenerateImagesModalProps {
  prompt: string | null;
}

const GenerateImagesModal: React.FC<GenerateImagesModalProps> = ({ prompt }) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    handleClose,
    images,
    imageStatus,
    setImages,
    generateImages,
  } = useGenerateSteps();

  const maxStepIndex = 1;

  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <GenerateImages
            status={imageStatus}
            generateImages={generateImages}
            images={images}
            setImages={setImages}
            prompt={prompt}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-soehne" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {stepIndex > 0 && (
                      <Button
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </Button>
                    )}
                    {stepIndex < maxStepIndex && (
                      <Button onClick={() => setStepIndex(stepIndex + 1)}>Next</Button>
                    )}
                  </div>
                  <Button color="light" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateImagesModal;
