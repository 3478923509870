import { FormEvent, useState } from "react";
import { supabase } from "../vendor/supabaseClient.ts";
import SvgLogo from "../components/SvgLogo.tsx";
import { Input } from "../components/catalyst/input.tsx";
import { Button } from "../components/catalyst/button.tsx";

export default function AuthScreen() {
  const [email, setEmail] = useState<string>("");
  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {},
    });

    if (error) {
      alert(error.message);
    } else {
      alert("Check your email for the login link!");
    }
  };

  return (
    <main className="grow bg-zinc-950">
      <section className="relative h-screen">
        {/* Illustration */}
        <div
          className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none "
          aria-hidden="true"
        >
          <img
            src={"auth-illustration.svg"}
            className="max-w-none z-20"
            width={1440}
            height={450}
            alt="Page Illustration"
          />
        </div>

        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            {/* Page header */}
            <div className="max-w-3xl mx-auto text-center pb-12">
              {/* Logo */}
              <div className="flex justify-center items-center ">
                <SvgLogo />
              </div>
              {/* Page title */}
              <h1 className="text-4xl bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                Welcome
              </h1>
            </div>

            {/* Form */}
            <div className="max-w-sm mx-auto">
              <form onSubmit={handleLogin}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm text-slate-300 font-medium mb-1"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Input
                      className="form-input w-full"
                      type="email"
                      required
                      placeholder="Your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <Button type="submit" className="w-full shadow-sm group">
                    Sign In{" "}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
