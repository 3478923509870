// components/imageEffects/CustomEffect.tsx
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import { isMobile } from "react-device-detect";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import {
  IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC,
  IMAGE_EFFECT_REACT_FROM_VOICE,
} from "../../constants/constant.ts";
import useDialogueManager from "../../hooks/useDialogueManager.ts";
import { useRecoilState } from "recoil";
import { lastImageEffectConfigState } from "../../states/effectState.ts";
import { buildControlsIfAdmin } from "../../utils/imageEffect.ts";

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

export const defaultCustomEffectControlParams: CustomEffectControlParams = {
  scale: 1,
  shift: 1,
  reactToMouseMove: true,
  reactToMusic: true,
  enableDistortionOnMove: true,
  reactToMusicFrom: IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC,
  mouseRadius: 0.3,
  mouseStrength: 0.2,
  bassStrength: 0.05,
  midStrength: 0.1,
  trebleStrength: 0.15,
  bassDivider: 10,
  midDivider: 75,
  trebleDivider: 150,
};

export interface CustomEffectControlParams {
  scale: number;
  shift: number;
  reactToMouseMove: boolean;
  reactToMusic: boolean;
  enableDistortionOnMove: boolean;
  reactToMusicFrom:
    | typeof IMAGE_EFFECT_REACT_FROM_VOICE
    | typeof IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC;
  mouseRadius: number;
  mouseStrength: number;
  bassStrength: number;
  midStrength: number;
  trebleStrength: number;
  bassDivider: number;
  midDivider: number;
  trebleDivider: number;
}

export interface CustomEffectProps {
  composer: EffectComposer | null;
  texture: THREE.Texture;
  params?: any;
}

const Combined3DEffect: React.FC<CustomEffectProps> = ({ composer, texture, params }) => {
  const { size } = useThree();
  const uMouse = useRef(new THREE.Vector2(-10, -10));
  const uScale = useRef(0);
  const uShift = useRef(0);
  const targetScale = useRef(0);
  const targetShift = useRef(0);
  const [, setShaderParams] = useRecoilState(lastImageEffectConfigState);
  const shaderParams = params
    ? params
    : buildControlsIfAdmin({
        scale: {
          value: defaultCustomEffectControlParams.scale,
          min: 0,
          max: 5,
          step: 0.001,
        },
        shift: {
          value: defaultCustomEffectControlParams.shift,
          min: 0,
          max: 5,
          step: 0.001,
        },
        reactToMouseMove: {
          value: defaultCustomEffectControlParams.reactToMouseMove,
        },
        reactToMusic: {
          value: defaultCustomEffectControlParams.reactToMusic,
        },
        enableDistortionOnMove: {
          value: defaultCustomEffectControlParams.enableDistortionOnMove,
        },
        reactToMusicFrom: {
          value: defaultCustomEffectControlParams.reactToMusicFrom,
          options: [IMAGE_EFFECT_REACT_FROM_VOICE, IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC],
        },
        mouseRadius: {
          value: defaultCustomEffectControlParams.mouseRadius,
          min: 0,
          max: 1,
          step: 0.01,
        },
        mouseStrength: {
          value: defaultCustomEffectControlParams.mouseStrength,
          min: 0,
          max: 1,
          step: 0.01,
        },
        bassStrength: {
          value: defaultCustomEffectControlParams.bassStrength,
          min: 0,
          max: 1,
          step: 0.01,
        },
        midStrength: {
          value: defaultCustomEffectControlParams.midStrength,
          min: 0,
          max: 1,
          step: 0.01,
        },
        trebleStrength: {
          value: defaultCustomEffectControlParams.trebleStrength,
          min: 0,
          max: 1,
          step: 0.01,
        },
        bassDivider: {
          value: defaultCustomEffectControlParams.bassDivider,
          min: 1,
          max: 100,
          step: 1,
        },
        midDivider: {
          value: defaultCustomEffectControlParams.midDivider,
          min: 1,
          max: 100,
          step: 1,
        },
        trebleDivider: {
          value: defaultCustomEffectControlParams.trebleDivider,
          min: 1,
          max: 100,
          step: 1,
        },
      });
  const memoizedSetShaderParams = useCallback(() => {
    setShaderParams(shaderParams);
  }, [JSON.stringify(shaderParams), setShaderParams]);

  useEffect(() => {
    memoizedSetShaderParams();
  }, [memoizedSetShaderParams]);

  const { getFrequencyData } =
    params && shaderParams.reactToMusicFrom === IMAGE_EFFECT_REACT_FROM_VOICE
      ? useDialogueManager()
      : useBackgroundMusicManager();

  const customPass = useMemo(() => {
    const customEffect = {
      uniforms: {
        tDiffuse: { value: texture },
        resolution: { value: new THREE.Vector2(1, size.height / size.width) },
        uMouse: { value: uMouse.current },
        uVelo: { value: 0 },
        uScale: { value: uScale.current },
        uShift: { value: uShift.current },
        uReactToMouseMove: { value: shaderParams.reactToMouseMove },
        uReactToMusic: { value: shaderParams.reactToMusic },
        uEnableDistortionOnMove: { value: shaderParams.enableDistortionOnMove },
        uMouseRadius: { value: shaderParams.mouseRadius },
        uMouseStrength: { value: shaderParams.mouseStrength },
        uBassStrength: { value: shaderParams.bassStrength },
        uMidStrength: { value: shaderParams.midStrength },
        uTrebleStrength: { value: shaderParams.trebleStrength },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        uniform sampler2D tDiffuse;
        uniform vec2 resolution;
        varying vec2 vUv;
        uniform vec2 uMouse;
        uniform float uScale;
        uniform float uShift;
        uniform bool uReactToMouseMove; 
        uniform bool uReactToMusic;
        uniform bool uEnableDistortionOnMove;
        uniform float uMouseRadius;
        uniform float uMouseStrength;
        uniform float uBassStrength;
        uniform float uMidStrength;
        uniform float uTrebleStrength;

        float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
          uv -= disc_center;
          uv *= resolution;
          float dist = sqrt(dot(uv, uv));
          return smoothstep(disc_radius + border_size, disc_radius - border_size, dist);
        }

        void main() {
          vec2 p = vUv;
          float angle = 1.55;
          vec2 offset = uShift / 4.0 * vec2(cos(angle), sin(angle));
          
          // Clamp the texture coordinates to the valid range [0, 1]
          vec2 clampedCoords = clamp(p + offset, 0.0, 1.0);
          vec4 cr = texture2D(tDiffuse, clampedCoords);
          
          clampedCoords = clamp(p, 0.0, 1.0);
          vec4 cga = texture2D(tDiffuse, clampedCoords);
          
          clampedCoords = clamp(p - offset, 0.0, 1.0);
          vec4 cb = texture2D(tDiffuse, clampedCoords);
          
          vec4 color = vec4(cr.r, cga.g, cb.b, cga.a);

          if (uReactToMouseMove) {
            vec2 newUV = vUv;
            float c = circle(vUv, uMouse, 0.0, uMouseRadius);
            float r = texture2D(tDiffuse, newUV.xy += c * (uMouseStrength * .5)).x;
            float g = texture2D(tDiffuse, newUV.xy += c * (uMouseStrength * .525)).y;
            float b = texture2D(tDiffuse, newUV.xy += c * (uMouseStrength * .55)).z;
            color = mix(color, vec4(r, g, b, cga.a), c);
          }
          
          if (uReactToMusic) {
            float scaleEffect = uScale * 0.1;
            float shiftEffect = uShift * 0.1;
            
            vec2 newUV = vUv;
            newUV += vec2(scaleEffect, shiftEffect);
            
            float r = texture2D(tDiffuse, newUV).r;
            float g = texture2D(tDiffuse, newUV).g;
            float b = texture2D(tDiffuse, newUV).b;
            
            color = mix(color, vec4(r, g, b, cga.a), 0.5);
          }
          
          if(uEnableDistortionOnMove) {
            float c = circle(vUv, uMouse, 0.0, 0.2);
            vec2 distortionOffset = vec2(c * 0.1);
            float r = texture2D(tDiffuse, p + distortionOffset).x;
            float g = texture2D(tDiffuse, p + distortionOffset).y;
            float b = texture2D(tDiffuse, p + distortionOffset).z;
            vec4 distortionColor = vec4(r, g, b, 1.);

            color = mix(color, distortionColor, c);
          }
          
          gl_FragColor = color;
        }
      `,
    };

    return new ShaderPass(customEffect);
  }, [size, texture, shaderParams]);

  useEffect(() => {
    if (composer) {
      composer.addPass(customPass);
    }

    const handleMouseMove = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      uMouse.current.x = clientX / window.innerWidth;
      uMouse.current.y = 1 - clientY / window.innerHeight;
      targetScale.current = (clientX - window.innerWidth / 2) / (window.innerWidth / 2);
      targetShift.current = (clientY - window.innerHeight / 2) / (window.innerHeight / 2);
    };

    const handleTouchMove = (event: TouchEvent) => {
      const { clientX, clientY } = event.touches[0];
      uMouse.current.x = clientX / window.innerWidth;
      uMouse.current.y = 1 - clientY / window.innerHeight;
      targetScale.current = (clientX - window.innerWidth / 2) / (window.innerWidth / 2);
      targetShift.current = (clientY - window.innerHeight / 2) / (window.innerHeight / 2);
    };

    const resetEffect = () => {
      targetScale.current = 0;
      targetShift.current = 0;
    };

    if (shaderParams.reactToMouseMove || shaderParams.enableDistortionOnMove) {
      if (isMobile) {
        window.addEventListener("touchmove", handleTouchMove);
        window.addEventListener("touchend", resetEffect);
      } else {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", resetEffect);
      }
    }

    return () => {
      if (composer) {
        composer.removePass(customPass);
      }

      if (shaderParams.reactToMouseMove || shaderParams.enableDistortionOnMove) {
        if (isMobile) {
          window.removeEventListener("touchmove", handleTouchMove);
          window.removeEventListener("touchend", resetEffect);
        } else {
          window.removeEventListener("mousemove", handleMouseMove);
          window.removeEventListener("mouseup", resetEffect);
        }
      }
    };
  }, [composer, customPass, shaderParams.reactToMouseMove]);

  useFrame((_, _delta) => {
    if (shaderParams.reactToMusic) {
      const frequencyData = getFrequencyData();
      if (frequencyData) {
        const bass = frequencyData.slice(0, 10).reduce((a, b) => a + b) / shaderParams.bassDivider;
        const mid = frequencyData.slice(75, 150).reduce((a, b) => a + b) / shaderParams.midDivider;
        const treble =
          frequencyData.slice(150, 300).reduce((a, b) => a + b) / shaderParams.trebleDivider;

        const bassMapped = Math.log1p(bass) * shaderParams.bassStrength;
        const midMapped = Math.sin((mid * Math.PI) / 255) * shaderParams.midStrength;
        const trebleMapped = Math.sqrt(treble / 255) * shaderParams.trebleStrength;

        uScale.current = lerp(uScale.current, bassMapped + trebleMapped, 0.1);
        uShift.current = lerp(uShift.current, midMapped, 0.1);
      }
    }

    if (shaderParams.reactToMouseMove || shaderParams.enableDistortionOnMove) {
      uScale.current = lerp(uScale.current, targetScale.current * shaderParams.scale, 0.1);
      uShift.current = lerp(uShift.current, targetShift.current * shaderParams.shift, 0.1);
      customPass.uniforms.uMouse.value = uMouse.current;
    }

    customPass.uniforms.uScale.value = uScale.current;
    customPass.uniforms.uShift.value = uShift.current;
  }, 1);

  return null;
};

export default Combined3DEffect;
