import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Button } from "../catalyst/button.tsx";
import { Tables } from "../../types/database.ts";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import { useFetchUniqueImageUrls } from "../../hooks/database/useChapter.ts";

type ImageBackgroundProps = PropsWithChildren<{
  story: Tables<"blueprint_stories_with_ratings">;
}>;

export function Carousel({ story, children }: ImageBackgroundProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const { uniqueImageUrls, error } = useFetchUniqueImageUrls(story.id || "");
  if (error != "") console.log("error", error);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % uniqueImageUrls.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [uniqueImageUrls.length]);

  const handleMouseMove = (event: MouseEvent) => {
    const el = imageRef.current;
    if (el) {
      const r = el.getBoundingClientRect();
      const x = event.clientX - (r.left + Math.floor(r.width / 2));
      const y = event.clientY - (r.top + Math.floor(r.height / 2));
      el.style.transform = `scale(1.025) translate(${x / 50}px, ${y / 50}px)`; // Preserve scale while translating
    }
  };

  const handleMouseLeave = () => {
    const el = imageRef.current;
    if (el) {
      el.style.transform = `scale(1) translate(0px, 0px)`; // Preserve scale when mouse leaves
    }
  };

  useEffect(() => {
    const el = carouselRef.current;
    if (el) {
      el.addEventListener("mousemove", handleMouseMove);
      el.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        el.removeEventListener("mousemove", handleMouseMove);
        el.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, []);

  return (
    <div className="relative h-screen overflow-hidden" ref={carouselRef}>
      <div className="absolute inset-0 -z-10">
        <div>
          <img
            ref={imageRef}
            src={uniqueImageUrls[currentImageIndex] || undefined}
            alt="Carousel Image"
            className="absolute w-full h-full object-cover transition duration-300 ease-out"
          />
        </div>
        {story?.story_logo_image && (
          <div className="absolute inset-0 flex justify-center items-center z-10">
            <img
              src={
                story?.story_logo_image
                  ? (getSupabaseImageUrl(story?.id, story?.story_logo_image) as string)
                  : ""
              }
              alt="Centered Image"
              className="w-auto h-auto max-w-[20%] max-h-[20%] object-contain "
            />
          </div>
        )}
        <div className="absolute top-[30%] md:top-[40%] ml-4 md:ml-16">
          <p className="text-white text-1xl md:text-5xl h-full w-[50%] lg:text-6xl font-bold drop-shadow-xl uppercase">
            {story.name}
          </p>
          <p className="font-soehne text-white md:text-lg mt-3 md:mt-8 w-[90%] md:w-[80%] lg:w-[50%] drop-shadow-xl">
            {story.catalogue_description}
          </p>
          <div className="flex flex-row items-center mt-3 md:mt-4 gap-3">
            <Button>More Info</Button>
          </div>
        </div>
      </div>
      <div className="relative z-0">{children}</div>
    </div>
  );
}
