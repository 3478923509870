import { createEffectComponent } from "./CreateEffectComponent.ts";

const levaParams = {
  intensity: {
    value: 0.3,
    min: 0,
    max: 2,
    step: 0.01,
  },
  displacementFactor: {
    value: 0.33,
    min: 0,
    max: 1,
    step: 0.01,
  },
  uvOffset: {
    value: 0.5,
    min: 0,
    max: 1,
    step: 0.01,
  },
};

const DissolveEffect = createEffectComponent(
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader with displacement and intensity
  `
    uniform float time;
    uniform float progress;
    uniform float intensity;
    uniform float displacementFactor;
    uniform float uvOffset;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform vec4 resolution;
    varying vec2 vUv;

    void main() {
      vec2 newUV = (vUv - vec2(uvOffset)) * resolution.zw + vec2(uvOffset);

      vec4 d1 = texture2D(texture1, newUV);
      vec4 d2 = texture2D(texture2, newUV);

      float displace1 = (d1.r + d1.g + d1.b) * displacementFactor;
      float displace2 = (d2.r + d2.g + d2.b) * displacementFactor;

      vec4 t1 = texture2D(texture1, vec2(newUV.x, newUV.y + progress * (displace2 * intensity)));
      vec4 t2 = texture2D(texture2, vec2(newUV.x, newUV.y + (1.0 - progress) * (displace1 * intensity)));

      vec4 finalTexture = mix(t1, t2, progress);
      finalTexture.a = mix(t1.a, t2.a, progress);

      gl_FragColor = finalTexture;
    }
  `,
  () => ({
    ...levaParams,
  }),
);

export default DissolveEffect;
