import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../../catalyst/button.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { Tables } from "../../../types/database.ts";

import { Badge } from "../../catalyst/badge.tsx";
import GenerateChaptersIdeas from "./GenerateChaptersIdeas.tsx";
import SaveAll from "./SaveAll.tsx";

export interface GenerateBeatModalProps {
  story: Tables<"blueprint_stories"> | null;
  characters: Tables<"blueprint_characters">[];
  scene: Tables<"blueprint_scenes"> | null;
}

const GenerateChaptersModal: React.FC<GenerateBeatModalProps> = ({ story, scene, characters }) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    handleClose,
    chapters,
    chapterStatus,
    generateChapters,
    setChapters,
  } = useGenerateSteps();

  const maxStepIndex = 1;
  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <GenerateChaptersIdeas
            status={chapterStatus}
            generateChapters={generateChapters}
            setChapters={setChapters}
            scene={scene}
            chapters={chapters}
            characters={characters}
          />
        );
      case 1:
        return (
          <SaveAll chapters={chapters} scene={scene} story={story} handleClose={handleClose} />
        );

      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-soehne" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              {scene && (
                <div className="pt-6 ml-6">
                  <Badge>{scene.name}</Badge>
                </div>
              )}
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {stepIndex > 0 && (
                      <Button
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </Button>
                    )}
                    {stepIndex < maxStepIndex && (
                      <Button onClick={() => setStepIndex(stepIndex + 1)}>Next</Button>
                    )}
                  </div>
                  <Button color="light" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateChaptersModal;
