import React, { useEffect, useState } from "react";
import StorageFileSelector, { FILE_TYPE_IMAGE } from "./StorageFileSelector.tsx";
import { Button } from "../catalyst/button.tsx";
import { supabase } from "../../vendor/supabaseClient.ts";
import { DEV_FAST_API_HTTP } from "../../vendor/config.ts";
import axios from "axios";
import { Text } from "../catalyst/text.tsx";
import { Card, CardDescription, CardHeader, CardImage, CardTitle } from "./Card.tsx";
import {
  doesAllImageTypeExistInStorage,
  getSupabaseImageUrl,
  uploadToSupabaseFileFromUrl,
} from "../../utils/image.ts";
import {
  IMAGE_TYPE_DEPTH_MAP,
  IMAGE_TYPE_ORIGINAL,
  IMAGE_TYPE_WITHOUT_BG,
} from "../../constants/constant.ts";

export interface StagedImage {
  image_url: string;
  image_type: string;
  has_version_without_background?: boolean;
  has_depth_map?: boolean;
  has_original?: boolean;
}

interface AdvancedImageUploaderProps {
  storyId: string;
  onUploadComplete: (stagedImage: StagedImage) => void;
}

interface ProcessedImageResponse {
  message: string;
  without_bg: string;
  depth_map: string;
}

export const AdvancedImageUploader: React.FC<AdvancedImageUploaderProps> = ({
  storyId,
  onUploadComplete,
}) => {
  const [stagedImage, setStagedImage] = useState<StagedImage | null>(null);
  const [status, setStatus] = useState("");
  const [isProcessed, setIsProcessed] = useState(false);
  const [processedImages, setProcessedImages] = useState<StagedImage[]>([]);

  useEffect(() => {
    processImage();
  }, [stagedImage]);

  async function processImage() {
    if (!stagedImage) return;

    const { data, error } = await supabase.storage
      .from(FILE_TYPE_IMAGE)
      .download(`${storyId}/${stagedImage.image_url}`);
    if (error) {
      setStatus("Error downloading image: " + error);
      return;
    }

    if (!(await doesAllImageTypeExistInStorage(stagedImage.image_url, storyId))) {
      const response = await uploadImageToFastAPI(data, stagedImage.image_url);
      if (response && response.error) {
        setStatus("Error uploading image:" + response.error);
        return;
      }
    } else {
      setProcessedImages([
        {
          image_url: getSupabaseImageUrl(storyId, stagedImage?.image_url) ?? "",
          image_type: IMAGE_TYPE_ORIGINAL,
        },
        {
          image_url:
            getSupabaseImageUrl(storyId, IMAGE_TYPE_WITHOUT_BG + stagedImage?.image_url) ?? "",
          image_type: IMAGE_TYPE_WITHOUT_BG,
        },
        {
          image_url:
            getSupabaseImageUrl(storyId, IMAGE_TYPE_DEPTH_MAP + stagedImage?.image_url) ?? "",
          image_type: IMAGE_TYPE_DEPTH_MAP,
        },
      ]);
    }

    setStatus("Done");
    setIsProcessed(true);
  }

  async function uploadImageToFastAPI(imageData: any, imageName: string) {
    try {
      const URL = import.meta.env.VITE_FAST_API_HTTP || DEV_FAST_API_HTTP;
      const formData = new FormData();
      formData.append("file", imageData, imageName);
      setStatus("Hardcore image processing in progress...");
      const response = await axios.post(URL + "remove_background", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Image uploaded successfully:", response.data);
      const parsedResponse: ProcessedImageResponse = response.data;
      setProcessedImages([
        {
          image_url: getSupabaseImageUrl(storyId, stagedImage?.image_url) ?? "",
          image_type: IMAGE_TYPE_ORIGINAL,
        },
        {
          image_url: parsedResponse.without_bg,
          image_type: IMAGE_TYPE_WITHOUT_BG,
        },
        {
          image_url: parsedResponse.depth_map,
          image_type: IMAGE_TYPE_DEPTH_MAP,
        },
      ]);
    } catch (error) {
      setStatus(`Error processing image:${error}`);
      return { error };
    }
  }

  async function addImages() {
    if (!stagedImage) return;

    const returnedImageObject: StagedImage = {
      image_url: stagedImage.image_url,
      image_type: IMAGE_TYPE_ORIGINAL,
      has_original: true,
    };
    if (isProcessed) {
      setStatus("Uploading...");
      const depthMap = processedImages.find((image) => image.image_type === IMAGE_TYPE_DEPTH_MAP);
      if (depthMap) {
        const depthMapUrl = await uploadToSupabaseFileFromUrl(
          storyId,
          depthMap.image_url,
          IMAGE_TYPE_DEPTH_MAP + stagedImage.image_url,
        );
        if (depthMapUrl) returnedImageObject.has_depth_map = true;
      }
      const noBackground = processedImages.find(
        (image) => image.image_type === IMAGE_TYPE_WITHOUT_BG,
      );
      if (noBackground) {
        const noBackgroundUrl = await uploadToSupabaseFileFromUrl(
          storyId,
          noBackground.image_url,
          IMAGE_TYPE_WITHOUT_BG + stagedImage.image_url,
        );
        if (noBackgroundUrl) returnedImageObject.has_version_without_background = true;
      }
      const original = processedImages.find((image) => image.image_type === IMAGE_TYPE_ORIGINAL);
      if (!original) {
        returnedImageObject.has_original = false;
      }
    }
    setStatus("");
    setProcessedImages([]);
    setIsProcessed(false);
    setStagedImage(null);
    onUploadComplete(returnedImageObject);
  }

  return (
    <>
      <StorageFileSelector
        bucketName={FILE_TYPE_IMAGE}
        storyId={storyId}
        onStorageFileSelected={(storageFileUrl) =>
          setStagedImage({ image_url: storageFileUrl, image_type: "" })
        }
      />
      {isProcessed && (
        <ul className="grid grid-cols-4 gap-4 mt-8 mb-8">
          {processedImages.map((image, index) => {
            return (
              <li key={index}>
                <Card className="sm:col-span-2">
                  <CardHeader className="pb-3">
                    <CardImage src={image.image_url} />
                    <CardTitle>{image.image_url}</CardTitle>
                    <CardDescription className="max-w-lg text-balance leading-relaxed">
                      {image.image_type}
                    </CardDescription>
                  </CardHeader>
                </Card>
              </li>
            );
          })}
        </ul>
      )}
      {stagedImage && (
        <div className="flex mt-8 items-center space-x-4">
          <Button
            className="align-bottom"
            onClick={addImages}
            disabled={processedImages.length == 0}
          >
            Add image(s) above
          </Button>
          <Text>{status}</Text>
        </div>
      )}
    </>
  );
};
