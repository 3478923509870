import React from "react";
import AutoResizeTextArea from "./AutoResizeTextArea";

interface DynamicFormProps {
  data: any;
  index?: number;
  arrayKey?: string;
  setData: React.Dispatch<React.SetStateAction<any>>;
  filterIds?: boolean;
  filterDates?: boolean;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  data,
  index,
  setData,
  arrayKey,
  filterIds = false,
  filterDates = false,
}) => {
  const handleChange = (key: string, value: string) => {
    if (arrayKey && index !== undefined) {
      setData((prev: any) => ({
        ...prev,
        [arrayKey]: prev[arrayKey].map((item: any, i: number) =>
          i === index ? { ...item, [key]: value } : item,
        ),
      }));
    } else if (index !== undefined) {
      setData((prev: any) => {
        const newArray = [...prev];
        newArray[index] = {
          ...newArray[index],
          [key]: value,
        };
        return newArray;
      });
    } else {
      setData((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const formatLabel = (key: string): string => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const shouldRenderField = (key: string, value: any): boolean => {
    if (!filterIds && !filterDates) return true;
    if (filterIds && key.toLowerCase().includes("id")) return false;
    if (filterDates && (value instanceof Date || !isNaN(Date.parse(value)))) return false;
    return true;
  };

  return (
    <div className="max-w-2xl mx-auto">
      {Object.entries(data).map(
        ([key, value]) =>
          shouldRenderField(key, value) && (
            <div key={key} className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {formatLabel(key)}
              </label>
              <AutoResizeTextArea
                value={String(value)}
                onChange={(newValue) => handleChange(key, newValue)}
              />
            </div>
          ),
      )}
    </div>
  );
};

export default DynamicForm;
