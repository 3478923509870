import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { Tables } from "../../../types/database";
import { BoltIcon } from "@heroicons/react/16/solid";
import { CHAPTER_TYPE_INTERACTIVE } from "../../../constants/constant";
import { bestFontColor } from "../../../utils/color";
import { useFetchCharacterImageUrl } from "../../../hooks/database/useChapter.ts";

interface CustomNodeProps {
  data: {
    label: string;
    chapter_type: string;
    chapter: Tables<"blueprint_chapters">;
    borderColor: string;
  };
  selected: boolean;
}

const CustomChapterFlowNode: React.FC<CustomNodeProps> = memo(({ data, selected }) => {
  const { label, chapter } = data;
  const character_id = chapter.blueprint_character_id;
  const backgroundColor = data.borderColor || "white";
  const { imageUrl } = useFetchCharacterImageUrl(
    data.chapter.blueprint_story_id,
    data.chapter.blueprint_character_id,
  );
  return (
    <div
      className={`px-4 py-2 rounded-xl shadow-md ${selected ? "border-black" : "border-white"}`}
      style={{
        backgroundColor,
        color: bestFontColor(backgroundColor),
        borderWidth: "4px",
        borderStyle: "solid",
        width: "500px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="flex" style={{ overflow: "hidden" }}>
        <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
          {character_id && (
            <img src={imageUrl} alt="Character" className="rounded-full w-10 h-10" />
          )}
        </div>
        <div
          className="ml-2"
          style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <div className="text-md" title={label}>
            {label}
          </div>
          {chapter.chapter_type === CHAPTER_TYPE_INTERACTIVE && (
            <BoltIcon className="h-5 w-5 text-white" aria-hidden="true" />
          )}
        </div>
      </div>
      <Handle type="target" position={Position.Top} className="w-16 !bg-teal-500" />
      <Handle type="source" position={Position.Bottom} className="w-16 !bg-teal-500" />
    </div>
  );
});

CustomChapterFlowNode.displayName = "CustomChapterFlowNode";

export default CustomChapterFlowNode;
