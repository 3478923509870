import { useEffect, useMemo, useState } from "react";
import { supabase } from "../../vendor/supabaseClient";
import { Button } from "../catalyst/button";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useGenerateSteps } from "./generateCharactersImagesModal/useGenerateSteps.ts";
import AutoResizeTextArea from "./AutoResizeTextArea.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import ImageGenerationStyleSelector from "./ImageGenerationStyleSelector.tsx";

interface ImageGeneratorUploaderButtonProps {
  bucketName: string;
  storyId: string;
  onUploadComplete: (fileUrl: string) => void;
  basePrompt: string | null;
  promptModifier: string | null;
}

export default function ImageGeneratorUploaderButton({
  bucketName,
  storyId,
  onUploadComplete,
  basePrompt,
  promptModifier,
}: ImageGeneratorUploaderButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [modifiedBasedPrompt, setModifiedBasedPrompt] = useState<string | null>(basePrompt);
  const [modifiedPromptModifier, setModifiedPromptModifier] = useState<string | null>(
    promptModifier,
  );
  const [model, setModel] = useState<string | null>(null);

  const { generateImages, imageStatus, images } = useGenerateSteps();

  const finalImagePrompt = useMemo(() => {
    return modifiedPromptModifier
      ? modifiedPromptModifier.replace("{prompt}", modifiedBasedPrompt || "")
      : modifiedBasedPrompt;
  }, [modifiedBasedPrompt, modifiedPromptModifier]);

  useEffect(() => {
    setModifiedBasedPrompt(basePrompt);
  }, [basePrompt]);

  useEffect(() => {
    setModifiedPromptModifier(promptModifier);
  }, [promptModifier]);

  const handleGenerate = async () => {
    const baseParams = {
      image_generation_prompt: finalImagePrompt,
    };
    const modelParam = model ? { model } : {};

    await generateImages({
      ...baseParams,
      ...modelParam,
    });
  };

  const handleSave = async () => {
    if (!selectedImage) return;

    setUploadStatus("Uploading...");
    const fileName = `generated-${Date.now()}.jpg`;
    const filePath = `${storyId}/${fileName}`;

    try {
      const response = await fetch(selectedImage);
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: "image/jpeg" });

      const { data, error } = await supabase.storage
        .from(bucketName)
        .upload(filePath, file, { upsert: true });

      if (error) throw error;

      if (data) {
        const publicUrl = data.path;
        onUploadComplete(publicUrl);
        setUploadStatus("Done");
        closePopup();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("Failed to upload");
    }
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <SparklesIcon className="mr-2" />
        Generate
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <div className="p-6">
              <DialogTitle className="text-lg font-medium mb-4">Generate Image</DialogTitle>
            </div>

            <div className="flex-grow overflow-y-auto p-6 pt-0">
              <AutoResizeTextArea
                className="mb-4"
                value={modifiedBasedPrompt || ""}
                onChange={(e) => setModifiedBasedPrompt(e)}
              />
              <ImageGenerationStyleSelector
                promptStyle={promptModifier || ""}
                onPromptStyleSelected={(promptStyle, model) => {
                  setModifiedPromptModifier(promptStyle);
                  if (model) {
                    setModel(model);
                  } else {
                    setModel(null);
                  }
                }}
              />
              <Button onClick={handleGenerate} className="mb-4 mt-4">
                <SparklesIcon className="mr-2" />
                Generate Images
              </Button>
              {imageStatus && <div>{imageStatus}</div>}
              {images && (
                <div className="grid grid-cols-2 gap-4 mb-4">
                  {images.image_urls.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Generated ${index + 1}`}
                      className={`w-full h-auto cursor-pointer ${selectedImage === image ? "border-4 border-blue-500" : ""}`}
                      onClick={() => setSelectedImage(image)}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <Button onClick={handleSave} disabled={!selectedImage}>
                  Save Selected Image
                </Button>

                {uploadStatus && <p>{uploadStatus}</p>}
                <Button onClick={() => closePopup()} color="light">
                  Close
                </Button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
