import React, { useEffect, useRef } from "react";
import anime from "animejs";

interface AnimatedTextProps {
  texts: string[];
  showEachTextDuring?: number;
  onAnimationComplete?: () => void;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
  texts,
  showEachTextDuring = 100,
  onAnimationComplete,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<anime.AnimeInstance | null>(null);

  useEffect(() => {
    if (textRef.current && !animationRef.current) {
      const animation = anime.timeline({
        autoplay: true,
        complete: onAnimationComplete,
        loop: false,
      });

      texts.forEach((_text, index) => {
        const inAnimation = {
          targets: textRef.current?.children[index].children,
          rotateY: [-90, 0],
          opacity: 1,
          easing: "easeOutSine",
          duration: 400,
          delay: anime.stagger(50),
        };

        const outAnimation = {
          targets: textRef.current?.children[index].children,
          rotateY: 45,
          opacity: 0,
          easing: "easeOutSine",
          duration: 200,
          delay: anime.stagger(50),
        };

        animation.add(inAnimation);

        if (index < texts.length - 1) {
          animation.add({
            duration: showEachTextDuring,
          });
          animation.add(outAnimation);
        } else {
          animation.add({
            duration: showEachTextDuring,
          });
          animation.add(outAnimation);
        }
      });

      animation.add({
        complete: () => {
          animation.restart();
        },
      });

      animationRef.current = animation;
    }
  }, [texts, showEachTextDuring, onAnimationComplete]);

  const textDivs = texts.map((text, index) => {
    const spans = text.split("").map((char, i) => (
      <span key={`${index}-${i}`} className="inline-block mx-1">
        {char === " " ? "\u00A0" : char.toUpperCase()}
      </span>
    ));

    return (
      <div key={index} className="absolute top-0 left-0 flex">
        {spans}
      </div>
    );
  });

  return (
    <div className="text-4xl font-bold text-center relative">
      <div ref={textRef}>{textDivs}</div>
    </div>
  );
};

export default AnimatedText;
