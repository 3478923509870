import React, { useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import anime from "animejs";

interface SlideEffectProps {
  textures: THREE.Texture[];
  currentImageIndex: number;
  composer: EffectComposer | null;
}

const vertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
uniform sampler2D texture1;
uniform sampler2D texture2;
uniform float progress;
uniform vec2 resolution;
uniform bool zeroToOne;

varying vec2 vUv;

void main() {
  vec2 uv = vUv;
  vec4 color1 = texture2D(texture1, uv);
  vec2 slideUV = uv;
  float slideProgress = smoothstep(0.0, 1.0, progress);
  
  if (zeroToOne) {
    slideUV.x += (1.0 - slideProgress) * (1.0 + (resolution.x / resolution.y));
  } else {
    slideUV.x += slideProgress * (1.0 + (resolution.x / resolution.y));
  }
  
  vec4 color2 = texture2D(texture2, slideUV);
  
  if (zeroToOne) {
    color2.a *= slideProgress;
  } else {
    color2.a *= progress > 0.0 ? 1.0 - slideProgress : 0.0;
  }
  
  gl_FragColor = mix(color1, color2, color2.a);
}
`;

export const SlideEffect: React.FC<SlideEffectProps> = ({
  textures,
  currentImageIndex,
  composer,
}) => {
  const { size } = useThree();
  const effectRef = useRef<ShaderPass | null>(null);
  const prevIndexRef = useRef<number>(0);

  const uniforms = useMemo(
    () => ({
      texture1: { type: "t", value: textures[0] },
      texture2: { type: "t", value: textures[1] },
      progress: { value: 0 },
      resolution: { value: new THREE.Vector2(size.width, size.height) },
      zeroToOne: { value: currentImageIndex > prevIndexRef.current },
    }),
    [textures, currentImageIndex, size],
  );

  useEffect(() => {
    const effect = {
      uniforms,
      vertexShader,
      fragmentShader,
    };
    const pass = new ShaderPass(effect);
    effectRef.current = pass;

    if (composer) {
      composer.addPass(pass);

      return () => {
        composer.removePass(pass);
      };
    }
  }, [composer, uniforms]);

  useEffect(() => {
    if (effectRef.current) {
      effectRef.current.uniforms.progress.value = 0;
      effectRef.current.uniforms.zeroToOne.value = currentImageIndex > prevIndexRef.current;
      anime({
        targets: effectRef.current.uniforms.progress,
        value: 1,
        duration: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          prevIndexRef.current = currentImageIndex;
        },
      });
    }
  }, [currentImageIndex]);

  useFrame((_, delta) => composer?.render(delta), 1);

  return null;
};
