import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tables } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import ColorPicker from "../../components/admin/ColorPicker.tsx";
import StorageFileSelector, {
  FILE_TYPE_IMAGE,
  FILE_TYPE_SOUND,
} from "../../components/admin/StorageFileSelector.tsx";
import FormField from "../../components/admin/FormField.tsx";
import {
  PAGE_STORY,
  STORY_BRANCHING_LEVELS,
  STORY_INTERACTIVITY_LEVELS,
} from "../../constants/constant.ts";
import { Avatar } from "../../components/catalyst/avatar.tsx";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import { Button } from "../../components/catalyst/button.tsx";
import {
  Card,
  CardContent,
  CardH1,
  CardHeader,
  CardImage,
  CardTitle,
} from "../../components/admin/Card.tsx";
import { Input } from "../../components/catalyst/input.tsx";
import StorageFileAudioPlayer from "../../components/admin/StorageFileAudioPlayer.tsx";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import NumericStepper from "../../components/admin/NumericStepper.tsx";
import SpokableTagSelector from "../../components/admin/SpokableTagSelector.tsx";
import { ESBR_RATINGS, ESBR_TAGS } from "../../constants/rating.ts";
import { GENRE_TAGS } from "../../constants/genres.ts";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { modalStoryGenerateState } from "../../states/ModalState.ts";
import { useRecoilState } from "recoil";
import GenerateStoryModal from "../../components/admin/generateStoryModal/GenerateStoryModal.tsx";
import Collapsible from "../../components/admin/Collapsible.tsx";
import ImageGenerationStyleSelector from "../../components/admin/ImageGenerationStyleSelector.tsx";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";

function StoryDetail() {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [, setGenerateModalOpen] = useRecoilState(modalStoryGenerateState);
  // const [onePager, setOnePager] = useState<OnePager | null>(null);
  const [isOpen] = useRecoilState(modalStoryGenerateState);

  useEffect(() => {
    stopBackgroundMusic();
    fetchStory();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      fetchStory();
    }
  }, [isOpen]);

  async function fetchStory() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", storyId)
      .returns<Tables<"blueprint_stories">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setStory(data);
      // const parsedOnePager = data?.one_pager ? JSON.parse(data?.one_pager?.toString()) : "";
      // setOnePager(parsedOnePager);
    }
  }

  async function updateStory() {
    if (!story) return;
    if (storyId == undefined) return;

    const { id, ...storyWithoutId } = story;
    // storyWithoutId.one_pager = JSON.stringify(onePager);
    const { error } = await supabase
      .from("blueprint_stories")
      .update(storyWithoutId)
      .eq("id", storyId);

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      setErrorMessage("Saved! ");
    }
  }

  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <GlobalSideNavLayout activePageId={PAGE_STORY} storyId={storyId || ""}>
      <div className="px-4 py-8">
        <div className="top-section sticky top-0 backdrop-blur-xl z-50 shadow-md p-6">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <h1 className="text-5xl font-bold mb-12">{story.name?.toUpperCase()}</h1>

          <div className="flex justify-between items-end w-full">
            {story.background_image && (
              <div className="flex">
                <Avatar
                  key={story.id}
                  className="size-28"
                  src={getSupabaseImageUrl(story.id, story.background_image)}
                />
              </div>
            )}

            <div className="flex gap-2 w-full justify-between">
              <div className="flex gap-2">
                <Button onClick={updateStory}>Save</Button>
              </div>
              <Button onClick={() => setGenerateModalOpen(true)}>
                <SparklesIcon className="mr-2" />
                Generate
              </Button>
            </div>
          </div>
        </div>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Home Page" id="basic" />
            <FormField label="Story name:">
              <Input
                type="text"
                id="chapter_name"
                value={story.name || ""}
                onChange={(e) => setStory({ ...story, name: e.target.value })}
              />
            </FormField>
            <FormField label="Log line:">
              <AutoResizeTextArea
                value={story.catalogue_description || ""}
                onChange={(e) => setStory({ ...story, catalogue_description: e })}
              />
            </FormField>
            <FormField label="Home page image prompt:">
              <AutoResizeTextArea
                value={story.image_prompt || ""}
                onChange={(e) => setStory({ ...story, image_prompt: e })}
              />
            </FormField>
            <FormField label={"Overall Style:"}>
              <ImageGenerationStyleSelector
                promptStyle={story.image_prompt_style_key || ""}
                onPromptStyleSelected={(e) => {
                  console.log(e);
                  setStory({
                    ...story,
                    image_prompt_style_key: e,
                  });
                }}
              />
            </FormField>
            <FormField label={"Home background image:"}>
              <StorageFileSelector
                bucketName={FILE_TYPE_IMAGE}
                storyId={story.id}
                onStorageFileSelected={(storageFileUrl) =>
                  setStory({ ...story, background_image: storageFileUrl })
                }
                value={story.background_image}
                hideImagePreview={true}
                canAIGenerate={true}
                baseImagePrompt={story.image_prompt}
                promptModifier={story.image_prompt_style_key}
              />
              {story.background_image && (
                <Card className="sm:col-span-2">
                  <CardHeader className="pb-3">
                    <CardImage src={getSupabaseImageUrl(storyId, story.background_image) ?? ""} />
                    <CardTitle>
                      {story.background_image.replace(".png", "").toUpperCase()}
                    </CardTitle>
                  </CardHeader>
                </Card>
              )}
            </FormField>

            <Collapsible title={"Catalogue details"}>
              <FormField label="">
                <NumericStepper
                  value={story.estimated_minute_duration || 90}
                  onChange={(e) => {
                    setStory({ ...story, estimated_minute_duration: e });
                  }}
                  label={"Estimate duration in minutes: "}
                />
              </FormField>
              <FormField label="ESBR Rating">
                <SpokableListBox
                  options={ESBR_RATINGS}
                  value={
                    ESBR_RATINGS.find((type) => type.key === story.esbr_rating) || ESBR_RATINGS[0]
                  }
                  onChange={(selectedType) => {
                    setStory({ ...story, esbr_rating: selectedType.key });
                  }}
                />
              </FormField>
              <FormField label="ESBR tags">
                <SpokableTagSelector
                  value={story.esbr_tags || []}
                  tags={ESBR_TAGS}
                  onChange={(e) => {
                    setStory({ ...story, esbr_tags: e });
                  }}
                />
              </FormField>
              <FormField label="Genre tags">
                <SpokableTagSelector
                  value={story.genre_tags || []}
                  tags={GENRE_TAGS}
                  onChange={(e) => {
                    setStory({ ...story, genre_tags: e });
                  }}
                />
              </FormField>
              <FormField label="Interactivity level">
                <SpokableListBox
                  options={STORY_INTERACTIVITY_LEVELS}
                  value={
                    STORY_INTERACTIVITY_LEVELS.find(
                      (type) => type.key === story.interactivity_level,
                    ) || STORY_INTERACTIVITY_LEVELS[0]
                  }
                  onChange={(selectedType) => {
                    setStory({ ...story, interactivity_level: selectedType.key });
                  }}
                />
              </FormField>
              <FormField label="Interactivity level">
                <SpokableListBox
                  options={STORY_BRANCHING_LEVELS}
                  value={
                    STORY_BRANCHING_LEVELS.find((type) => type.key === story.branching_level) ||
                    STORY_BRANCHING_LEVELS[0]
                  }
                  onChange={(selectedType) => {
                    setStory({ ...story, branching_level: selectedType.key });
                  }}
                />
              </FormField>
              <div className="w-full lg:w-1/2">
                <div className="flex justify-between">
                  <FormField label="Background color:">
                    <ColorPicker
                      initialColor={story.background_rgba_color || ""}
                      onColorChange={(color) =>
                        setStory({ ...story, background_rgba_color: color })
                      }
                    ></ColorPicker>
                  </FormField>
                  <FormField label="Text color:">
                    <ColorPicker
                      initialColor={story.text_rgba_color || ""}
                      onColorChange={(color) => setStory({ ...story, text_rgba_color: color })}
                    ></ColorPicker>
                  </FormField>
                  <FormField label="Control color:">
                    <ColorPicker
                      initialColor={story.control_rgba_color || ""}
                      onColorChange={(color) => setStory({ ...story, control_rgba_color: color })}
                    ></ColorPicker>
                  </FormField>
                </div>
              </div>
            </Collapsible>
            <Collapsible title={"Story home details"}>
              <FormField label={"Story logo"}>
                <StorageFileSelector
                  bucketName={FILE_TYPE_IMAGE}
                  storyId={story.id}
                  onStorageFileSelected={(storageFileUrl) =>
                    setStory({ ...story, story_logo_image: storageFileUrl })
                  }
                  value={story.story_logo_image}
                  hideImagePreview={true}
                />
                {story.story_logo_image && (
                  <Card className="sm:col-span-2">
                    <CardHeader className="pb-3">
                      <CardImage src={getSupabaseImageUrl(storyId, story.story_logo_image) ?? ""} />
                      <CardTitle>
                        {story.story_logo_image.replace(".png", "").toUpperCase()}
                      </CardTitle>
                    </CardHeader>
                  </Card>
                )}
              </FormField>
              <FormField label="Sound intro:">
                <StorageFileSelector
                  bucketName="sound"
                  storyId={story.id}
                  onStorageFileSelected={(storageFileUrl) =>
                    setStory({ ...story, sound_intro: storageFileUrl })
                  }
                  value={story.sound_intro}
                />
                {story.sound_intro && (
                  <StorageFileAudioPlayer
                    bucketName={FILE_TYPE_SOUND}
                    storyId={story.id}
                    fileName={story.sound_intro}
                  />
                )}
              </FormField>
            </Collapsible>
          </CardContent>
        </Card>
      </div>
      <GenerateStoryModal story={story} />
    </GlobalSideNavLayout>
  );
}

export default StoryDetail;
