import { createEffectComponent } from "./CreateEffectComponent.ts";

const levaParams = {
  intensity: { value: 1.5, min: 0, max: 5, step: 0.1 },
  displacementScale: { value: 0.1, min: 0, max: 1, step: 0.01 },
  // Add other leva parameters as necessary
};

const WaterEffect = createEffectComponent(
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader with displacement and intensity
  `
    uniform float progress;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform sampler2D displacement;
    uniform float intensity;
    uniform float displacementScale;
    varying vec2 vUv;

    void main() {
      vec2 disp = texture2D(displacement, vUv).rg;
      vec2 uvDisplaced1 = vUv + disp * displacementScale * progress * intensity;
      vec2 uvDisplaced2 = vUv - disp * displacementScale * (1.0 - progress) * intensity;
      vec4 t1 = texture2D(texture1, uvDisplaced1);
      vec4 t2 = texture2D(texture2, uvDisplaced2);
      vec3 color = mix(t1.rgb, t2.rgb, progress);
      float alpha = mix(t1.a, t2.a, progress);
      gl_FragColor = vec4(color, alpha);
    }
  `,
  () => ({ ...levaParams }),
);

export default WaterEffect;
