import Thumbnail from "./Thumbnail";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import { Tables } from "../../types/database.ts";

interface Props {
  title: string;
  stories: Tables<"blueprint_stories_with_ratings">[];
  showProgress?: boolean;
}

function Row({ title, stories, showProgress = false }: Props) {
  return (
    <div className="m-4 mr-10 px-4 md:ps-12">
      <h2 className=" cursor-pointer text-sm md:text-xl  font-bold font-soehne flex items-center">
        {title}
        <ChevronRightIcon className="h-12 w-12 text-brand-yellow ml-2" />
      </h2>
      <div className="border-t border-white/10 pt-8 "></div>
      <div className="ml-10 mr-10 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-12">
        {stories.map((story) => (
          <div key={story.id}>
            <Thumbnail story={story} />
            {showProgress && (
              <div className="m-4 overflow-hidden rounded-full bg-gray-200">
                <div
                  className="h-2 rounded-full bg-brand-yellow"
                  style={{ width: `${Math.floor(Math.random() * 100)}%` }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Row;
