import { useEffect, useState } from "react";

import { Tables, TablesInsert } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import { User } from "@sentry/react";
import { useRecoilCallback, useRecoilState } from "recoil";
import { storyBeatsState } from "../../states/storyState.tsx";
import { BeatSheet } from "../../types/fastApiTypes.ts"; // Adjust the import path as needed

type Story = Tables<"blueprint_stories">;

export const useFetchStories = () => {
  const [stories, setStories] = useState<Story[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStories = async () => {
      const { data, error } = await supabase
        .from("blueprint_stories")
        .select("*")
        .order("created_at", { ascending: true });

      if (error) {
        setError(error.message);
      } else {
        setStories(data);
      }
    };

    fetchStories();
  }, []);

  return { stories, error };
};

export default useFetchStories;

export const useFetchStoriesWithRatingAndList = () => {
  const [user, setUser] = useState<User | null>(null);
  const [stories, setStories] = useState<Tables<"blueprint_stories_with_ratings">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserAndStories = async () => {
      // Fetch user
      const { data: userData, error: userError } = await supabase.auth.getUser();
      if (userError) {
        setError(userError.message);
        setIsLoading(false);
        return;
      }
      const user = userData.user;
      setUser(user);

      if (user) {
        // Fetch stories
        const { data: storiesData, error: storiesError } = await supabase
          .from("blueprint_stories_with_ratings")
          .select("*")
          .or(`player_user_id.eq.${user.id},player_user_id.is.null`)
          .order("created_at", { ascending: true });

        if (storiesError) {
          setError(storiesError.message);
        } else {
          setStories(storiesData);
        }
      }
      setIsLoading(false);
    };

    fetchUserAndStories();
  }, []);

  return { user, stories, error, isLoading };
};

export const useStoryBeats = () => {
  const [storyBeats] = useRecoilState(storyBeatsState);

  const fetchStoryBeats = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (storyBeats) return; // Data already fetched, no need to fetch again

        try {
          const { data, error } = await supabase
            .from("blueprint_beats")
            .select("*")
            .order("beat_order");

          if (error) {
            throw error;
          }

          set(storyBeatsState, data);
        } catch (error) {
          console.error("Error fetching story beats:", error);
        }
      },
    [],
  );

  useEffect(() => {
    fetchStoryBeats();
  }, [fetchStoryBeats]);

  return storyBeats;
};

export const useCreateBeats = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const createBeats = async (
    branch: Tables<"blueprint_branches"> | undefined,
    story: Tables<"blueprint_stories"> | undefined,
    beatSheet: BeatSheet | null,
    storyBeats: Tables<"blueprint_beats">[] | null,
    fromBranch?: Tables<"blueprint_branches">,
    fromBeat?: Tables<"blueprint_beatsheets">,
    transitionDescription?: string,
  ) => {
    if (!branch || !story || !beatSheet || !storyBeats) return;

    const beatsheetData = Object.entries(beatSheet)
      .map(([key, value]) => {
        if (key === "branch_name") return null;
        if (key === "fun_and_games" && Array.isArray(value)) {
          value = value.join("\n");
        }
        const databaseBeat = storyBeats.find((beat) => beat.name === key);
        if (!databaseBeat) {
          console.log("Beat not found: ", key);
          return null;
        }
        return {
          beat_id: databaseBeat.id,
          branch_id: branch.id,
          description: value,
          story_id: story.id,
        };
      })
      .filter((entry): entry is NonNullable<typeof entry> => entry !== null);

    const createdBeatsheets: Tables<"blueprint_beatsheets">[] = [];
    for (const beat of beatsheetData) {
      const createdBeat = await createBeat(beat);
      if (createdBeat) {
        createdBeatsheets.push(createdBeat);
      }
    }

    if (fromBranch && fromBeat && transitionDescription) {
      const firstBeatInNewBranch = createdBeatsheets.find(
        (beatsheet) => beatsheet.beat_id === fromBeat.beat_id,
      );
      if (firstBeatInNewBranch) {
        await createTransition({
          from_beat_id: fromBeat.id,
          to_beat_id: firstBeatInNewBranch.id,
          from_branch_id: fromBranch.id,
          to_branch_id: branch.id,
          story_id: story.id,
          transition_description: transitionDescription,
        });
      }
    }
  };

  const createBeat = async (beat: TablesInsert<"blueprint_beatsheets">) => {
    const { data, error } = await supabase
      .from("blueprint_beatsheets")
      .upsert(beat, { onConflict: "branch_id, beat_id" })
      .select()
      .limit(1)
      .single();
    if (error) {
      setErrorMessage("Error updating beat: " + error.message);
    } else {
      setErrorMessage("Saved!");
      return data;
    }
  };

  const createTransition = async (transition: TablesInsert<"blueprint_branch_transitions">) => {
    const { error } = await supabase.from("blueprint_branch_transitions").insert(transition);
    if (error) {
      setErrorMessage("Error creating transition: " + error.message);
    } else {
      setErrorMessage("Transition saved!");
    }
  };

  return { createBeats, errorMessage };
};
