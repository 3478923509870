import { createEffectComponent } from "./CreateEffectComponent.ts";

export const FadeEffect = createEffectComponent(
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader for Fade Effect
  `
    uniform float progress;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    varying vec2 vUv;
    void main() {
      vec4 t1 = texture2D(texture1, vUv);
      vec4 t2 = texture2D(texture2, vUv);
      
      // Calculate the mixed color values
      vec3 mixedColor = mix(t1.rgb, t2.rgb, progress);
      
      // Calculate the mixed alpha value
      float mixedAlpha = mix(t1.a, t2.a, progress);
      
      // Set the final color with preserved alpha
      gl_FragColor = vec4(mixedColor, mixedAlpha);
    }
  `,
);
