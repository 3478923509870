import React, { useEffect, useMemo, useState } from "react";
import { CardH1 } from "./Card.tsx";
import { useNavigate } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
  showSidePanel?: boolean;
}

export const SideNavLayout: React.FC<LayoutProps> = ({ children, showSidePanel = true }) => {
  const [activeSection, setActiveSection] = useState("");
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();
  const contentSections = useMemo(() => {
    const sections: {
      id: string;
      label: string;
    }[] = [];
    const extractSections = (node: React.ReactNode) => {
      React.Children.forEach(node, (child) => {
        if (React.isValidElement(child)) {
          if (child.type === CardH1 && child.props.id && child.props.label) {
            sections.push({ id: child.props.id, label: child.props.label });
          }
          extractSections(child.props.children);
        }
      });
    };

    extractSections(children);
    return sections;
  }, [children]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")?.slice(1);
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const scrollPosition = targetElement.offsetTop - offset;
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const topSectionElement = document.querySelector(".top-section");
    if (topSectionElement) {
      setOffset(topSectionElement.clientHeight);
    }

    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const currentSection = contentSections.find((section) => {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop - offset;
          const sectionBottom = sectionTop + sectionElement.offsetHeight;
          return scrollPosition >= sectionTop && scrollPosition < sectionBottom;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [contentSections, children]); // Adding 'children' as a dependency to recalculate when content changes

  return (
    <div className="flex flex-col h-screen font-soehne admin">
      {/* Sidebar */}
      {showSidePanel && (
        <div className="flex flex-1">
          <div className="w-40 flex-shrink-0 border-r border-gray-200 bg-neutral-100 fixed left-0 top-0 bottom-0">
            <div
              className="flex h-16 items-center justify-center p-5 mt-6 cursor-pointer"
              onClick={() => navigate("/admin")}
            >
              <img src="/spokable_logo.svg" alt="logo" />
            </div>
            <nav className="flex-1 overflow-y-auto">
              <ul role="list" className="space-y-2 py-4">
                {contentSections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      onClick={handleClick}
                      className={`block px-4 py-2 text-sm ${
                        activeSection === section.id ? "bg-white" : "hover:underline"
                      }`}
                    >
                      {section.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
      {/* Main content */}
      <main className={`flex-1 p-6  h-full ${showSidePanel ? "ml-40" : ""}`}>
        <div className="px-4 py-8  h-full">{children}</div>
      </main>
    </div>
  );
};
