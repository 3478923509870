import React from "react";

interface BreadcrumbProps {
  items: { label: string | null; onClick: () => void }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="flex space-x-2">
        {items.map((item, index) => (
          <li key={index}>
            {index > 0 && <span className="mx-2">/</span>}
            {item.label && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  item.onClick();
                }}
                className="hover:underline"
              >
                {item.label}
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
