import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables } from "../../types/database.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";

export default function ContextDetail() {
  const { contextId } = useParams();
  const navigate = useNavigate();
  const [context, setContext] = useState<Tables<"blueprint_context_blocks"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchContext();
  }, [contextId]);

  const fetchContext = async () => {
    if (!contextId) return;

    const { data, error } = await supabase
      .from("blueprint_context_blocks")
      .select("*")
      .eq("id", contextId)
      .single();

    if (error) {
      console.error("Error fetching context:", error);
    } else {
      setContext(data);
    }
  };

  async function updateCharacter() {
    if (!context) return;
    if (contextId == undefined) return;

    const { error } = await supabase
      .from("blueprint_context_blocks")
      .update(context)
      .eq("id", contextId);

    if (error) {
      setErrorMessage("Error updating context: " + error.message);
    } else {
      navigate(-1);
    }
  }

  const handleCancel = () => {
    navigate(-1);
  };

  if (!context) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4">
          {errorMessage}
        </div>
      )}

      <h1 className="text-3xl font-bold mb-4">Edit Context</h1>

      <div className="mb-8">
        <label htmlFor="name" className="block font-bold mb-2">
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={context.context_block_name || ""}
          onChange={(e) => setContext({ ...context, context_block_name: e.target.value })}
          className="w-full border border-gray-300 px-4 py-2"
        />
      </div>
      <div className="mb-8">
        <label htmlFor="context-description" className="block font-bold mb-2">
          Context
        </label>
        <AutoResizeTextArea
          value={context.context_block_description || ""}
          onChange={(e) => setContext({ ...context, context_block_description: e })}
        ></AutoResizeTextArea>
      </div>

      <div className="flex justify-end">
        <button
          onClick={updateCharacter}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2"
        >
          Save
        </button>
        <button
          onClick={handleCancel}
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4"
        >
          Back
        </button>
      </div>
    </div>
  );
}
