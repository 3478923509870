import React, { useCallback, useEffect, useRef, useState } from "react";
import { Textarea } from "../catalyst/textarea.tsx";
import { debounce } from "lodash";

interface AutoResizeTextAreaProps {
  value: string;
  onChange: (value: string) => void;
  debounceDelay?: number;
  className?: string;
}

// The auto-resize hook
const useAutosizeTextArea = (textAreaRef: React.RefObject<HTMLTextAreaElement>, value: string) => {
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [textAreaRef, value]);

  // Set the initial height when the component mounts
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [textAreaRef]);
};

// The ResizableTextArea component
const AutoResizeTextArea: React.FC<AutoResizeTextAreaProps> = ({
  value,
  onChange,
  debounceDelay = 0,
  className = "",
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [localValue, setLocalValue] = useState(value);

  useAutosizeTextArea(textAreaRef, localValue);

  const debouncedOnChange = useCallback(
    debounce((newValue: string) => {
      onChange(newValue);
    }, debounceDelay),
    [onChange],
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  return (
    <Textarea
      className={className}
      ref={textAreaRef}
      value={localValue}
      onChange={handleChange}
      rows={3}
      resizable={false}
    />
  );
};

export default AutoResizeTextArea;
