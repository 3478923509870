import { useEffect, useState } from "react";
import { supabase } from "../../vendor/supabaseClient.ts";
import FileUploaderButton from "./FileUploaderButton.tsx";
import { Avatar } from "../catalyst/avatar.tsx";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import { Select } from "../catalyst/select.tsx";
import { IMAGE_TYPE_DEPTH_MAP, IMAGE_TYPE_WITHOUT_BG } from "../../constants/constant.ts";
import ImageGeneratorUploaderButton from "./ImageGeneratorUploaderButton.tsx";

interface StorageFileSelectorProps {
  bucketName: string;
  storyId: string;
  onStorageFileSelected: (storageFileUrl: string) => void;
  value?: string | null;
  hideImagePreview?: boolean;
  canAIGenerate?: boolean;
  baseImagePrompt?: string | null;
  promptModifier?: string | null;
}

export const FILE_TYPE_SOUND: string = "sound"; // name of the bucket
export const FILE_TYPE_IMAGE: string = "image"; // name of the bucket

export default function StorageFileSelector({
  bucketName,
  storyId,
  onStorageFileSelected,
  value,
  hideImagePreview = false,
  canAIGenerate = false,
  baseImagePrompt = "",
  promptModifier = "",
}: StorageFileSelectorProps) {
  const [storageFileOptions, setStorageFileOptions] = useState<string[]>([]);
  const [selectedStorageFile, setSelectedStorageFile] = useState<string>(value || "");

  useEffect(() => {
    fetchStorageFileOptions();
  }, []);

  useEffect(() => {
    if (value && storageFileOptions.includes(value)) {
      setSelectedStorageFile(value);
    }
  }, [value, storageFileOptions]);

  const fetchStorageFileOptions = async () => {
    const { data, error } = await supabase.storage.from(bucketName).list(storyId, {
      limit: 100,
      offset: 0,
      sortBy: { column: "name", order: "asc" },
    });

    if (error) {
      console.error("Error fetching sound intro options:", error);
    } else {
      let storageFileFiles = data.map((file) => file.name);
      storageFileFiles = storageFileFiles.filter(
        (fileName) => !fileName.startsWith(IMAGE_TYPE_DEPTH_MAP),
      );
      storageFileFiles = storageFileFiles.filter(
        (fileName) => !fileName.startsWith(IMAGE_TYPE_WITHOUT_BG),
      );
      setStorageFileOptions(storageFileFiles);
    }
  };

  const handleStorageFileUpload = async (url: string) => {
    await fetchStorageFileOptions();
    const filename = url.split("/").pop()!;
    setSelectedStorageFile(filename);
    onStorageFileSelected(filename);
  };

  const handleStorageFileChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUrl = event.target.value;
    setSelectedStorageFile(selectedUrl);
    onStorageFileSelected(selectedUrl);
  };

  return (
    <div className="flex items-center space-x-4">
      <Select value={selectedStorageFile} onChange={handleStorageFileChange}>
        <option value="">{"Select " + bucketName}</option>
        {storageFileOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      {canAIGenerate && (
        <ImageGeneratorUploaderButton
          bucketName={bucketName}
          storyId={storyId}
          onUploadComplete={handleStorageFileUpload}
          basePrompt={baseImagePrompt}
          promptModifier={promptModifier}
        />
      )}
      <FileUploaderButton
        bucketName={bucketName}
        storyId={storyId}
        onUploadComplete={handleStorageFileUpload}
      />
      {bucketName == FILE_TYPE_IMAGE && !hideImagePreview && selectedStorageFile != "" && (
        <Avatar className="size-10" src={getSupabaseImageUrl(storyId, selectedStorageFile)} />
      )}
    </div>
  );
}
