import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tables, TablesInsert } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import { LOCALE_ENGLISH } from "../../constants/constant.ts";
import { formatDateString } from "../../utils/date.ts";
import { SideNavLayout } from "../../components/admin/SideNavLayout.tsx";
import { Card, CardContent } from "../../components/admin/Card.tsx";
import { Button } from "../../components/catalyst/button.tsx";
import { capitalize } from "../../utils/string.ts";
import Sparkline from "../../components/admin/Sparkline.tsx";
import { Avatar } from "../../components/catalyst/avatar.tsx";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";

function StoriesList() {
  const [stories, setStories] = useState<Tables<"blueprint_stories">[]>([]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { stopBackgroundMusic } = useBackgroundMusicManager();

  useEffect(() => {
    stopBackgroundMusic();
    fetchStories();
  }, []);

  async function fetchStories() {
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .order("created_at", { ascending: true })
      .returns<Tables<"blueprint_stories">[]>();

    if (error) {
      console.error("Error fetching stories:", error);
    } else {
      setStories(data);
    }
  }

  async function createStory() {
    const storyData: TablesInsert<"blueprint_stories"> = {
      name: "New Story",
    };

    const { data, error } = await supabase
      .from("blueprint_stories")
      .insert(storyData)
      .select()
      .single();
    if (error) {
      console.error("Error creating story:", error);
    } else {
      console.log(data);
      const createdStory = data as Tables<"blueprint_stories">;
      navigate(`/admin/stories/${createdStory.id}`);
    }
  }

  async function deleteStory(storyId: string) {
    // Show a confirmation dialog to the user
    if (window.confirm("Are you sure you want to delete this story?")) {
      const { error } = await supabase.from("blueprint_stories").delete().eq("id", storyId);

      if (error) {
        console.error("Error deleting story:", error);
      } else {
        fetchStories();
      }
    } else {
      // User clicked 'Cancel' or closed the confirmation dialog
      console.log("Story deletion cancelled by user.");
    }
  }

  return (
    <SideNavLayout>
      <div className="top-section sticky top-0 backdrop-blur-xl z-50 shadow-md p-6">
        {errorMessage && (
          <div
            className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
            onClick={() => setErrorMessage(null)}
          >
            {errorMessage}
          </div>
        )}
        <h1 className="text-5xl font-bold mb-12">Stories</h1>

        {/* Container for images and buttons */}
        <div className="flex justify-between items-end w-full">
          {/* Container for images */}

          {/* Container for buttons */}
          <div className="flex gap-2">
            <Button onClick={createStory}>Create story</Button>
          </div>
        </div>
      </div>
      <Card isFullWidth={true}>
        <CardContent>
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
            <TableHead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Updated at</TableHeader>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {stories.map((story) => (
                <TableRow key={story.id} href={`/admin/stories/${story.id}`}>
                  <TableCell>{capitalize(story.name)}</TableCell>
                  <TableCell>
                    {story.updated_at ? formatDateString(story.updated_at, LOCALE_ENGLISH) : ""}
                  </TableCell>
                  <TableCell>
                    <Avatar
                      className="size-10"
                      src={getSupabaseImageUrl(story.id, story.background_image)}
                    />
                  </TableCell>
                  <TableCell>
                    <Sparkline />
                  </TableCell>
                  <TableCell>
                    <Button color={"light"} onClick={() => deleteStory(story.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </SideNavLayout>
  );
}

export default StoriesList;
