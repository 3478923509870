import { supabase, supabaseUrl } from "../vendor/supabaseClient.ts";
import { FILE_TYPE_IMAGE } from "../components/admin/StorageFileSelector.tsx";
import { IMAGE_TYPE_DEPTH_MAP, IMAGE_TYPE_WITHOUT_BG } from "../constants/constant.ts";

export function getSupabaseImageUrl(
  storyId: string | null | undefined,
  imageName: string | null | undefined,
): string | null {
  if (!imageName) return null;
  else return `${supabaseUrl}/storage/v1/object/public/image/${storyId}/${imageName}`;
}

export async function uploadToSupabaseFileFromUrl(
  storyId: string,
  fileUrl: string,
  fileName: string,
): Promise<string | null> {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const filePath = `${storyId}/${fileName}`;

    const { data, error } = await supabase.storage.from(FILE_TYPE_IMAGE).upload(filePath, blob, {
      upsert: true,
    });

    if (error) {
      console.error("Error uploading file:", error);
      return null;
    }

    if (data !== null) {
      return data.path;
    }
  } catch (error) {
    console.error("Error fetching file from URL:", error);
  }

  return null;
}

export function getDepthMapUrl(url: string): string {
  //"http://127.0.0.1:54321/storage/v1/object/public/image/5923aff4-85a5-484b-a5fd-a032d56900b5/adad.png";
  const parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  if (lastPart.startsWith(IMAGE_TYPE_WITHOUT_BG)) {
    lastPart = lastPart.replace(IMAGE_TYPE_WITHOUT_BG, "");
  }
  lastPart = IMAGE_TYPE_DEPTH_MAP + lastPart;
  parts[parts.length - 1] = lastPart;
  return parts.join("/");
}

export async function doesObjectExistInStorage(objectName: string) {
  const { data, error } = await supabase.storage.from(FILE_TYPE_IMAGE).download(objectName); // The expiry time is set to 1 second

  if (error) {
    console.error("Error fetching signed URL:", error);
    return false;
  }

  return !!data;
}

export async function doesAllImageTypeExistInStorage(objectName: string, storyId: string) {
  return (
    (await doesObjectExistInStorage(`${storyId}/${IMAGE_TYPE_WITHOUT_BG + objectName}`)) &&
    (await doesObjectExistInStorage(`${storyId}/${IMAGE_TYPE_DEPTH_MAP + objectName}`))
  );
}
