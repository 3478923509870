import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "@headlessui/react";
import { IdeaItem } from "./IdeaItem";
import { LoadingSpinner } from "../LoadingSpinner";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { RankedIdea, RankedIdeas } from "../../../types/fastApiTypes";
import { Button } from "../../catalyst/button";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";

export interface GenerateIdeasProps {
  ideas: { ranked_ideas: RankedIdea[] } | null;
  status: string;
  generateIdeas: (payload: any) => void;
  selectedIdea: string | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<string | null>>;
  setIdeas: React.Dispatch<React.SetStateAction<RankedIdeas | null>>;
}

const GenerateIdeas: React.FC<GenerateIdeasProps> = ({
  ideas,
  status,
  generateIdeas,
  selectedIdea,
  setSelectedIdea,
  setIdeas,
}) => {
  const memoizedIdeas = useMemo(() => ideas, [ideas]);
  const [ideaSeed, setIdeaSeed] = useState("");

  useEffect(() => {
    if (ideas === null && status === "") {
      generateIdeas({ idea: ideaSeed || "" });
    }
  }, [ideas, status]);

  const handleRegenerate = () => {
    setIdeas(null);
  };

  return (
    <>
      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        Generate Ideas{" "}
        <Button color="light" className="ml-2" onClick={handleRegenerate}>
          <ArrowPathIcon />
        </Button>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      <div className="mt-2">
        {status !== "" && <LoadingSpinner />}
        {memoizedIdeas && (
          <>
            <div className="flex w-full space-x-2">
              <div className="flex-grow">
                <AutoResizeTextArea value={ideaSeed} onChange={(value) => setIdeaSeed(value)} />
              </div>
              <Button onClick={handleRegenerate}>Generate</Button>
            </div>
            <div className="bg-zinc-200 rounded-lg p-3 mt-4 max-h-[calc(90vh-300px)] overflow-y-auto">
              {memoizedIdeas.ranked_ideas.map((idea, index) => (
                <IdeaItem
                  key={index}
                  idea={idea}
                  setSelectedIdea={setSelectedIdea}
                  selectedIdea={selectedIdea}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GenerateIdeas;
