import React, { useState } from "react";
import {
  BeatSheet,
  CharacterSheet,
  CharactersSummary,
  LogLine,
  OnePager,
} from "../../../types/fastApiTypes";
import { Button } from "../../catalyst/button";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { BRANCH_NAME_MAIN } from "../../../constants/constant.ts";
import { DialogTitle } from "../../catalyst/dialog.tsx";
import { useStoryBeats } from "../../../hooks/database/useStory.ts";

export interface SaveAllProps {
  beatSheet: BeatSheet | null;
  onePager: OnePager | null;
  charactersSummary: CharactersSummary | null;
  logLine: LogLine | null;
  story: Tables<"blueprint_stories">;
  handleClose: () => void;
}

const SaveAll: React.FC<SaveAllProps> = ({
  beatSheet,
  onePager,
  charactersSummary,
  logLine,
  story,
  handleClose,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const storyBeats = useStoryBeats();

  const saveAll = async () => {
    updateStory();
    createCharacters();
    const branch = await createBranch();
    createBeats(branch);
    handleClose();
  };

  async function updateStory() {
    if (!story) return;

    const { id, ...storyWithoutId } = story;
    storyWithoutId.name = onePager?.title || "";
    storyWithoutId.catalogue_description = logLine?.log_line || "";
    storyWithoutId.image_prompt = onePager?.image_generation_prompt || "";

    const { error } = await supabase
      .from("blueprint_stories")
      .update(storyWithoutId)
      .eq("id", story.id);

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      setErrorMessage("Saved! ");
    }
  }

  async function createBranch() {
    if (!story) return;

    // Check if the main branch already exists for this story
    const { data: existingBranch } = await supabase
      .from("blueprint_branches")
      .select()
      .eq("story_id", story.id)
      .eq("name", BRANCH_NAME_MAIN)
      .limit(1)
      .single();

    if (existingBranch) {
      return existingBranch;
    }

    const branchData: TablesInsert<"blueprint_branches"> = {
      name: BRANCH_NAME_MAIN,
      one_pager: JSON.stringify(onePager),
      story_id: story.id,
    };
    const { data, error } = await supabase
      .from("blueprint_branches")
      .insert(branchData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      console.log(data);
      setErrorMessage("Saved!");
      return data;
    }
  }

  async function createBeats(branch: Tables<"blueprint_branches"> | undefined) {
    console.log(branch);
    if (!branch) return;
    if (!story) return;
    if (!beatSheet) return;
    if (!storyBeats) return;

    const beatsheetData = Object.entries(beatSheet)
      .map(([key, value]) => {
        // Skip the branch_name key
        if (key === "branch_name") return null;

        // Handle the fun_and_games array
        if (key === "fun_and_games" && Array.isArray(value)) {
          value = value.join("\n");
        }

        const databaseBeat = storyBeats.find((beat) => beat.name === key);

        if (!databaseBeat) {
          console.log("Beat not found: ", key);
          return null;
        }

        return {
          beat_id: databaseBeat.id,
          branch_id: branch.id,
          description: value,
          story_id: story.id,
        };
      })
      .filter((entry) => entry !== null);
    console.log(beatsheetData);
    beatsheetData.map((beat) => createBeat(beat));
  }

  async function createBeat(beat: TablesInsert<"blueprint_beatsheets"> | null) {
    if (!beat) return;

    const { error } = await supabase
      .from("blueprint_beatsheets")
      .upsert(beat, { onConflict: "branch_id, beat_id" });

    if (error) {
      setErrorMessage("Error updating beat: " + error.message);
    } else {
      setErrorMessage("Saved!");
    }
  }

  async function createCharacters() {
    if (!story) return;

    charactersSummary?.character_sheets.map((characterSheet) => createCharacter(characterSheet));
  }

  async function createCharacter(characterSheet: CharacterSheet) {
    const characterData: TablesInsert<"blueprint_characters"> = {
      ...characterSheet,
      blueprint_story_id: story.id,
    };

    const { error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else {
      setErrorMessage("Saved!");
    }
  }

  return (
    <>
      <DialogTitle as="h2" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Complete process
      </DialogTitle>
      <div className="flex">
        <div className="flex-grow">
          {errorMessage && <div>{errorMessage}</div>}
          <Button onClick={saveAll} className="mt-6 mb-6">
            Save all
          </Button>
        </div>
      </div>
    </>
  );
};

export default SaveAll;
