import { formatRelative, parseISO } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { LOCALE_ENGLISH, LOCALE_FRENCH } from "../constants/constant.ts";

export function formatDateString(dateString: string, locale: string): string {
  const parsedDate = parseISO(dateString);

  let localeSetting;
  switch (locale) {
    case LOCALE_FRENCH:
      localeSetting = fr;
      break;
    case LOCALE_ENGLISH:
      localeSetting = enUS;
      break;
    default:
      throw new Error(`Unsupported locale: ${locale}`);
  }
  return formatRelative(parsedDate, new Date(), { locale: localeSetting });
}
