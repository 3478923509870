// components/imageEffects/ShakingEffect.tsx
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import useDialogueManager from "../../hooks/useDialogueManager.ts";
import {
  IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC,
  IMAGE_EFFECT_REACT_FROM_VOICE,
} from "../../constants/constant.ts";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import { useRecoilState } from "recoil";
import { lastImageEffectConfigState } from "../../states/effectState.ts";
import { buildControlsIfAdmin } from "../../utils/imageEffect.ts";

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

export interface ShakingEffectProps {
  composer: EffectComposer | null;
  texture: THREE.Texture;
  params?: any;
}

const ShakingOnSoundEffect: React.FC<ShakingEffectProps> = ({ composer, texture, params }) => {
  const { size } = useThree();
  const uOffset = useRef(new THREE.Vector2(0, 0));
  const uScale = useRef(1);

  const shaderParams =
    params ||
    buildControlsIfAdmin({
      reactToMusicFrom: {
        value: IMAGE_EFFECT_REACT_FROM_VOICE,
        options: [IMAGE_EFFECT_REACT_FROM_VOICE, IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC],
      },
      bassFrequencyRange: [0, 10],
      trebleFrequencyRange: [150, 300],
      bassMultiplier: 0.01,
      trebleMultiplier: 0.02,
      lerpFactor: 0.1,
    });
  const [, setShaderParams] = useRecoilState(lastImageEffectConfigState);
  const memoizedSetShaderParams = useCallback(() => {
    setShaderParams(shaderParams);
  }, [JSON.stringify(shaderParams), setShaderParams]);

  useEffect(() => {
    memoizedSetShaderParams();
  }, [memoizedSetShaderParams]);

  const { getFrequencyData } =
    shaderParams && shaderParams.reactToMusicFrom === IMAGE_EFFECT_REACT_FROM_VOICE
      ? useDialogueManager()
      : useBackgroundMusicManager();

  const customPass = useMemo(() => {
    const customEffect = {
      uniforms: {
        tDiffuse: { value: texture },
        resolution: { value: new THREE.Vector2(1, size.height / size.width) },
        uOffset: { value: uOffset.current },
        uScale: { value: uScale.current },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform vec2 resolution;
        uniform vec2 uOffset;
        uniform float uScale;
        varying vec2 vUv;
      
        void main() {
          vec2 scaledUv = (vUv - 0.5) * uScale + 0.5;
          vec2 offsetUv = scaledUv + uOffset / resolution;
          vec4 color = texture2D(tDiffuse, offsetUv);
          gl_FragColor = color;
        }
      `,
    };

    return new ShaderPass(customEffect);
  }, [size, texture]);

  useEffect(() => {
    if (composer) {
      composer.addPass(customPass);
    }

    return () => {
      if (composer) {
        composer.removePass(customPass);
      }
    };
  }, [composer, customPass]);

  useFrame(() => {
    const frequencyData = getFrequencyData();
    if (frequencyData) {
      const bass =
        frequencyData.slice(...shaderParams.bassFrequencyRange).reduce((a, b) => a + b) /
        shaderParams.bassFrequencyRange[1];
      const treble =
        frequencyData.slice(...shaderParams.trebleFrequencyRange).reduce((a, b) => a + b) /
        (shaderParams.trebleFrequencyRange[1] - shaderParams.trebleFrequencyRange[0]);

      const bassMapped = Math.log1p(bass) * shaderParams.bassMultiplier;
      const trebleMapped = Math.sqrt(treble / 255) * shaderParams.trebleMultiplier;

      uOffset.current.x = lerp(
        uOffset.current.x,
        (Math.random() - 0.5) * trebleMapped,
        shaderParams.lerpFactor,
      );
      uOffset.current.y = lerp(
        uOffset.current.y,
        (Math.random() - 0.5) * trebleMapped,
        shaderParams.lerpFactor,
      );
      uScale.current = lerp(uScale.current, 1 + bassMapped, shaderParams.lerpFactor);
    }

    customPass.uniforms.uOffset.value = uOffset.current;
    customPass.uniforms.uScale.value = uScale.current;
  }, 1);

  return null;
};

export default ShakingOnSoundEffect;
