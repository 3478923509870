interface Item {
  probability: number;
}

export function getRandomItemWithProbability<T extends Item>(items: T[]): T {
  // Ensure the probabilities sum up to 1
  const totalProbability = items.reduce((sum, item) => sum + item.probability, 0);
  if (totalProbability !== 1) {
    throw new Error("Probabilities must sum up to 1");
  }

  // Generate a random number between 0 and 1
  const randomNumber = Math.random();

  // Initialize the cumulative probability
  let cumulativeProbability = 0;

  // Iterate through the items and find the selected item based on the random number
  for (const item of items) {
    cumulativeProbability += item.probability;
    if (randomNumber <= cumulativeProbability) {
      return item;
    }
  }

  // This line is unreachable if the probabilities sum up to 1
  throw new Error("No item selected");
}

export function getRandomItem<T>(items: T[] | undefined): T | null {
  if (!items) return null;
  else return items[Math.floor(Math.random() * items.length)];
}
