import { atom } from "recoil";

export const modalStoryGenerateState = atom<boolean>({
  key: "modalGenerateState",
  default: false,
});

export const modalBeatGenerateState = atom<boolean>({
  key: "modalBeatGenerateState",
  default: false,
});

export const modalScenesGenerateState = atom<boolean>({
  key: "modalSceneGenerateState",
  default: false,
});

export const modalChaptersGenerateState = atom<boolean>({
  key: "modalChapterGenerateState",
  default: false,
});
export const modalImageGenerateState = atom<boolean>({
  key: "modalImageGenerateState",
  default: false,
});
