import React from "react";

interface CardProps {
  className?: string;
  children: React.ReactNode;
  isFullWidth?: boolean;
}

export const Card: React.FC<CardProps> = ({ className, children, isFullWidth = false }) => {
  return (
    <div className={`${isFullWidth ? "" : "max-w-sm"} mt-6 rounded-xl shadow-md ${className}`}>
      {children}
    </div>
  );
};

interface CardContentProps {
  children: React.ReactNode;
  className?: string;
}

export const CardContent: React.FC<CardContentProps> = ({ className, children }) => {
  return (
    <div className={`px-6 py-6 rounded-md bg-gray-100 ${className} bg-neutral-100`}>{children}</div>
  );
};

interface CardH1Props {
  label: string;
  className?: string;
  id: string;
}

export const CardH1: React.FC<CardH1Props> = ({ label, className, id }) => {
  return (
    <div id={id} className={`${className} mb-2 mt-8`}>
      <h1 className="text-3xl font-bold mb-4 border-b pt-3 pb-3 border-black">{label}</h1>
    </div>
  );
};

interface CardHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ children, className }) => {
  return <div className={` ${className}`}>{children}</div>;
};

interface CardImageProps {
  src: string;
  alt?: string;
}

export const CardImage: React.FC<CardImageProps> = ({ src, alt }) => {
  return <img className="w-full" src={src} alt={alt || "Card Image"} />;
};

interface CardTitleProps {
  children: string;
}

export const CardTitle: React.FC<CardTitleProps> = ({ children }) => {
  return (
    <div className="px-6 py-4 font-bold text-xl overflow-hidden text-ellipsis whitespace-nowrap">
      {children}
    </div>
  );
};

interface CardDescriptionProps {
  children: React.ReactNode;
  className?: string;
}

export const CardDescription: React.FC<CardDescriptionProps> = ({ children, className }) => {
  return (
    <p
      className={`px-6 text-gray-700 text-base overflow-hidden text-ellipsis whitespace-nowrap ${className}`}
    >
      {children}
    </p>
  );
};

interface CardFooterProps {
  children: React.ReactNode;
}

export const CardFooter: React.FC<CardFooterProps> = ({ children }) => {
  return <div className="px-6 pt-4 pb-6">{children}</div>;
};
