import React, { useEffect, useState } from "react";
import { Badge } from "../catalyst/badge.tsx";
import { imageEffectTypes } from "../../constants/constant.ts";
import { Json } from "../../types/database.ts";
import { findConfigEntry } from "../../utils/imageEffect.ts";

type EffectCardProps = {
  configuration: Json | null;
  imageId?: string;
};

const CardEffectDescription: React.FC<EffectCardProps> = ({ configuration, imageId }) => {
  const [configEntry, setConfigEntry] = useState<{ [key: string]: Json } | null>(null);

  useEffect(() => {
    if (!imageId) {
      if (Array.isArray(configuration) && configuration.length > 0) {
        const firstEntry = configuration[0];
        if (typeof firstEntry === "object" && firstEntry !== null) {
          setConfigEntry(firstEntry as { [key: string]: Json });
        } else {
          setConfigEntry(null);
        }
      }
    } else if (configuration) {
      const entry = findConfigEntry(configuration, imageId);
      setConfigEntry(entry);
    }
  }, [configuration, imageId]);

  return (
    <div className="mt-4 ml-4">
      {configEntry && (
        <div>
          <Badge>{imageEffectTypes.find((type) => type.key === configEntry.name)?.value}</Badge>
          <div className="p-4 mr-4 mt-4 bg-white shadow-md rounded-md">
            <div className="space-y-2 text-sm">
              {configEntry.config &&
                typeof configEntry.config === "object" &&
                !Array.isArray(configEntry.config) &&
                Object.entries(configEntry.config).map(([key, value]) => (
                  <div className="flex justify-between" key={key}>
                    <Badge className="capitalize">{key}:</Badge>
                    <p className="capitalize" title={value?.toString()}>
                      {" "}
                      {typeof value === "number"
                        ? value.toFixed(2)
                        : typeof value === "string"
                          ? value.slice(0, 20)
                          : String(value)}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardEffectDescription;
