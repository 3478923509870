import React, { useEffect, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { OnePager, RankedBranchIdea } from "../../../types/fastApiTypes";
import { Button } from "../../catalyst/button";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Tables } from "../../../types/database.ts";
import {
  getMergedBeatsByBranchName,
  mergeBeatsAndBeatsheets,
  mergeBeatsAndBeatsheetsByBranch,
} from "../../../utils/valueObject.ts";

export interface GenerateOnePagerProps {
  onePager: OnePager | null;
  status: string;
  setOnePager: React.Dispatch<React.SetStateAction<OnePager | null>>;
  generateOnePager: (payload: any) => void;
  selectedIdea: RankedBranchIdea | null;

  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  clickedBranch: Tables<"blueprint_branches"> | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null;
}

const GenerateOnePager: React.FC<GenerateOnePagerProps> = ({
  onePager,
  status,
  setOnePager,
  generateOnePager,
  selectedIdea,
  beats,
  beatsheets,
  branches,
  characters,
  clickedBeatsheet,
  clickedBranch,
}) => {
  const memoizedOnePager = useMemo(() => onePager, [onePager]);

  useEffect(() => {
    if (selectedIdea && onePager === null && status === "" && clickedBeatsheet && clickedBranch) {
      const mergedBeats = mergeBeatsAndBeatsheetsByBranch(beats, beatsheets, branches);
      const beatsForClickedBranch = getMergedBeatsByBranchName(mergedBeats, clickedBranch.name);
      generateOnePager({
        idea: "",
        branch_idea: selectedIdea.idea,
        beats: beatsForClickedBranch,
        branches: branches,
        characters: characters,
        clickedBranch: clickedBranch,
        clickedBeatsheet: mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]),
      });
    }
  }, [onePager, selectedIdea, generateOnePager, setOnePager]);

  const handleRegenerate = () => {
    if (selectedIdea) {
      setOnePager(null);
    }
  };

  return (
    <>
      <Dialog.Title as="h3" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        One Pager
        <Button color="light" className="ml-2" onClick={handleRegenerate}>
          <ArrowPathIcon className="h-4 w-4" />
        </Button>
      </Dialog.Title>
      {status !== "" && <LoadingSpinner />}
      {memoizedOnePager && (
        <div className="mt-4 max-h-[calc(90vh-200px)] overflow-y-auto">
          <DynamicForm data={memoizedOnePager} setData={setOnePager} />
        </div>
      )}
    </>
  );
};

export default GenerateOnePager;
