import React from "react";
import { Card, CardDescription, CardFooter, CardHeader, CardImage, CardTitle } from "./Card";
import { getSupabaseImageUrl } from "../../utils/image";
import { Button } from "../catalyst/button";
import { useNavigate } from "react-router-dom";
import { Json } from "../../types/database";
import { ImageDetails } from "../AnimatedThreeJSImage.tsx";
import { levaStore } from "leva";
import CardEffectDescription from "./CardEffectDescription.tsx";

interface ImageCardProps {
  storyId: string;
  chapterId?: string;
  configType: string; // Adjusted for simplicity
  image: ImageDetails;
  onDelete?: (imageId: string) => void;
  imageId: string;
  configuration: Json | null;
  isDirty: boolean;
}

const ImageCard: React.FC<ImageCardProps> = ({
  storyId,
  image,
  onDelete,
  imageId,
  configType,
  chapterId,
  configuration,
  isDirty,
}) => {
  const navigate = useNavigate();
  const handleNavigation = (url: string) => {
    if (isDirty) {
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to navigate away?",
      );
      if (!userConfirmed) {
        return;
      }
    }
    navigate(url);
  };

  return (
    <Card className="sm:col-span-2">
      <CardHeader className="pb-3">
        <CardImage src={getSupabaseImageUrl(storyId, image.image_url) ?? ""} />
        <CardTitle>{image.image_url.replace(".png", "").toUpperCase()}</CardTitle>
        <CardDescription className="max-w-lg text-sm leading-relaxed">
          <span>Has original: {image.has_original.toString()} </span>
          <br />
          <span>Has depth map: {image.has_depth_map.toString()}</span>
          <br />
          <span>Has no background: {image.has_version_without_background.toString()}</span>
        </CardDescription>
        <CardEffectDescription imageId={imageId} configuration={configuration} />
      </CardHeader>
      <CardFooter>
        <Button
          onClick={() => {
            levaStore.dispose();
            handleNavigation(
              `/admin/image-effect/${storyId}/${configType}/${chapterId}/${imageId}`,
            );
          }}
        >
          Configure Effects
        </Button>
        {onDelete && (
          <Button className="ml-6" color={"light"} onClick={() => onDelete(imageId)}>
            Delete
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default ImageCard;
