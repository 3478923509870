import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { MessageType } from "../constants/messageType";
import { audioDataQueueState } from "../states/audioDataState.ts";
import { GameOutput } from "../types/gameOutput.ts";
import useChapterManager from "../hooks/useChapterManager.ts";
import { errorAtom } from "../states/storyState.tsx";
import { GameAudioOutput } from "../types/GameAudioOutput.ts";

export const useWebSocketMessageHandler = () => {
  const [audioQueue, setAudioQueue] = useRecoilState(audioDataQueueState);
  const [, setErrorMessage] = useRecoilState(errorAtom);
  const audioQueueRef = useRef(audioQueue);

  const [latestGameOutput, setLatestGameOutput] = useState<GameOutput | null>(null);
  useChapterManager({ gameOutput: latestGameOutput });

  useEffect(() => {
    audioQueueRef.current = audioQueue;
  }, [audioQueue]);

  const handleMessage = async (messageType: string, messageDataBuffer: Uint8Array) => {
    const textData = new TextDecoder().decode(messageDataBuffer);

    switch (messageType) {
      case MessageType.AUDIO_ANSWER:
        handleAudioAnswer(messageDataBuffer);
        break;

      case MessageType.GAME_OUTPUT:
        const gameOutput = JSON.parse(textData) as GameOutput;
        setLatestGameOutput(gameOutput);
        break;

      case MessageType.ERROR_MESSAGE:
        setErrorMessage(textData);
        break;

      default:
        console.error("Unknown message type:", messageType);
    }
  };
  const handleAudioAnswer = (messageDataBuffer: Uint8Array) => {
    const nullByteIndex = messageDataBuffer.indexOf(0);
    const jsonData = new TextDecoder().decode(messageDataBuffer.slice(0, nullByteIndex));
    const audioData = messageDataBuffer.slice(nullByteIndex + 1);

    const gameAudioOutput: GameAudioOutput = {
      player_chapter_id: JSON.parse(jsonData).player_chapter_id,
      audio_data: new Uint8Array(audioData),
    };

    setAudioQueue((currentQueue) => [...currentQueue, gameAudioOutput]);
    console.log("AUDIO_ANSWER message received");
  };

  return handleMessage;
};
