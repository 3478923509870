import React, {
  forwardRef,
  Suspense,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Canvas } from "@react-three/fiber";
import { IMAGE_SCALE_MODE_CONTAIN, IMAGE_SCALE_MODE_COVER } from "../../constants/constant.ts";
import { useRecoilValue } from "recoil";
import { dominantColorState } from "../../states/chapterState.tsx";

interface ThreeSceneProps {
  children: React.ReactNode;
  scaleMode: typeof IMAGE_SCALE_MODE_COVER | typeof IMAGE_SCALE_MODE_CONTAIN;
}

const ThreeScene = forwardRef(
  ({ children, scaleMode = IMAGE_SCALE_MODE_CONTAIN }: ThreeSceneProps, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const dominantColor = useRecoilValue(dominantColorState);

    useImperativeHandle(ref, () => ({
      getDimensions: () => dimensions,
    }));

    useEffect(() => {
      const calculateDimensions = () => {
        if (containerRef.current) {
          const containerWidth = containerRef.current.offsetWidth;
          const containerHeight = containerRef.current.offsetHeight;

          let width = 0,
            height = 0;

          if (scaleMode === IMAGE_SCALE_MODE_CONTAIN) {
            if (containerWidth > containerHeight) {
              height = containerHeight;
              width = containerHeight; // Square aspect ratio
            } else {
              width = containerWidth;
              height = containerWidth; // Square aspect ratio
            }
          } else if (scaleMode === IMAGE_SCALE_MODE_COVER) {
            if (containerWidth > containerHeight) {
              width = containerWidth;
              height = containerWidth; // Square aspect ratio
            } else {
              height = containerHeight;
              width = containerHeight; // Square aspect ratio
            }
          }

          setDimensions({ width, height });
        }
      };

      calculateDimensions();
      window.addEventListener("resize", calculateDimensions);

      return () => window.removeEventListener("resize", calculateDimensions);
    }, [scaleMode]);

    return (
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: dominantColor,
        }}
      >
        <Canvas style={{ width: dimensions.width, height: dimensions.height }}>
          <Suspense fallback={null}>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {children}
          </Suspense>
        </Canvas>
      </div>
    );
  },
);

export default ThreeScene;
