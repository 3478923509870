export function capitalize(str: string | null): string {
  if (!str) return ""; // return the original string if it's empty
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateText(text: string | null, maxLength: number): string {
  if (!text) return "";
  // Check if the text length is more than maxLength
  if (text.length > maxLength) {
    // Return the substring from 0 to maxLength and append "..."
    return text.substring(0, maxLength) + "...";
  }
  // Return the original text if it's shorter than maxLength
  return text;
}

export function unSnakeCase(snakeCasedText: string): string {
  return capitalize(snakeCasedText.replace(/_/g, " "));
}
