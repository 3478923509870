import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import { TextureLoader, Vector4 } from "three";
import anime from "animejs";
import { lastTransitionEffectConfigState } from "../../states/effectState.ts";
import { useRecoilState } from "recoil";
import { buildControlsIfAdmin } from "../../utils/imageEffect.ts";

interface EffectProps {
  textures: THREE.Texture[];
  currentImageIndex: number;
  displacementTextureURL?: string;
  composer: EffectComposer | null;
  params?: any;
}

export const createEffectComponent = (
  vertexShader: string,
  fragmentShader: string,
  defaultParams: any = {},
) => {
  const EffectComponent: React.FC<EffectProps> = ({
    textures,
    currentImageIndex,
    displacementTextureURL,
    composer,
    params,
  }) => {
    const { size } = useThree();
    const prevIndexRef = useRef<number>(0);
    let displacementTexture: THREE.Texture;
    if (displacementTextureURL) {
      displacementTexture = useLoader(TextureLoader, displacementTextureURL);
    }
    const [, setShaderParams] = useRecoilState(lastTransitionEffectConfigState);
    const shaderParams = params || buildControlsIfAdmin(defaultParams);
    const memoizedSetShaderParams = useCallback(() => {
      setShaderParams([shaderParams[0]]);
    }, [JSON.stringify(shaderParams[0]), setShaderParams]);

    useEffect(() => {
      memoizedSetShaderParams();
    }, [memoizedSetShaderParams]);

    const effect = useMemo(() => {
      const uniforms = {
        tDiffuse: { value: null },
        progress: { value: 0 },
        texture1: { type: "t", value: textures[0] },
        texture2: { type: "t", value: textures[currentImageIndex] || textures[0] },
        ...(displacementTexture ? { displacement: { type: "t", value: displacementTexture } } : {}),
        resolution: { value: new Vector4(size.width, size.height, 1, 1) },
        ...Object.entries(shaderParams).reduce((acc, [key, value]) => {
          if (key === "0" && typeof value === "object" && value !== null) {
            // If the key is '0' and the value is an object, spread the nested object properties into the accumulator
            return {
              ...acc,
              ...Object.entries(value as Record<string, unknown>).reduce(
                (nestedAcc, [nestedKey, nestedValue]) => {
                  return {
                    ...nestedAcc,
                    [nestedKey]: { value: nestedValue },
                  };
                },
                {},
              ),
            };
          } else {
            // For other keys, add them as is to the accumulator
            return {
              ...acc,
              [key]: { value },
            };
          }
        }, {}),
      };

      return {
        uniforms,
        vertexShader,
        fragmentShader,
      };
    }, [
      vertexShader,
      fragmentShader,
      currentImageIndex,
      textures,
      size,
      JSON.stringify(shaderParams),
    ]);

    const effectPass = useMemo(() => new ShaderPass(effect), [effect]);

    useEffect(() => {
      if (composer) {
        composer.addPass(effectPass);

        return () => {
          composer.removePass(effectPass);
        };
      }
    }, [composer, effectPass]);

    useEffect(() => {
      if (prevIndexRef.current !== currentImageIndex) {
        effectPass.uniforms.texture1.value = textures[prevIndexRef.current];
        effectPass.uniforms.texture2.value = textures[currentImageIndex];
        effectPass.uniforms.progress.value = 0;
        anime({
          targets: effectPass.uniforms.progress,
          value: 1,
          duration: 1000,
          easing: "easeInOutQuad",
          complete: () => {
            prevIndexRef.current = currentImageIndex;
            composer?.removePass(effectPass);
          },
        });
      }
    }, [currentImageIndex, textures, effectPass.uniforms]);

    useFrame((_, delta) => {
      composer?.render(delta);
    }, 1);

    return null;
  };

  return EffectComponent;
};
