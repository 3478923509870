import { useRef, useState } from "react";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Button } from "../catalyst/button.tsx";
import { Input } from "../catalyst/input.tsx";

interface FileUploaderProps {
  bucketName: string;
  storyId: string;
  onUploadComplete: (fileUrl: string) => void;
}

export default function FileUploaderButton({
  bucketName,
  storyId,
  onUploadComplete,
}: FileUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadStatus, setUploadStatus] = useState<string>("");

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setUploadStatus("Uploading...");
      const fileName = `${file.name}`;
      const file_path = storyId + "/" + fileName;
      const { data, error } = await supabase.storage.from(bucketName).upload(file_path, file, {
        upsert: true,
      });

      if (error) {
        console.error("Error uploading file:", error);
        setUploadStatus("Failed to upload");
      } else {
        if (data !== null) {
          const publicUrl = data.path;
          onUploadComplete(publicUrl);
          setUploadStatus("Done");
        }
      }
    }
  };

  return (
    <div className="flex items-center">
      <Input type="file" ref={fileInputRef} onChange={handleFileChange} className="hidden" />
      <Button
        onClick={() => {
          fileInputRef.current?.click();
          setUploadStatus(""); // Reset status when a new upload starts
        }}
      >
        New
      </Button>
      {uploadStatus && <span className="ml-4">{uploadStatus}</span>}
    </div>
  );
}
