import { Field } from "../catalyst/fieldset.tsx";
import { Listbox, ListboxLabel, ListboxOption } from "../catalyst/listbox.tsx";

interface Option {
  key: string;
  value: string;
}

interface SelectProps {
  options: Option[];
  value: Option;
  onChange: (value: Option) => void;
}

export default function SpokableListBox({ options, value, onChange }: SelectProps) {
  options = options.sort((a, b) => a.value.localeCompare(b.value));
  return (
    <Field>
      <Listbox
        name="option"
        value={value.key}
        onChange={(key) => onChange(options.find((o) => o.key === key)!)}
      >
        {options.map((option) => (
          <ListboxOption key={option.key} value={option.key} className="font-soehne">
            <ListboxLabel>{option.value}</ListboxLabel>
          </ListboxOption>
        ))}
      </Listbox>
    </Field>
  );
}
