import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tables } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Card, CardContent } from "../../components/admin/Card.tsx";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import Tooltip from "../../components/Tooltip.tsx";
import OverlayPanel from "../../components/admin/OverlayPanel.tsx";
import ChapterList from "./ChapterList.tsx";
import DynamicForm from "../../components/admin/DynamicForm.tsx";
import { Select } from "../../components/catalyst/select.tsx";
import FormField from "../../components/admin/FormField.tsx";
import ImageGenerationStyleSelector from "../../components/admin/ImageGenerationStyleSelector.tsx";
import { useCharacterImageFetcher } from "../../hooks/database/useChapter.ts";
import GenerateImagesModal from "../../components/admin/generateCharactersImagesModal/GenerateImagesModal.tsx";
import { modalImageGenerateState } from "../../states/ModalState.ts";
import { useRecoilState } from "recoil";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { Button } from "../../components/catalyst/button.tsx";
import SimpleImageCard from "../../components/admin/SimpleImageCard.tsx";

function BeatsDetail() {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [beats, setBeats] = useState<Tables<"blueprint_beats">[]>([]);
  const [branches, setBranches] = useState<Tables<"blueprint_branches">[]>([]);
  const [characters, setCharacters] = useState<Tables<"blueprint_characters">[]>([]);
  const [beatSheets, setBeatSheets] = useState<Tables<"blueprint_beatsheets">[]>([]);
  const [scenes, setScenes] = useState<Tables<"blueprint_scenes">[]>([]);

  const [chapters, setChapters] = useState<Tables<"blueprint_chapters">[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [sceneInOverlay, setSceneInOverlay] = useState<Tables<"blueprint_scenes"> | null>(null);
  const [isOverlayChaptersOpen, setIsOverlayChaptersOpen] = useState(false);
  const [isOverlaySceneOpen, setIsOverlaySceneOpen] = useState(false);
  const [showChaptersForSceneId, setShowChaptersForSceneId] = useState<string | null>(null);
  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  const [characterImageUrls, setCharacterImageUrls] = useState<Record<string, string[]>>({});
  const [isModalImageGenerateOpen, setModalImageGenerateOpen] =
    useRecoilState(modalImageGenerateState);
  const { fetchCharacterImages } = useCharacterImageFetcher(storyId || "");
  const [prompt, setPrompt] = useState<string | null>(null);

  useEffect(() => {
    stopBackgroundMusic();
    fetchStory();
    fetchAllData();
  }, [isModalImageGenerateOpen]);

  useEffect(() => {
    if (branches.length > 0 && !selectedBranchId) {
      setSelectedBranchId(branches[0].id);
    }
  }, [branches, selectedBranchId]);

  useEffect(() => {
    const fetchAllCharacterImages = async () => {
      const uniqueCharacterIds = Array.from(
        new Set(chapters.map((chapter) => chapter.blueprint_character_id)),
      );
      const imageUrlsMap: Record<string, string[]> = {};

      for (const characterId of uniqueCharacterIds) {
        if (characterId) {
          imageUrlsMap[characterId] = await fetchCharacterImages(characterId);
        }
      }

      setCharacterImageUrls(imageUrlsMap);
    };

    if (chapters.length > 0 && storyId) {
      fetchAllCharacterImages();
    }
  }, [chapters, storyId, fetchCharacterImages]);

  async function fetchStory() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", storyId)
      .returns<Tables<"blueprint_stories">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setStory(data);
    }
  }

  const generateNewCharacterImage = (character: Tables<"blueprint_characters">) => {
    setPrompt(character.image_generation_prompt);
    setModalImageGenerateOpen(true);
  };

  async function fetchAllData() {
    if (storyId == undefined) return;
    const [beatsData, branchesData, beatSheetsData, charactersData, scenesData, chaptersData] =
      await Promise.all([
        supabase.from("blueprint_beats").select("*").order("beat_order"),
        supabase.from("blueprint_branches").select("*").eq("story_id", storyId),
        supabase.from("blueprint_beatsheets").select("*").eq("story_id", storyId),
        supabase.from("blueprint_characters").select("*").eq("blueprint_story_id", storyId),
        supabase.from("blueprint_scenes").select("*").eq("story_id", storyId).order("scene_order"),
        supabase.from("blueprint_chapters").select("*").eq("blueprint_story_id", storyId),
      ]);

    setBeats(beatsData.data || []);
    setBranches(branchesData.data || []);
    setBeatSheets(beatSheetsData.data || []);
    setCharacters(charactersData.data || []);
    setScenes(scenesData.data || []);
    setChapters(chaptersData.data || []);
  }

  async function updateScene(scene: Tables<"blueprint_scenes"> | null) {
    if (!scene) return;
    const { id, ...sceneWithoutId } = scene;

    const { error } = await supabase
      .from("blueprint_scenes")
      .update(sceneWithoutId)
      .eq("id", scene.id);

    if (error) {
      setErrorMessage("Error updating scene: " + error.message);
    } else {
      setErrorMessage("Saved! ");
    }
  }

  const renderTableContent = () => {
    const selectedBranch = branches.find((branch) => branch.id === selectedBranchId);
    if (!selectedBranch) return null;

    let currentBeat: Tables<"blueprint_beats"> | null = null;
    let currentScene: Tables<"blueprint_scenes"> | null = null;

    const rows: JSX.Element[] = [];

    beats.forEach((beat) => {
      const beatSheet = beatSheets.find(
        (bs) => bs.beat_id === beat.id && bs.branch_id === selectedBranch.id,
      );
      if (!beatSheet) return;

      const linkedScenes = scenes.filter((scene) => scene.beatsheet_id === beatSheet.id);

      linkedScenes.forEach((scene) => {
        const linkedChapters = chapters.filter((chapter) => chapter.scene_id === scene.id);

        // Skip rows without chapters
        if (linkedChapters.length === 0) return;

        linkedChapters.forEach((chapter) => {
          const character = characters.find((char) => char.id === chapter.blueprint_character_id);
          rows.push(
            <TableRow key={`${beat.id}-${scene.id}-${chapter.id}`}>
              {(!currentBeat || currentBeat.id !== beat.id) && (
                <TableCell
                  rowSpan={linkedScenes.reduce(
                    (acc, s) => acc + chapters.filter((c) => c.scene_id === s.id).length,
                    0,
                  )}
                >
                  {beat.name}
                </TableCell>
              )}
              {(!currentScene || currentScene.id !== scene.id) && (
                <TableCell rowSpan={linkedChapters.length}>
                  <Tooltip content={scene.scene_summary || ""}>
                    <span
                      className="hover:underline cursor-pointer"
                      onClick={() => {
                        setIsOverlaySceneOpen(true);
                        setSceneInOverlay(scene);
                      }}
                    >
                      {scene.name}
                    </span>
                  </Tooltip>
                </TableCell>
              )}
              <TableCell>
                <span
                  className="hover:underline cursor-pointer"
                  onClick={() => {
                    setIsOverlayChaptersOpen(true);
                    setShowChaptersForSceneId(scene.id);
                  }}
                >
                  {chapter.chapter_name}
                </span>
              </TableCell>
              <TableCell className="group/character">
                {character && (
                  <div className="flex items-center space-x-2">
                    <div className="flex space-x-2">
                      {characterImageUrls[character.id] &&
                        characterImageUrls[character.id].map((url, index) => (
                          <SimpleImageCard
                            key={`${chapter.id}-${index}`}
                            title={character.name}
                            url={url}
                          />
                        ))}
                    </div>
                    <Button
                      className="ml-6 opacity-0 group-hover/character:opacity-100 transition-opacity duration-200"
                      onClick={() => generateNewCharacterImage(character)}
                    >
                      <SparklesIcon className="h-4 w-4" />
                      Image
                    </Button>
                  </div>
                )}
                {!character && <span>No character assigned</span>}
              </TableCell>
            </TableRow>,
          );

          currentBeat = beat;
          currentScene = scene;
        });
      });
    });

    return rows;
  };

  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <GlobalSideNavLayout activePageId="PAGE_GALLERY" storyId={storyId}>
      <div className="px-4 py-8">
        <div className="top-section sticky top-0 backdrop-blur-xl z-50 rounded-md shadow-md p-6">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <h1 className="text-5xl font-bold mb-6">{story.name?.toUpperCase()}</h1>

          <div className="filter-section bg-gray-100 p-4 rounded-md">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <FormField label="Branch">
                  <Select
                    name="branch"
                    value={selectedBranchId || ""}
                    onChange={(event) => setSelectedBranchId(event.target.value)}
                  >
                    <option value="">Select a branch</option>
                    {branches.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </Select>
                </FormField>
              </div>
              <div className="w-1/2">
                <FormField label="Modifier">
                  <ImageGenerationStyleSelector
                    promptStyle={story.image_prompt_style_key || ""}
                    onPromptStyleSelected={(e) => {
                      console.log(e);
                      setStory({
                        ...story,
                        image_prompt_style_key: e,
                      });
                    }}
                  />
                </FormField>
              </div>
            </div>
          </div>
        </div>

        <Card isFullWidth={true}>
          <CardContent>
            <div className="overflow-x-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Beat</TableHeader>
                    <TableHeader>Scene</TableHeader>
                    <TableHeader>Chapter</TableHeader>
                    <TableHeader>Character</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableContent()}</TableBody>
              </Table>
            </div>
          </CardContent>
        </Card>
      </div>

      <OverlayPanel
        isOpen={isOverlayChaptersOpen}
        onClose={() => {
          setIsOverlayChaptersOpen(false);
          setShowChaptersForSceneId(null);
        }}
      >
        <ChapterList
          story={story}
          chapters={chapters}
          filterBySceneId={showChaptersForSceneId}
          onChapterUpdateOrDeleted={() => {
            fetchAllData();
          }}
        />
      </OverlayPanel>

      <OverlayPanel
        isOpen={isOverlaySceneOpen}
        onClose={() => {
          updateScene(sceneInOverlay);
          setIsOverlaySceneOpen(false);
          setSceneInOverlay(null);
          fetchAllData();
        }}
      >
        {sceneInOverlay && (
          <DynamicForm
            data={sceneInOverlay}
            setData={setSceneInOverlay}
            filterDates={false}
            filterIds={true}
          />
        )}
      </OverlayPanel>
      <GenerateImagesModal prompt={prompt} />
    </GlobalSideNavLayout>
  );
}

export default BeatsDetail;
