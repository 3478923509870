import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import { LOCALE_ENGLISH, TAB } from "../../constants/constant.ts";
import { formatDateString } from "../../utils/date.ts";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { capitalize } from "../../utils/string.ts";
import { Tables } from "../../types/database.ts";
import DeleteChapterButton from "../../components/admin/CRUD/DeleteChapterButton.tsx";
import ChapterDetail from "./ChapterDetail";
import Breadcrumb from "../../components/admin/Breadcrumb.tsx";

interface ChapterListProps {
  story: Tables<"blueprint_stories">;
  chapters: Tables<"blueprint_chapters">[];
  onChapterUpdateOrDeleted?: () => void;
  filterBySceneId?: string | null;
}

const ChapterList: React.FC<ChapterListProps> = ({
  story,
  chapters,
  onChapterUpdateOrDeleted,
  filterBySceneId,
}) => {
  const [selectedChapter, setSelectedChapter] = useState<Tables<"blueprint_chapters"> | null>(null);

  if (!story) {
    return <div>"No story"</div>;
  }

  const filteredChapters = filterBySceneId
    ? chapters.filter((chapter) => chapter.scene_id === filterBySceneId)
    : chapters;

  const handleChapterClick = (chapter: Tables<"blueprint_chapters">) => {
    setSelectedChapter(chapter);
  };

  const handleBackToList = () => {
    setSelectedChapter(null);
    if (onChapterUpdateOrDeleted) {
      onChapterUpdateOrDeleted();
    }
  };

  const breadcrumbItems = [
    { label: "Chapters", onClick: handleBackToList },
    ...(selectedChapter
      ? [
          {
            label: selectedChapter.chapter_name,
            onClick: () => {},
          },
        ]
      : []),
  ];

  if (selectedChapter) {
    return (
      <Card isFullWidth={true}>
        <CardContent>
          <Breadcrumb items={breadcrumbItems} />
          <ChapterDetail
            chapterId={selectedChapter.id}
            onChapterUpdate={() => handleBackToList()}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card isFullWidth={true}>
      <CardContent>
        <Breadcrumb items={breadcrumbItems} />
        <CardH1 label="Chapters" id="chapters" />
        <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
          <TableHead>
            <TableRow>
              <TableHeader>Name</TableHeader>
              <TableHeader>Updated at</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader></TableHeader>
              <TableHeader></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChapters.map((chapter) => (
              <TableRow
                key={chapter.id}
                onClick={() => handleChapterClick(chapter)}
                className="cursor-pointer hover:bg-zinc-950/5"
              >
                <TableCell>
                  {chapter.parent_chapter_id != null
                    ? TAB + chapter.chapter_name
                    : chapter.chapter_name}
                </TableCell>
                <TableCell>
                  {chapter.updated_at ? formatDateString(chapter.updated_at, LOCALE_ENGLISH) : ""}
                </TableCell>
                <TableCell>{capitalize(chapter.chapter_type)}</TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                  <DeleteChapterButton
                    chapterId={chapter.id}
                    chapters={chapters}
                    onChapterDeleted={onChapterUpdateOrDeleted}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default ChapterList;
