import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { audioDataQueueState, isThinkingState } from "../states/audioDataState";
import MainButton, { GAME_STATES } from "./MainButton";
import { useAudioRecorder } from "../hooks/useAudioRecorder";
import useDialogueManager from "../hooks/useDialogueManager.ts";
import useBackgroundMusicManager from "../hooks/useBackgroundMusicManager.ts";

interface AudioRecorderProps {
  onRecordingComplete: (audioBlob: Blob) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ onRecordingComplete }) => {
  const [isThinking] = useRecoilState(isThinkingState);
  const [isRecordingDisabled, setIsRecordingDisabled] = useState(true);
  const [gameState, setGameState] = useState(GAME_STATES.RECORDABLE);
  const [handFreeMode] = useState(false);
  const { startRecording, stopRecording, isRecording } = useAudioRecorder(onRecordingComplete);
  const [audioDataQueue] = useRecoilState(audioDataQueueState);
  const { isPlayingAudio } = useDialogueManager();

  const { decreaseBackgroundVolumeForRecording, resetBackgroundVolumeAfterRecording } =
    useBackgroundMusicManager();

  useEffect(() => {
    if (isRecording || handFreeMode) {
      decreaseBackgroundVolumeForRecording();
    } else {
      resetBackgroundVolumeAfterRecording();
    }
  }, [
    isRecording,
    handFreeMode,
    decreaseBackgroundVolumeForRecording,
    resetBackgroundVolumeAfterRecording,
  ]);

  useEffect(() => {
    if (!isThinking && !isPlayingAudio && audioDataQueue.length == 0) {
      setIsRecordingDisabled(false);
    } else setIsRecordingDisabled(true);
  }, [isPlayingAudio, isThinking, audioDataQueue.length]);

  useEffect(() => {
    if (!isRecordingDisabled) {
      setGameState(isRecording || handFreeMode ? GAME_STATES.RECORDING : GAME_STATES.RECORDABLE);
      if (!isRecording && handFreeMode) {
        startRecording();
      }
    } else {
      setGameState(
        isPlayingAudio ? GAME_STATES.SPEAKING : isThinking ? GAME_STATES.THINKING : gameState,
      );
    }
  }, [isRecordingDisabled, isRecording, handFreeMode, isPlayingAudio, isThinking]);

  return (
    <div className="flex flex-col justify-between h-full items-center mt-8">
      <MainButton
        onStopRecording={stopRecording}
        onStartRecording={startRecording}
        gameState={gameState}
        color={"#E5FF76"}
      />
    </div>
  );
};

export default AudioRecorder;
