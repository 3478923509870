import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables } from "../../types/database.ts";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import _ from "lodash";

export const useFetchChapters = () => {
  const [chapters, setChapters] = useState<Tables<"blueprint_chapters">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChapters = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_chapters")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (error) {
        throw new Error("Error fetching chapters: " + error.message);
      }

      setChapters(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchChapters:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchChapters, chapters, error, isLoading };
};

export const useFetchStory = () => {
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStory = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_stories")
        .select("*")
        .eq("id", storyId)
        .returns<Tables<"blueprint_stories">[]>()
        .single();

      if (error) {
        throw new Error("Error fetching story: " + error.message);
      }
      setStory(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchStory:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchStory, story, error, isLoading };
};

export const useFetchTransitions = () => {
  const [transitions, setTransitions] = useState<Tables<"blueprint_chapter_transitions">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransitions = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_chapter_transitions")
        .select("*")
        .eq("blueprint_story_id", storyId);

      if (error) {
        throw new Error("Error fetching transitions: " + error.message);
      }
      setTransitions(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchTransitions:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchTransitions, transitions, error, isLoading };
};

export const useUpdateChapter = () => {
  const [chapter, setChapter] = useState<Tables<"blueprint_chapters"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateChapter = async (
    chapterToUpdate: Tables<"blueprint_chapters">,
    onChapterUpdate?: (updatedChapter: Tables<"blueprint_chapters">) => void,
  ) => {
    setIsLoading(true);
    setError(null);

    const { id, ...chapterWithoutId } = chapterToUpdate;

    const { data, error: updateError } = await supabase
      .from("blueprint_chapters")
      .update(chapterWithoutId)
      .eq("id", id)
      .select()
      .limit(1)
      .single();

    if (updateError) {
      setError("Error updating chapter: " + updateError.message);
    } else if (data) {
      setChapter(data);
      if (onChapterUpdate) onChapterUpdate(data);
    }

    setIsLoading(false);
  };

  return { updateChapter, error, isLoading, chapter };
};

export const useDeleteChapter = (
  updateChapter: (chapter: Tables<"blueprint_chapters">) => Promise<void>,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const deleteChapter = async (
    chapterId: string,
    chapters: Tables<"blueprint_chapters">[],
    onChapterDeleted?: () => void,
  ) => {
    if (!window.confirm("Are you sure you want to delete this chapter?")) {
      console.log("Chapter deletion cancelled by user.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { error: deleteError } = await supabase
        .from("blueprint_chapters")
        .delete()
        .eq("id", chapterId);

      if (deleteError) {
        throw new Error("Error deleting chapter: " + deleteError.message);
      }

      if (chapters.length > 0) {
        const newStartingChapter = { ...chapters[0], is_starting_chapter: true };
        await updateChapter(newStartingChapter);
      }

      if (onChapterDeleted) onChapterDeleted();
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in deleteChapter:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteChapter, isLoading, error };
};

export const useFetchChapter = () => {
  const [chapter, setChapter] = useState<Tables<"blueprint_chapters"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChapter = async (chapterId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_chapters")
        .select("*")
        .eq("id", chapterId)
        .single();

      if (error) throw new Error("Error fetching chapter: " + error.message);
      setChapter(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchChapter:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchChapter, chapter, error, isLoading };
};

export const useFetchCharacter = () => {
  const [character, setCharacter] = useState<Tables<"blueprint_characters"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCharacter = async (characterId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_characters")
        .select("*")
        .eq("id", characterId)
        .single();

      if (error) throw new Error("Error fetching character: " + error.message);
      setCharacter(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchCharacter:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchCharacter, character, error, isLoading };
};

export const useFetchChapterImages = () => {
  const [chapterImages, setChapterImages] = useState<Tables<"blueprint_chapter_images">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChapterImages = async (chapterId: string | undefined) => {
    if (!chapterId) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_chapter_images")
        .select("*")
        .eq("blueprint_chapter_id", chapterId);

      if (error) throw new Error("Error fetching chapter images: " + error.message);
      setChapterImages(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchChapterImages:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchChapterImages, chapterImages, error, isLoading };
};

export const useFetchCharacterImageUrl = (
  storyId: string,
  characterId: string,
  fetchAllImages: boolean = false,
) => {
  const [imageUrl, setImageUrl] = useState<string>(
    `https://api.dicebear.com/8.x/notionists/svg?seed=${characterId}`,
  );
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const { data, error } = await supabase
          .from("blueprint_character_images")
          .select("image_url")
          .eq("blueprint_character_id", characterId)
          .order("created_at", { ascending: false });

        if (error) throw new Error("Error fetching image URL: " + error.message);

        if (data && data.length > 0) {
          const urls = data
            .map((item) =>
              item.image_url ? getSupabaseImageUrl(storyId, item.image_url) || "" : "",
            )
            .filter((url) => url !== "");

          if (urls.length > 0) {
            setImageUrl(urls[0]); // Set the most recent image as the primary imageUrl
            setImageUrls(urls);
          }
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        console.error("Error in fetchImage:", err);
      }
    };
    fetchImage();
  }, [storyId, characterId]);

  return fetchAllImages ? { imageUrl, imageUrls, error } : { imageUrl, error };
};

export const useCharacterImageFetcher = (storyId: string) => {
  const [error, setError] = useState<string | null>(null);

  const fetchCharacterImages = useCallback(
    async (characterId: string) => {
      try {
        const { data, error } = await supabase
          .from("blueprint_character_images")
          .select("image_url")
          .eq("blueprint_character_id", characterId)
          .order("created_at", { ascending: false });

        if (error) throw new Error("Error fetching image URLs: " + error.message);

        const urls = data
          .map((item) => (item.image_url ? getSupabaseImageUrl(storyId, item.image_url) || "" : ""))
          .filter((url) => url !== "");

        return urls.length > 0
          ? urls
          : [`https://api.dicebear.com/8.x/notionists/svg?seed=${characterId}`];
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        console.error("Error in fetchCharacterImages:", err);
        return [`https://api.dicebear.com/8.x/notionists/svg?seed=${characterId}`];
      }
    },
    [storyId],
  );

  return { fetchCharacterImages, error };
};

export const useFetchContextBlocks = () => {
  const [contextBlocks, setContextBlocks] = useState<Tables<"blueprint_context_blocks">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContextBlocks = async (storyId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_context_blocks")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (error) throw new Error("Error fetching context blocks: " + error.message);
      setContextBlocks(data || []);
      console.log("context", data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchContextBlocks:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchContextBlocks, contextBlocks, error, isLoading };
};

export const useFetchContextBlocksLinks = () => {
  const [contextBlocksLinks, setContextBlocksLinks] = useState<
    Tables<"blueprint_chapter_context_links">[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContextBlocksLinks = async (chapterId: string | undefined) => {
    if (!chapterId) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_chapter_context_links")
        .select("*")
        .eq("chapter_id", chapterId)
        .order("created_at", { ascending: true });

      if (error) throw new Error("Error fetching context block links: " + error.message);
      setContextBlocksLinks(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchContextBlocksLinks:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchContextBlocksLinks, contextBlocksLinks, error, isLoading };
};

type ChapterData = {
  chapter: Tables<"blueprint_chapters"> | null;
  backgroundImages: Tables<"blueprint_chapter_images">[];
  characterImages: Tables<"blueprint_character_images">[];
  contextBlocks: Tables<"blueprint_context_blocks">[];
  contextBlockLinks: Tables<"blueprint_chapter_context_links">[];
};

export const useFetchAllChapterData = () => {
  const [chapterData, setChapterData] = useState<ChapterData>({
    chapter: null,
    backgroundImages: [],
    characterImages: [],
    contextBlocks: [],
    contextBlockLinks: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchAllChapterData = async (chapterId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      // Fetch chapter data first
      const { data: chapterData, error: chapterError } = await supabase
        .from("blueprint_chapters")
        .select("*")
        .eq("id", chapterId)
        .single();

      if (chapterError) throw new Error(chapterError.message);
      if (!chapterData) throw new Error("Chapter not found");

      // Now fetch all related data in parallel
      const [
        backgroundImagesResponse,
        characterImagesResponse,
        contextBlocksResponse,
        contextBlockLinksResponse,
      ] = await Promise.all([
        supabase.from("blueprint_chapter_images").select("*").eq("blueprint_chapter_id", chapterId),
        supabase
          .from("blueprint_character_images")
          .select("*")
          .eq("blueprint_character_id", chapterData.blueprint_character_id),
        supabase
          .from("blueprint_context_blocks")
          .select("*")
          .eq("blueprint_story_id", chapterData.blueprint_story_id),
        supabase.from("blueprint_chapter_context_links").select("*").eq("chapter_id", chapterId),
      ]);

      // Check for errors in the responses
      if (backgroundImagesResponse.error) throw new Error(backgroundImagesResponse.error.message);
      if (characterImagesResponse.error) throw new Error(characterImagesResponse.error.message);
      if (contextBlocksResponse.error) throw new Error(contextBlocksResponse.error.message);
      if (contextBlockLinksResponse.error) throw new Error(contextBlockLinksResponse.error.message);

      setChapterData({
        chapter: chapterData,
        backgroundImages: backgroundImagesResponse.data || [],
        characterImages: characterImagesResponse.data || [],
        contextBlocks: contextBlocksResponse.data || [],
        contextBlockLinks: contextBlockLinksResponse.data || [],
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchAllChapterData, chapterData, isLoading, error };
};

export const useFetchUniqueImageUrls = (storyId: string) => {
  const [uniqueImageUrls, setUniqueImageUrls] = useState<(string | null)[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChaptersAndImages = async () => {
      if (!storyId) return;

      // Fetch chapters
      const { data: chapters, error: chaptersError } = await supabase
        .from("blueprint_chapters")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (chaptersError) {
        setError("Error fetching chapters: " + chaptersError.message);
        setIsLoading(false);
        return;
      }

      if (chapters.length === 0) {
        setError("No chapters found");
        setIsLoading(false);
        return;
      }

      let uniqueUrls: (string | null)[] = [];
      let attempts = 0;

      while (uniqueUrls.length === 0 && attempts < chapters.length) {
        // Select a random chapter
        const randomChapter = _.sample(chapters);
        if (!randomChapter) continue;
        // Fetch chapter and character images concurrently
        const [chapterImagesResponse, characterImagesResponse] = await Promise.all([
          supabase
            .from("blueprint_chapter_images")
            .select("*")
            .eq("blueprint_chapter_id", randomChapter.id),
          supabase
            .from("blueprint_character_images")
            .select("*")
            .eq("blueprint_character_id", randomChapter.blueprint_character_id),
        ]);

        if (chapterImagesResponse.error) {
          setError("Error fetching chapter images: " + chapterImagesResponse.error.message);
          setIsLoading(false);
          return;
        }

        if (characterImagesResponse.error) {
          setError("Error fetching character images: " + characterImagesResponse.error.message);
          setIsLoading(false);
          return;
        }

        // Combine and deduplicate image URLs
        const chapterUrls = chapterImagesResponse.data.map((image) =>
          getSupabaseImageUrl(storyId, image.image_url),
        );
        const characterUrls = characterImagesResponse.data.map((image) =>
          getSupabaseImageUrl(storyId, image.image_url),
        );
        const allImageUrls = [...chapterUrls, ...characterUrls];
        uniqueUrls = _.uniq(allImageUrls);

        attempts++;
      }

      if (uniqueUrls.length === 0) {
        setError("No unique image URLs found");
      }

      // Set unique image URLs
      setUniqueImageUrls(uniqueUrls);
      setIsLoading(false);
    };

    fetchChaptersAndImages();
  }, [storyId]);

  return { uniqueImageUrls, error, isLoading };
};
