import { atom } from "recoil";

export const lastImageEffectConfigState = atom<any>({
  key: "lastImageEffectConfigState",
  default: {},
});

export const lastTransitionEffectConfigState = atom<any>({
  key: "lastTransitionEffectConfigState",
  default: {},
});
