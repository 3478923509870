import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "@headlessui/react";
import AutoResizeTextArea from "../AutoResizeTextArea";
import { Button } from "../../catalyst/button";
import { LoadingSpinner } from "../LoadingSpinner";
import { Card, CardFooter, CardHeader, CardImage } from "../Card";
import { GeneratedImages } from "../../../types/fastApiTypes";

export interface GenerateCharacterImagesProps {
  prompt: string | null;
  status: string;
  generateImages: (payload: any) => void;
  images: GeneratedImages | null;
  setImages: React.Dispatch<React.SetStateAction<GeneratedImages | null>>;
}

const GenerateImages: React.FC<GenerateCharacterImagesProps> = ({
  prompt,
  status,
  generateImages,
  images,
  setImages,
}) => {
  const memoizedImages = useMemo(() => images, [images]);
  const [promptState, setPromptState] = useState(prompt);

  useEffect(() => {
    if (images === null && status === "" && prompt) {
      handleGenerateSingle();
    }
  }, [images, status, prompt]);

  const handleGenerateSingle = () => {
    setImages(null);
    generateImages({
      image_generation_prompt: promptState,
    });
  };

  return (
    <>
      <Dialog.Title as="h3" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Characters
      </Dialog.Title>
      <div className="flex">
        <div className="flex w-full space-x-2">
          <div className="flex-grow">
            <AutoResizeTextArea
              value={promptState || ""}
              onChange={(value) => setPromptState(value)}
            />
          </div>
          <Button onClick={() => handleGenerateSingle()}>Generate</Button>
        </div>
      </div>
      {status !== "" && <LoadingSpinner />}
      <div className="grid grid-cols-2 gap-6 mt-4 min-h-[calc(90vh-300px)] max-h-[calc(90vh-300px)] overflow-y-auto">
        {memoizedImages &&
          memoizedImages.image_urls.map((imageUrl, index) => (
            <Card key={index}>
              <CardHeader className="pb-3">
                <CardImage src={imageUrl} />
              </CardHeader>
              <CardFooter>
                <Button className="ml-6" color="light" onClick={() => console.log("coucou")}>
                  Delete
                </Button>
              </CardFooter>
            </Card>
          ))}
      </div>
    </>
  );
};

export default GenerateImages;
