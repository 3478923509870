import { useEffect, useState } from "react";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Select } from "../catalyst/select.tsx";

interface DatabaseSelectInputProps {
  table: any;
  keyColumn: string;
  labelColumn: string;
  value: string | null;
  onChange: (value: string | null) => void;
  storyId: string;
  placeholder?: string;
  conditions?: { column: string; value: string | null; notOperator?: boolean | null }[];
  addNullValueOption?: boolean; // New prop to control null value option
}

const DatabaseSelectInput: React.FC<DatabaseSelectInputProps> = ({
  table,
  keyColumn,
  labelColumn,
  value,
  onChange,
  storyId,
  placeholder = `Select ${labelColumn}`,
  conditions,
  addNullValueOption = false, // Default value is false
}) => {
  const [options, setOptions] = useState<{ key: string | null; label: string }[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      let query = supabase
        .from(table)
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });
      if (conditions) {
        conditions.forEach((condition) => {
          if (condition.value === null) {
            query = query.is(condition.column, null);
          } else {
            if (condition.notOperator) {
              query = query.not(condition.column, "eq", condition.value);
            } else {
              query = query.eq(condition.column, condition.value);
            }
          }
        });
      }

      const { data, error } = await query;

      if (error) {
        console.error("Error fetching options:", error);
      } else {
        const mappedData = data.map((item) => ({
          key: item[keyColumn],
          label: item[labelColumn],
        }));

        if (addNullValueOption) {
          mappedData.unshift({ key: null, label: "None" }); // Add null value option at the beginning
        }

        setOptions(mappedData);
      }
    };

    fetchOptions();
  }, [table, keyColumn, labelColumn, conditions, addNullValueOption]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    onChange(newValue === "null" ? null : newValue); // Handle null value conversion
  };

  return (
    <Select
      value={value === null ? "null" : value} // Handle null value representation
      onChange={handleChange}
    >
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option
          key={option.key === null ? "null" : option.key}
          value={option.key === null ? "null" : option.key}
        >
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default DatabaseSelectInput;
