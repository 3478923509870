import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tables, TablesInsert } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import {
  CHAPTER_TYPES,
  IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE,
  IMAGE_CONFIG_TYPE_CHARACTER_IMAGE,
  IMAGE_CONFIG_TYPE_TRANSITION,
  LOCALE_FRENCH,
} from "../../constants/constant.ts";
import StorageFileSelector, {
  FILE_TYPE_SOUND,
} from "../../components/admin/StorageFileSelector.tsx";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import DatabaseSelectInput from "../../components/admin/DatabaseSelectInput.tsx";
import { useCreatePlayerStoriesAndChapters } from "../../states/storyState.tsx";
import { DEV_VITE_URL } from "../../vendor/config.ts";
import FormField from "../../components/admin/FormField.tsx";
import ColorPicker from "../../components/admin/ColorPicker.tsx";
import { Checkbox, CheckboxField } from "../../components/catalyst/checkbox.tsx";
import { Description, Label } from "../../components/catalyst/fieldset.tsx";
import {
  AdvancedImageUploader,
  StagedImage,
} from "../../components/admin/AdvancedImageUploader.tsx";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import { Button } from "../../components/catalyst/button.tsx";
import { Input } from "../../components/catalyst/input.tsx";
import { Avatar } from "../../components/catalyst/avatar.tsx";
import ImageCard from "../../components/admin/ImageCard.tsx";
import { ImageDetails } from "../../components/AnimatedThreeJSImage.tsx";
import StorageFileAudioPlayer from "../../components/admin/StorageFileAudioPlayer.tsx";
import NumericStepper from "../../components/admin/NumericStepper.tsx";
import { SideNavLayout } from "../../components/admin/SideNavLayout.tsx";
import { capitalize } from "../../utils/string.ts";
import { levaStore } from "leva";
import CardEffectDescription from "../../components/admin/CardEffectDescription.tsx";
import { Badge } from "../../components/catalyst/badge.tsx";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import { useFetchAllChapterData, useFetchChapters } from "../../hooks/database/useChapter.ts";

interface ChapterDetailProps {
  chapterId?: string;
  onChapterUpdate?: (updatedChapter: Tables<"blueprint_chapters">) => void;
}

function ChapterDetail({ chapterId: propsChapterId, onChapterUpdate }: ChapterDetailProps) {
  const { chapterId: paramsChapterId } = useParams<{ chapterId: string }>();
  const navigate = useNavigate();
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [isDirty, setIsDirty] = useState(false);
  const [chapter, setChapter] = useState<Tables<"blueprint_chapters"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const createStoryAndChapter = useCreatePlayerStoriesAndChapters();
  const chapterId = propsChapterId || paramsChapterId;

  const { fetchAllChapterData, ...allChapterData } = useFetchAllChapterData();
  const { fetchChapters } = useFetchChapters();

  useEffect(() => {
    stopBackgroundMusic();
  }, [chapterId]);

  useEffect(() => {
    if (chapterId) {
      fetchAllChapterData(chapterId);
    }
  }, [chapterId]);

  useEffect(() => {
    setChapter(allChapterData.chapterData.chapter);
    if (allChapterData.chapterData.chapter?.blueprint_story_id) {
      fetchChapters(allChapterData.chapterData.chapter.blueprint_story_id);
    }
  }, [allChapterData.chapterData.chapter]);

  const isFullPageAndNotASubComponent = (): boolean => {
    return !propsChapterId;
  };

  function reload() {
    if (chapterId) fetchAllChapterData(chapterId);
  }

  async function updateChapter() {
    if (!chapter) return;
    if (chapterId == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...chapterWithoutId } = chapter;
    const { error } = await supabase
      .from("blueprint_chapters")
      .update(chapterWithoutId)
      .eq("id", chapterId)
      .select();

    if (error) {
      setErrorMessage("Error updating chapter: " + error.message);
    } else {
      if (onChapterUpdate) onChapterUpdate(chapter);
      setErrorMessage("Saved!");
      setIsDirty(false);
    }
  }

  async function createCharacter() {
    if (!chapter) return;
    const { data: voice, error: voiceError } = await supabase
      .from("blueprint_voices")
      .select("*")
      .limit(1)
      .single();

    if (!voice) return;

    const characterData: TablesInsert<"blueprint_characters"> = {
      name: "New character",
      voice_id: voice.id,
      blueprint_story_id: chapter?.blueprint_story_id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else if (voiceError) {
      setErrorMessage("Error updating character: " + voiceError);
    } else {
      console.log(data);
      const createdCharacter = data as Tables<"blueprint_characters">;
      handleNavigation(`/admin/characters/${createdCharacter.id}`);
    }
  }

  async function deleteChapterImage(imageId: string) {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const { error } = await supabase.from("blueprint_chapter_images").delete().eq("id", imageId);

      if (error) {
        console.error("Error deleting context:", error);
      } else {
        reload();
      }
    } else {
      console.log("image deletion cancelled by user.");
    }
  }

  const handleCancel = () => {
    if (!chapter) return;
    handleNavigation(`/admin/stories/${chapter.blueprint_story_id}`);
  };

  const handleTestChapter = async () => {
    if (!chapter) return;
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      await createStoryAndChapter(
        {
          user_id: user.id,
          blueprint_story_id: chapter.blueprint_story_id,
          language: LOCALE_FRENCH,
        },
        chapter?.id,
      );
      let url;
      try {
        url = process.env.VITE_URL;
      } catch (e) {
        url = DEV_VITE_URL;
      }
      window.open(url + "game", "_blank", "noopener,noreferrer");
    }
  };
  const handleNavigation = (url: string) => {
    if (isDirty) {
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to navigate away?",
      );
      if (!userConfirmed) {
        return;
      }
    }
    navigate(url);
  };
  const handleContextCheckboxChange = async (contextId: string, isChecked: boolean) => {
    console.log(contextId, isChecked);
    if (!chapter) return;
    if (isChecked) {
      const contextData: TablesInsert<"blueprint_chapter_context_links"> = {
        chapter_id: chapter?.id,
        context_block_id: contextId,
      };

      const { error } = await supabase
        .from("blueprint_chapter_context_links")
        .upsert(contextData)
        .select()
        .single();

      if (error) {
        setErrorMessage("Error creating context link: " + error.message);
      }
    } else {
      const { error } = await supabase
        .from("blueprint_chapter_context_links")
        .delete()
        .eq("context_block_id", contextId)
        .eq("chapter_id", chapter.id);
      if (error) {
        setErrorMessage("Error deleting context link: " + error.message);
      }
    }
    reload();
  };

  async function createChapterImage(stagedImage: StagedImage) {
    if (!chapter) return;

    const imageData: TablesInsert<"blueprint_chapter_images"> = {
      image_type: stagedImage.image_type,
      image_url: stagedImage.image_url,
      blueprint_chapter_id: chapter.id,
      blueprint_story_id: chapter.blueprint_story_id,
      has_original: stagedImage.has_original,
      has_depth_map: stagedImage.has_depth_map,
      has_version_without_background: stagedImage.has_version_without_background,
    };

    const { error } = await supabase
      .from("blueprint_chapter_images")
      .insert(imageData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else {
      reload();
    }
  }

  if (!chapter) {
    return <div>Loading...</div>;
  }

  return (
    <SideNavLayout showSidePanel={isFullPageAndNotASubComponent()}>
      <div>
        <div className="top-section sticky top-0 backdrop-blur-xl z-10 shadow-md p-6">
          {errorMessage && (
            <div
              className="rounded border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <h1 className="text-5xl font-bold mb-12">{capitalize(chapter.chapter_name)}</h1>

          <div className="flex justify-between items-end w-full">
            {isFullPageAndNotASubComponent() && (
              <div className="flex">
                {allChapterData.chapterData.backgroundImages.map((image) => {
                  return (
                    <Avatar
                      key={image.id}
                      className="size-28"
                      src={getSupabaseImageUrl(chapter.blueprint_story_id, image.image_url)}
                    />
                  );
                })}
                {allChapterData.chapterData.characterImages.map((image) => {
                  return (
                    <Avatar
                      key={image.id}
                      className="size-28 ml-6"
                      src={getSupabaseImageUrl(chapter.blueprint_story_id, image.image_url)}
                    />
                  );
                })}
              </div>
            )}
            <div className="flex gap-2">
              <Button onClick={handleTestChapter}>Test Chapter</Button>
              <Button onClick={updateChapter}>Save</Button>

              {isFullPageAndNotASubComponent() && <Button onClick={handleCancel}>Back</Button>}
            </div>
          </div>
        </div>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Basic" id="basic" />

            <FormField label="Chapter Name:">
              <Input
                type="text"
                id="chapter_name"
                value={capitalize(chapter.chapter_name) || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_name: e.target.value });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Scene Date:">
              <Input
                type="datetime-local"
                id="scene_date"
                value={chapter.scene_date?.toString().slice(0, 16) || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, scene_date: e.target.value ? e.target.value : null });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Chapter Setting:">
              <AutoResizeTextArea
                value={chapter.chapter_setting || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_setting: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Chapter objectives:">
              <AutoResizeTextArea
                value={chapter.chapter_objectives || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_objectives: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Chapter Traps:">
              <AutoResizeTextArea
                value={chapter.chapter_traps || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_traps: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Chapter Type:">
              <SpokableListBox
                options={CHAPTER_TYPES}
                value={
                  CHAPTER_TYPES.find((type) => type.key === chapter.chapter_type) ||
                  CHAPTER_TYPES[0]
                }
                onChange={(selectedType) => {
                  setChapter({ ...chapter, chapter_type: selectedType.key });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <div className={`w-full ${isFullPageAndNotASubComponent() ? "lg:w-1/2" : ""} `}>
              <div className="flex justify-between">
                <FormField label="Background color:">
                  <ColorPicker
                    initialColor={chapter.background_rgba_color || ""}
                    onColorChange={(color) => {
                      setChapter({ ...chapter, background_rgba_color: color });
                      setIsDirty(true);
                    }}
                  ></ColorPicker>
                </FormField>
                <FormField label="Text color:">
                  <ColorPicker
                    initialColor={chapter.text_rgba_color || ""}
                    onColorChange={(color) => {
                      setChapter({ ...chapter, text_rgba_color: color });
                      setIsDirty(true);
                    }}
                  ></ColorPicker>
                </FormField>
                <FormField label="Controls color:">
                  <ColorPicker
                    initialColor={chapter.control_rgba_color || ""}
                    onColorChange={(color) => {
                      setChapter({ ...chapter, control_rgba_color: color });
                      setIsDirty(true);
                    }}
                  ></ColorPicker>
                </FormField>
              </div>
            </div>

            <FormField label="Parent chapter (Seamless transition with parent):">
              <DatabaseSelectInput
                table="blueprint_chapters"
                keyColumn="id"
                labelColumn="chapter_name"
                value={chapter.parent_chapter_id || ""}
                onChange={(value) => {
                  setChapter({ ...chapter, parent_chapter_id: value });
                  setIsDirty(true);
                }}
                placeholder="Select a chapter"
                addNullValueOption={true}
                storyId={chapter.blueprint_story_id}
                conditions={[
                  { column: "parent_chapter_id", value: null },
                  { column: "id", value: chapter.id, notOperator: true },
                ]}
              />
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Engine character" id="character" />

            <FormField label="Character:">
              <DatabaseSelectInput
                table="blueprint_characters"
                keyColumn="id"
                labelColumn="name"
                storyId={chapter.blueprint_story_id}
                value={chapter.blueprint_character_id || ""}
                onChange={(value) => {
                  setChapter({ ...chapter, blueprint_character_id: value || "" });
                  setIsDirty(true);
                }}
                placeholder="Select a character"
              />
              {isFullPageAndNotASubComponent() && (
                <div className="mt-6">
                  <Button color={"light"} onClick={createCharacter}>
                    Create New Character
                  </Button>
                  {chapter.blueprint_character_id && (
                    <Button
                      className="ml-6"
                      onClick={() => {
                        handleNavigation(`/admin/characters/${chapter?.blueprint_character_id}`);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </FormField>
            <ul
              className={`grid gap-4 mb-8 grid-cols-2 ${isFullPageAndNotASubComponent() ? "lg:grid-cols-4" : ""}`}
            >
              {allChapterData.chapterData.characterImages.map((image) => {
                return (
                  <li key={image.id}>
                    <ImageCard
                      storyId={chapter.blueprint_story_id}
                      chapterId={chapter.id}
                      configType={IMAGE_CONFIG_TYPE_CHARACTER_IMAGE}
                      image={image as unknown as ImageDetails}
                      imageId={image.id}
                      configuration={chapter.character_speaking_image_effect}
                      isDirty={isDirty}
                    />
                  </li>
                );
              })}
            </ul>

            <FormField label="Starting Dialogue:">
              <AutoResizeTextArea
                value={chapter.starting_dialogue || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, starting_dialogue: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Engine Character Scene Example Dialogues:">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_example_dialogues || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_example_dialogues: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Engine Character Scene Agenda:">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_agenda || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_agenda: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Engine Character Scene Description:">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_description || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_description: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Engine Character Scene Arc:">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_arc || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_arc: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Sound" id="sound" />
            <FormField label="Sound Intro:">
              <StorageFileSelector
                bucketName="sound"
                storyId={chapter.blueprint_story_id}
                onStorageFileSelected={(storageFileUrl) => {
                  setChapter({ ...chapter, sound_intro: storageFileUrl });
                  setIsDirty(true);
                }}
                value={chapter.sound_intro}
              />
              {chapter.sound_intro && (
                <StorageFileAudioPlayer
                  bucketName={FILE_TYPE_SOUND}
                  storyId={chapter.blueprint_story_id}
                  fileName={chapter.sound_intro}
                />
              )}
            </FormField>

            <div className={`w-full ${isFullPageAndNotASubComponent() ? "lg:w-1/3" : ""} `}>
              <div className="flex justify-between">
                <FormField label="">
                  <NumericStepper
                    value={chapter.sound_intro_volume}
                    onChange={(e) => {
                      setChapter({ ...chapter, sound_intro_volume: e });
                      setIsDirty(true);
                    }}
                    label={"Sound intro volume"}
                  />
                </FormField>
                <FormField label="">
                  <CheckboxField className="mt-3">
                    <Checkbox
                      defaultChecked={chapter.sound_intro_is_looping || false}
                      onChange={(checked) => {
                        setChapter({ ...chapter, sound_intro_is_looping: checked });
                        setIsDirty(true);
                      }}
                    />
                    <Label>Sound Intro is looping</Label>
                  </CheckboxField>
                </FormField>
              </div>
            </div>

            <FormField label="Sound outro:">
              <StorageFileSelector
                bucketName="sound"
                storyId={chapter.blueprint_story_id}
                onStorageFileSelected={(storageFileUrl) => {
                  setChapter({ ...chapter, sound_outro: storageFileUrl });
                  setIsDirty(true);
                }}
                value={chapter.sound_outro}
              />
              {chapter.sound_outro && (
                <StorageFileAudioPlayer
                  bucketName={FILE_TYPE_SOUND}
                  storyId={chapter.blueprint_story_id}
                  fileName={chapter.sound_outro}
                />
              )}
            </FormField>
            {chapter.sound_outro && (
              <FormField label="">
                <NumericStepper
                  value={chapter.sound_outro_volume}
                  onChange={(e) => {
                    setChapter({ ...chapter, sound_outro_volume: e });
                    setIsDirty(true);
                  }}
                  label={"Sound outro volume"}
                />
              </FormField>
            )}
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Image" id="image" />

            <FormField label="Add background Image(s):">
              <AdvancedImageUploader
                storyId={chapter.blueprint_story_id}
                onUploadComplete={createChapterImage}
              />
            </FormField>
            <FormField label="Selected Images:">
              <ul
                className={`grid gap-4 mb-8 grid-cols-2 ${isFullPageAndNotASubComponent() ? "lg:grid-cols-4" : ""}`}
              >
                {allChapterData.chapterData.backgroundImages.map((image) => {
                  return (
                    <li key={image.id}>
                      <ImageCard
                        storyId={chapter.blueprint_story_id}
                        chapterId={chapter.id}
                        configType={IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE}
                        image={image as unknown as ImageDetails}
                        onDelete={deleteChapterImage}
                        imageId={image.id}
                        configuration={chapter.background_image_effect}
                        isDirty={isDirty}
                      />
                    </li>
                  );
                })}
              </ul>
            </FormField>
            <FormField label="Transition between background and character image:">
              <Button
                onClick={() => {
                  levaStore.dispose();
                  handleNavigation(
                    `/admin/image-effect/${chapter?.blueprint_story_id}/${IMAGE_CONFIG_TYPE_TRANSITION}/${chapterId}`,
                  );
                }}
              >
                Configure Effects
              </Button>
              <Badge className="ml-4">
                {JSON.stringify(chapter.character_speaking_transition_effect) || "uncool"}
              </Badge>
              {chapter.character_speaking_transition_effect && (
                <CardEffectDescription
                  configuration={chapter.character_speaking_transition_effect}
                />
              )}
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Context" id="context" />

            <ul className="grid grid-cols-2 gap-4">
              {allChapterData.chapterData.contextBlocks.map((context) => {
                const isLinked = allChapterData.chapterData.contextBlockLinks.some(
                  (link) => link.context_block_id === context.id,
                );

                return (
                  <li key={context.id}>
                    <CheckboxField>
                      <Checkbox
                        name="discoverability"
                        value="show_on_events_page"
                        checked={isLinked}
                        onChange={(isChecked) => handleContextCheckboxChange(context.id, isChecked)}
                      />
                      <Label>
                        {context.context_block_name}{" "}
                        <Link to={`/admin/contexts/${context.id}`}>(edit)</Link>
                      </Label>
                      <Description className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {context.context_block_description}
                      </Description>
                    </CheckboxField>
                  </li>
                );
              })}
            </ul>
          </CardContent>
        </Card>
        <div className="mb-2 mt-40"></div>
      </div>
    </SideNavLayout>
  );
}

export default ChapterDetail;
