import { Card, CardDescription, CardHeader, CardTitle } from "./Card.tsx";

interface CardImageProps {
  src: string;
  alt?: string;
}

const CardImage: React.FC<CardImageProps> = ({ src, alt = "" }) => (
  <img src={src} alt={alt} className="w-full h-auto object-cover mb-4" />
);

interface CustomCardProps {
  url: string;
  title: string;
  description?: string;
  imageAlt?: string;
}

const SimpleImageCard: React.FC<CustomCardProps> = ({ url, title, description, imageAlt }) => {
  return (
    <Card className="sm:col-span-2">
      <CardHeader className="pb-3">
        <CardImage src={url} alt={imageAlt} />
        <CardTitle>{title}</CardTitle>
        {description && (
          <CardDescription className="max-w-lg text-sm leading-relaxed">
            <span>{description}</span>
          </CardDescription>
        )}
      </CardHeader>
    </Card>
  );
};

export default SimpleImageCard;
