import { Json } from "../types/database.ts";
import { useLocation } from "react-router-dom";
import { useControls } from "leva";

export function findConfigEntry(
  configuration: Json,
  imageId: string,
): { [key: string]: Json } | null {
  try {
    if (Array.isArray(configuration)) {
      const entry = configuration.find(
        (item): item is { [key: string]: Json } =>
          typeof item === "object" &&
          item !== null &&
          "imageId" in item &&
          (item as { [key: string]: Json }).imageId === imageId,
      );
      return entry || null;
    }
  } catch (error) {
    console.error("Error parsing configuration:", error);
  }
  return null;
}

export function buildControlsIfAdmin(config: any) {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith("/admin");
  if (isAdmin) {
    return useControls(config);
  } else {
    return config;
  }
}

export function updateEffects(effects: Json, newEffect: { [key: string]: Json }): Json {
  if (!effects) {
    effects = [];
  }
  if (!Array.isArray(effects)) {
    throw new Error("Effects should be an array");
  }

  const index = effects.findIndex(
    (effect) =>
      typeof effect === "object" &&
      effect !== null &&
      "imageId" in effect &&
      effect.imageId === newEffect.imageId,
  );

  if (index !== -1) {
    // If imageId exists, replace the existing entry
    effects[index] = newEffect;
  } else {
    // If imageId does not exist, add the new entry
    effects.push(newEffect);
  }

  return effects;
}
