import { useCallback, useEffect, useState } from "react";
import { Connection, Edge, Node } from "reactflow";
import {
  useFetchChapters,
  useFetchStory,
  useFetchTransitions,
} from "../../../hooks/database/useChapter.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { generateEdges, generateNodes } from "./useNodesAndEdges.ts";

export const useChapterGraph = (storyId: string | undefined) => {
  const { fetchStory, story, error: storyError, isLoading: storyLoading } = useFetchStory();
  const {
    fetchChapters,
    chapters,
    error: chaptersError,
    isLoading: chaptersLoading,
  } = useFetchChapters();
  const {
    fetchTransitions,
    transitions,
    error: transitionsError,
    isLoading: transitionsLoading,
  } = useFetchTransitions();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);

  const fetchingErrorMessage = storyError || chaptersError || transitionsError;
  const isLoading = storyLoading || chaptersLoading || transitionsLoading;

  useEffect(() => {
    if (storyId) {
      fetchStory(storyId);
      fetchChapters(storyId);
      fetchTransitions(storyId);
    }
  }, [storyId]);

  useEffect(() => {
    if (chapters.length > 0) {
      setInitialNodes(generateNodes(chapters));
    }
  }, [chapters]);

  useEffect(() => {
    if (transitions.length > 0) {
      setInitialEdges(generateEdges(transitions, chapters));
    }
  }, [transitions, chapters]);

  const updateNode = useCallback((updatedChapter: Tables<"blueprint_chapters">) => {
    setInitialNodes((currentNodes) =>
      currentNodes.map((node) => {
        if (node.id === updatedChapter.id) {
          return {
            ...node,
            data: { ...node.data, label: updatedChapter.chapter_name, chapter: updatedChapter },
          };
        }
        return node;
      }),
    );
  }, []);

  const updateEdge = useCallback((updatedTransition: Tables<"blueprint_chapter_transitions">) => {
    setInitialEdges((currentEdges) =>
      currentEdges.map((edge) => {
        if (edge.id === updatedTransition.id) {
          return { ...edge, label: updatedTransition.condition };
        }
        return edge;
      }),
    );
  }, []);

  const onConnect = useCallback(
    async (params: Edge | Connection) => {
      const { source, target } = params;
      if (!source || !target || !storyId) return;

      const transitionsData: TablesInsert<"blueprint_chapter_transitions"> = {
        current_chapter_id: source,
        next_chapter_id: target,
        blueprint_story_id: storyId,
        condition: "",
      };

      const { error } = await supabase
        .from("blueprint_chapter_transitions")
        .insert(transitionsData);

      if (error) {
        setErrorMessage("Error creating transition: " + error.message);
      } else {
        setErrorMessage(null);
        fetchChapters(storyId);
        fetchTransitions(storyId);
      }
    },
    [storyId, fetchChapters, fetchTransitions],
  );

  const onEdgeDelete = useCallback(
    async (edges: Edge[]) => {
      if (edges.length < 1 || !storyId) return;
      const edge = edges[0];
      if (window.confirm("Are you sure you want to delete this transition?")) {
        const { error } = await supabase
          .from("blueprint_chapter_transitions")
          .delete()
          .eq("id", edge.id);

        if (error) {
          setErrorMessage("Error deleting transition: " + error.message);
        } else {
          fetchTransitions(storyId);
        }
      }
    },
    [storyId, fetchTransitions],
  );

  const refreshData = useCallback(() => {
    if (storyId) {
      fetchChapters(storyId);
      fetchTransitions(storyId);
    }
  }, [storyId, fetchChapters, fetchTransitions]);

  return {
    story,
    chapters,
    transitions,
    initialNodes,
    initialEdges,
    onConnect,
    onEdgeDelete,
    updateNode,
    updateEdge,
    errorMessage,
    isLoading,
    fetchingErrorMessage,
    refreshData,
  };
};
