import React from "react";
import { Button } from "../../catalyst/button.tsx";
import { useDeleteChapter, useUpdateChapter } from "../../../hooks/database/useChapter.ts";
import { Tables } from "../../../types/database.ts";

interface DeleteChapterComponentProps {
  chapterId: string | undefined;
  chapters: Tables<"blueprint_chapters">[] | undefined;
  onChapterDeleted?: () => void;
}

const DeleteChapterButton: React.FC<DeleteChapterComponentProps> = ({
  chapterId,
  chapters,
  onChapterDeleted,
}) => {
  const { updateChapter } = useUpdateChapter();
  const { deleteChapter } = useDeleteChapter(updateChapter);

  const handleDelete = () => {
    if (!chapterId) return;
    if (!chapters) return;
    deleteChapter(chapterId, chapters, onChapterDeleted);
  };

  return (
    <Button color={"light"} onClick={handleDelete}>
      Delete
    </Button>
  );
};

export default DeleteChapterButton;
