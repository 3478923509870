import React from "react";
import * as THREE from "three";

// Import your transition effects here
import {
  IMAGE_TRANSITION_EFFECT_CLEMENT,
  IMAGE_TRANSITION_EFFECT_DISSOLVE,
  IMAGE_TRANSITION_EFFECT_FADE,
  IMAGE_TRANSITION_EFFECT_GLUE,
  IMAGE_TRANSITION_EFFECT_SLIDER,
  IMAGE_TRANSITION_EFFECT_WATER,
  IMAGE_TRANSITION_EFFECT_WAVE_RIGHT,
  IMAGE_TRANSITION_EFFECT_ZOOM_DOWN,
  IMAGE_TRANSITION_EFFECT_ZOOM_RADIAL,
} from "../../constants/constant.ts";
import { FadeEffect } from "./FadeEffect.tsx";
import WaterEffect from "./WaterEffect.tsx";
import GlueEffect from "./GlueEffect.tsx";
import ZoomDownEffect from "./ZoomDownEffect.tsx";
import ZoomRadialEffect from "./ZoomRadialEffect.tsx";
import WaveRightEffect from "./WaveRightEffect.tsx";
import DissolveEffect from "./DissolveEffect.tsx";
import ClementEffect from "./ClementEffect.tsx";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { SlideEffect } from "./SliderEffect.tsx";
import { ImageEffect } from "../AnimatedThreeJSImage.tsx";

interface TransitionProviderProps {
  transitionEffect: ImageEffect;
  textures: THREE.Texture[];
  currentImageIndex: number;
  composer: EffectComposer | null;
}

const TransitionProvider: React.FC<TransitionProviderProps> = ({
  transitionEffect,
  textures,
  currentImageIndex,
  composer,
}) => {
  switch (transitionEffect.name) {
    case IMAGE_TRANSITION_EFFECT_FADE:
      return (
        <FadeEffect textures={textures} currentImageIndex={currentImageIndex} composer={composer} />
      );
    case IMAGE_TRANSITION_EFFECT_WATER:
      return (
        <WaterEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_GLUE:
      return (
        <GlueEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_ZOOM_DOWN:
      return (
        <ZoomDownEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_ZOOM_RADIAL:
      return (
        <ZoomRadialEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_WAVE_RIGHT:
      return (
        <WaveRightEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_DISSOLVE:
      return (
        <DissolveEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_CLEMENT:
      return (
        <ClementEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
          params={transitionEffect.config}
        />
      );
    case IMAGE_TRANSITION_EFFECT_SLIDER:
      return (
        <SlideEffect
          textures={textures}
          currentImageIndex={currentImageIndex}
          composer={composer}
        />
      );
    default:
      return null;
  }
};

export default TransitionProvider;
