export const CHAPTER_TYPE_INTERACTIVE = "interactive";
export const CHAPTER_TYPE_MONOLOGUE = "monologue";

export const CHAPTER_TYPE_WON_GAME = "won_game";
export const CHAPTER_TYPES = [
  { key: CHAPTER_TYPE_INTERACTIVE, value: "Interactive" },
  { key: CHAPTER_TYPE_MONOLOGUE, value: "Monologue" },
  { key: CHAPTER_TYPE_WON_GAME, value: "Last Chapter (won)" },
];

export const STORY_INTERACTIVITY_LOW = "Low Interactivity";
export const STORY_INTERACTIVITY_MEDIUM = "Medium Interactivity";
export const STORY_INTERACTIVITY_HIGH = "High Interactivity";
export const STORY_INTERACTIVITY_LEVELS = [
  { key: STORY_INTERACTIVITY_LOW, value: "Low Interactivity" },
  { key: STORY_INTERACTIVITY_MEDIUM, value: "Medium Interactivity" },
  { key: STORY_INTERACTIVITY_HIGH, value: "High Interactivity" },
];

export const STORY_BRANCHING_LOW = "Low Branching";
export const STORY_BRANCHING_MEDIUM = "Medium Branching";
export const STORY_BRANCHING_HIGH = "High Branching";
export const STORY_BRANCHING_LEVELS = [
  { key: STORY_BRANCHING_LOW, value: "Low Branching" },
  { key: STORY_BRANCHING_MEDIUM, value: "Medium Branching" },
  { key: STORY_BRANCHING_HIGH, value: "High Branching" },
];

export const IMAGE_EFFECT_PARALLAX_ON_USER_MOVE = "IMAGE_EFFECT_PARALLAX_ON_USER_MOVE";
export const IMAGE_EFFECT_SHAKING_ON_SOUND = "IMAGE_EFFECT_SHAKING_ON_SOUND";
export const IMAGE_EFFECT_LIGHTNING = "IMAGE_EFFECT_LIGHTNING";
export const IMAGE_EFFECT_COMBINED_3D = "IMAGE_EFFECT_COMBINED_3D";
export const IMAGE_EFFECT_COMBINED_DEPTHMAP = "IMAGE_EFFECT_COMBINED_DEPTHMAP";
export const IMAGE_EFFECT_NO_EFFECT = "IMAGE_EFFECT_NO_EFFECT";

export const imageEffectTypes = [
  { key: "None", value: "No effect" },
  { key: IMAGE_EFFECT_SHAKING_ON_SOUND, value: "Shaking effect (sound)" },
  { key: IMAGE_EFFECT_PARALLAX_ON_USER_MOVE, value: "Parallax" },
  { key: IMAGE_EFFECT_LIGHTNING, value: "Lightning doppelganger" },
  { key: IMAGE_EFFECT_COMBINED_3D, value: "Distortion effects" },
  { key: IMAGE_EFFECT_COMBINED_DEPTHMAP, value: "Depth effects" },
];

export const IMAGE_TRANSITION_EFFECT_FADE = "IMAGE_TRANSITION_EFFECT_FADE";
export const IMAGE_TRANSITION_EFFECT_WATER = "IMAGE_TRANSITION_EFFECT_WATER";
export const IMAGE_TRANSITION_EFFECT_GLUE = "IMAGE_TRANSITION_EFFECT_GLUE";
export const IMAGE_TRANSITION_EFFECT_ZOOM_DOWN = "IMAGE_TRANSITION_EFFECT_ZOOM_DOWN";
export const IMAGE_TRANSITION_EFFECT_ZOOM_RADIAL = "IMAGE_TRANSITION_EFFECT_ZOOM_RADIAL";
export const IMAGE_TRANSITION_EFFECT_WAVE_RIGHT = "IMAGE_TRANSITION_EFFECT_WAVE_RIGHT";
export const IMAGE_TRANSITION_EFFECT_DISSOLVE = "IMAGE_TRANSITION_EFFECT_DISSOLVE";
export const IMAGE_TRANSITION_EFFECT_CLEMENT = "IMAGE_TRANSITION_EFFECT_CLEMENT";
export const IMAGE_TRANSITION_EFFECT_SLIDER = "IMAGE_TRANSITION_EFFECT_SLIDER";
export const IMAGE_EFFECT_DISTORTION_ON_USER_MOVE_AND_3D_ON_SOUND =
  "IMAGE_EFFECT_DISTORTION_ON_USER_MOVE_AND_3D_ON_SOUND";

export const imageTransitionTypes = [
  { key: "None", value: "No effect" },
  { key: IMAGE_TRANSITION_EFFECT_FADE, value: "Fade" },
  { key: IMAGE_TRANSITION_EFFECT_WATER, value: "Water" },
  { key: IMAGE_TRANSITION_EFFECT_GLUE, value: "Glue left/right" },
  { key: IMAGE_TRANSITION_EFFECT_ZOOM_DOWN, value: "Zoom down" },
  { key: IMAGE_TRANSITION_EFFECT_ZOOM_RADIAL, value: "Zoom radial" },
  { key: IMAGE_TRANSITION_EFFECT_WAVE_RIGHT, value: "Wave to the right" },
  { key: IMAGE_TRANSITION_EFFECT_DISSOLVE, value: "Dissolve" },
  { key: IMAGE_TRANSITION_EFFECT_CLEMENT, value: "Vertical Mosaic" },
  { key: IMAGE_TRANSITION_EFFECT_SLIDER, value: "Slide on top of the background" },
];

export const IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE = "background-image";
export const IMAGE_CONFIG_TYPE_CHARACTER_IMAGE = "character-image";
export const IMAGE_CONFIG_TYPE_TRANSITION = "transition";

export const allTransitionEffects = imageTransitionTypes
  .filter((type) => type.key !== "None")
  .map((type) => type.key);

export const IMAGE_TYPE_WITHOUT_BG = "NoBackground";
export const IMAGE_TYPE_DEPTH_MAP = "DepthMap";
export const IMAGE_TYPE_ORIGINAL = "Original";

export const IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC = "IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC";
export const IMAGE_EFFECT_REACT_FROM_VOICE = "IMAGE_EFFECT_REACT_FROM_VOICE";

export const IMAGE_SCALE_MODE_COVER = "IMAGE_SCALE_MODE_COVER";
export const IMAGE_SCALE_MODE_CONTAIN = "IMAGE_SCALE_MODE_CONTAIN";
export const SOUND_BUCKET_NAME = "sound";
export const IMAGE_BUCKET_NAME = "image";

export const LOCALE_FRENCH = "french";
export const LOCALE_ENGLISH = "english";

export const TAB = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0";

export const PAGE_CHAPTER = "Chapters";
export const PAGE_STORY = "Story";
export const PAGE_BEAT = "Beats";
export const PAGE_CONTEXT = "Contexts";
export const PAGE_CHARACTER = "Characters";
export const PAGE_GALLERY = "Gallery";

export const BRANCH_NAME_MAIN = "Main";
