// components/AnimatedThreeJSImage.tsx
import React, { useEffect, useRef, useState } from "react";
import EffectProvider from "./imageEffects/EffectProvider";
import ThreeScene from "./imageEffects/ThreeScene";
import {
  IMAGE_EFFECT_NO_EFFECT,
  IMAGE_SCALE_MODE_CONTAIN,
  IMAGE_TRANSITION_EFFECT_FADE,
  IMAGE_TRANSITION_EFFECT_SLIDER,
  IMAGE_TYPE_WITHOUT_BG,
} from "../constants/constant.ts";
import { getSupabaseImageUrl } from "../utils/image.ts";
import { getRandomItem } from "../utils/array.ts";
import SimpleText, { TextPosition } from "./textEffects/simpleText.tsx";
import { Json } from "../types/database.ts";

interface ImageProps {
  storyId: string | undefined;
  backgroundImages: ImageForThree[] | null;
  characterImages: ImageForThree[] | null;
  activeImageIndex?: number;
  className?: string;
  transitionEffects?: ImageEffect[];
  disableControls?: false;
  onTextPositionChange?: (newPosition: TextPosition) => void;
  enabledDelayedFade?: boolean;
  isTextMovable?: boolean;
}

export interface ImageDetails extends TextOverlay {
  image_url: string;
  has_original: boolean;
  has_depth_map: boolean;
  has_version_without_background: boolean;
}

export interface ImageEffect {
  name: string;
  config: object | null | Json;
}

export interface TextOverlay {
  text_overlay_text?: string | undefined;
  text_overlay_color?: string | undefined;
  text_overlay_coordinates?: string | undefined;
}

export interface ImageForThree extends ImageDetails {
  effects: ImageEffect[];
}

const AnimatedThreeJSImage: React.FC<ImageProps> = ({
  storyId,
  backgroundImages,
  characterImages,
  activeImageIndex = 0,
  className = "",
  transitionEffects,
  onTextPositionChange,
  enabledDelayedFade = true,
  isTextMovable = false,
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [transitionEffect, setTransitionEffect] = useState<ImageEffect>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [backgroundImageEffect, setBackgroundImageEffect] = useState<ImageEffect>();
  const [characterImageEffect, setCharacterImageEffect] = useState<ImageEffect>();
  const threeSceneRef = useRef<{ getDimensions: () => { width: number; height: number } }>(null);
  const [activeTextOverlay, setActiveTextOverlay] = useState<TextOverlay | null>(null);

  useEffect(() => {
    if (threeSceneRef.current) {
      const dimensions = threeSceneRef.current.getDimensions();
      console.log("Dimensions from ParentComponent:", dimensions);
    }
  }, []);

  const setupTextOverlayForActiveIndex = () => {
    if (activeImageIndex == 0) {
      if (!backgroundImages) return;
      const { text_overlay_text, text_overlay_color, text_overlay_coordinates } =
        backgroundImages[0];
      setActiveTextOverlay({
        text_overlay_text,
        text_overlay_color,
        text_overlay_coordinates,
      });
    } else {
      if (!characterImages) return;
      const { text_overlay_text, text_overlay_color, text_overlay_coordinates }: TextOverlay =
        characterImages[0];
      setActiveTextOverlay({
        text_overlay_text,
        text_overlay_color,
        text_overlay_coordinates,
      });
    }
  };

  // if (!isInitialized) {
  //   console.log("backgroundImages", backgroundImages);
  //   console.log("characterImages", characterImages);
  //   console.log("storyId", storyId);
  //   console.log("activeImageIndex", activeImageIndex);
  //   console.log("transitionEffects", transitionEffects);
  // }

  useEffect(() => {
    if (activeImageIndex !== 0) return;
    const timeoutId = setTimeout(
      () => {
        const newTransitionEffect = getRandomTransitionEffect(transitionEffects);
        const newImageUrls = getImageUrls(storyId, backgroundImages, characterImages);
        setTransitionEffect(newTransitionEffect);
        setImageUrls(newImageUrls);
        setIsInitialized(true);
        setupTextOverlayForActiveIndex();
        // console.log("newImageUrls", newImageUrls);
        // console.log("newTransitionEffect", newTransitionEffect);
        // console.log("backgroundImageEffect", backgroundImageEffect);
        // console.log("characterImageEffect", characterImageEffect);
      },
      isInitialized ? 1500 : 0,
    );

    return () => clearTimeout(timeoutId);
  }, [
    activeImageIndex,
    JSON.stringify(backgroundImages),
    JSON.stringify(characterImages),
    JSON.stringify(transitionEffects),
    storyId,
  ]);

  useEffect(() => {
    setupTextOverlayForActiveIndex();
  }, [activeImageIndex]);

  const getRandomTransitionEffect = (transitionEffects?: ImageEffect[]) => {
    if (!transitionEffects) return { name: IMAGE_TRANSITION_EFFECT_FADE, config: {} };
    let effect = undefined;
    if (transitionEffects && transitionEffects.length !== 0) {
      effect = transitionEffects[Math.floor(Math.random() * transitionEffects.length)];
    }
    return effect;
  };

  const getImageUrls = (
    storyId: string | undefined,
    backgroundImages: ImageForThree[] | null,
    characterImages: ImageForThree[] | null,
  ): string[] => {
    const imageUrls: string[] = [];

    processImages(backgroundImages, false, imageUrls, storyId);
    processImages(characterImages, true, imageUrls, storyId);

    return imageUrls;
  };

  const processImages = (
    images: ImageForThree[] | null,
    isCharacter: boolean,
    imageUrls: string[],
    storyId: string | undefined,
  ) => {
    if (images && images.length > 0) {
      const image = getRandomItem(images);
      let randomEffect = getRandomItem(image?.effects);
      randomEffect = randomEffect || { name: IMAGE_EFFECT_NO_EFFECT, config: null };
      setImageEffect(isCharacter, randomEffect);

      const expandedImages = images.flatMap((image) => {
        const urls: string[] = [];
        if (
          image.has_version_without_background &&
          isCharacter &&
          randomEffect.name === IMAGE_TRANSITION_EFFECT_SLIDER
        ) {
          urls.push(IMAGE_TYPE_WITHOUT_BG + image.image_url);
        } else {
          urls.push(image.image_url);
        }
        return urls;
      });

      const randomImageUrl = getRandomItem(expandedImages);
      const imageUrl = getSupabaseImageUrl(storyId, randomImageUrl);
      if (imageUrl) imageUrls.push(imageUrl);
    }
  };

  const setImageEffect = (isCharacter: boolean, effect: ImageEffect) => {
    if (isCharacter) {
      setCharacterImageEffect(effect);
    } else {
      setBackgroundImageEffect(effect);
    }
  };

  return (
    <>
      {imageUrls.length > 0 &&
        backgroundImageEffect &&
        characterImageEffect &&
        transitionEffect && (
          <>
            <div className={className}>
              <ThreeScene ref={threeSceneRef} scaleMode={IMAGE_SCALE_MODE_CONTAIN}>
                <EffectProvider
                  backgroundImageEffect={backgroundImageEffect}
                  characterImageEffect={characterImageEffect}
                  imageUrls={imageUrls}
                  activeImageIndex={activeImageIndex}
                  transitionEffect={transitionEffect}
                  scaleMode={IMAGE_SCALE_MODE_CONTAIN}
                />
              </ThreeScene>
              {threeSceneRef.current && activeTextOverlay && (
                <SimpleText
                  canvasWidth={threeSceneRef.current.getDimensions().width}
                  canvasHeight={threeSceneRef.current.getDimensions().height}
                  text_overlay_text={activeTextOverlay.text_overlay_text || ""}
                  text_overlay_color={
                    activeTextOverlay.text_overlay_color || "rgba(255, 255, 255, 1)"
                  }
                  text_overlay_coordinates={
                    activeTextOverlay.text_overlay_coordinates ||
                    '{"percentFromLeft": 10, "percentFromTop": 10}'
                  }
                  onPositionChange={onTextPositionChange}
                  enabledDelayedFade={enabledDelayedFade}
                  isTextMovable={isTextMovable}
                />
              )}
            </div>
          </>
        )}
    </>
  );
};

export default AnimatedThreeJSImage;
