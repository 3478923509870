import React, { useEffect, useRef, useState } from "react";
import { useTexture } from "@react-three/drei";
import { extend, useThree } from "@react-three/fiber";
import TWEEN from "@tweenjs/tween.js";
import { Mesh, MeshBasicMaterial, Texture } from "three";
import {
  IMAGE_EFFECT_COMBINED_3D,
  IMAGE_EFFECT_COMBINED_DEPTHMAP,
  IMAGE_EFFECT_DISTORTION_ON_USER_MOVE_AND_3D_ON_SOUND,
  IMAGE_EFFECT_LIGHTNING,
  IMAGE_EFFECT_PARALLAX_ON_USER_MOVE,
  IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC,
  IMAGE_EFFECT_SHAKING_ON_SOUND,
  IMAGE_SCALE_MODE_CONTAIN,
  IMAGE_SCALE_MODE_COVER,
  IMAGE_TRANSITION_EFFECT_SLIDER,
} from "../../constants/constant.ts";
import { computeLayout } from "./layoutUtils.ts";
import TransitionProvider from "../transitionEffects/TransitionProvider.tsx";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import ShakingOnSoundEffect from "./ShakingOnSoundEffect.tsx";
import ParallaxOnMoveEffect from "./ParallaxOnMoveEffect.tsx";
import LightningArcEffect from "./LightningArcEffect.tsx";
import { ImageEffect } from "../AnimatedThreeJSImage.tsx";
import Combined3DEffect from "./Combined3DEffect.tsx";
import CombinedDepthEffect from "./CombinedDepthEffect.tsx";
import DistortionOnMoveAndThreeDimOnSoundEffect from "./old/DistortionOnMoveAndThreeDimOnSoundEffect.tsx";
import { useRecoilState } from "recoil";
import { dominantColorState } from "../../states/chapterState.tsx";
import { computeDominantColor } from "../../utils/color.ts";

extend({ MeshBasicMaterial });

interface EffectProviderProps {
  backgroundImageEffect: ImageEffect;
  characterImageEffect: ImageEffect;
  imageUrls: string[];
  activeImageIndex: number;
  transitionEffect: ImageEffect;
  scaleMode?: typeof IMAGE_SCALE_MODE_COVER | typeof IMAGE_SCALE_MODE_CONTAIN;
}

const EffectProvider: React.FC<EffectProviderProps> = ({
  backgroundImageEffect,
  characterImageEffect,
  imageUrls,
  activeImageIndex,
  transitionEffect,
  scaleMode = IMAGE_SCALE_MODE_CONTAIN,
}) => {
  const { size, viewport } = useThree();
  const meshRef = useRef<Mesh>(null);
  const [texturesLoaded, setTexturesLoaded] = useState(false);
  const textures = useTexture(imageUrls, () => setTexturesLoaded(true));
  const currentImageIndex = activeImageIndex !== -1 ? activeImageIndex : 0;
  const [, setDominantColor] = useRecoilState(dominantColorState);

  const applyRandomTextureVariation = (texture: Texture): Texture => {
    const minZoom = 0.7;
    const maxZoom = 1;
    const randomZoom = Math.random() * (maxZoom - minZoom) + minZoom;

    if (currentImageEffect.name === IMAGE_TRANSITION_EFFECT_SLIDER) {
      return texture;
    } else {
      const initialZoom = texture.repeat.x;
      const tween = new TWEEN.Tween({ zoom: initialZoom })
        .to({ zoom: randomZoom }, 1500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(({ zoom }) => {
          texture.repeat.set(zoom, zoom);
          texture.offset.set(0, (1 - zoom) / 2);
        });

      tween.start();

      return texture;
    }
  };

  const currentImageEffect = currentImageIndex === 0 ? backgroundImageEffect : characterImageEffect;
  const currentTexture = applyRandomTextureVariation(textures[currentImageIndex]);
  const composerRef = useRef<EffectComposer | null>(null);
  const { gl, scene, camera } = useThree();

  useEffect(() => {
    composerRef.current = new EffectComposer(gl);
    composerRef.current.addPass(new RenderPass(scene, camera));

    return () => {
      composerRef.current?.dispose();
    };
  }, [gl, scene, camera]);

  useEffect(() => {
    if (texturesLoaded && meshRef.current && currentTexture.image) {
      const imageAspect = currentTexture.image.width / currentTexture.image.height;
      const viewportAspect = viewport.width / viewport.height;

      const { width, height, position } = computeLayout({
        imageAspect,
        viewportAspect,
        viewportWidth: viewport.width,
        viewportHeight: viewport.height,
        scaleMode,
      });

      meshRef.current.scale.set(width, height, 1);
      meshRef.current.position.copy(position);
    }
  }, [size, viewport, currentTexture, scaleMode, texturesLoaded]);

  useEffect(() => {
    if (currentTexture.image) {
      const color = computeDominantColor(currentTexture);
      setDominantColor(color);
    }
  }, [currentTexture]);

  useEffect(() => {
    const animationLoop = () => {
      requestAnimationFrame(animationLoop);
      TWEEN.update();
    };

    animationLoop();
  }, []);

  return (
    texturesLoaded && (
      <mesh ref={meshRef}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial map={currentTexture} />
        <TransitionProvider
          textures={textures}
          currentImageIndex={currentImageIndex}
          transitionEffect={transitionEffect}
          composer={composerRef.current}
        />
        {currentImageEffect.name === IMAGE_EFFECT_DISTORTION_ON_USER_MOVE_AND_3D_ON_SOUND && (
          <DistortionOnMoveAndThreeDimOnSoundEffect
            composer={composerRef.current}
            texture={currentTexture}
            reactToMusicFrom={IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC}
          />
        )}
        {currentImageEffect.name === IMAGE_EFFECT_PARALLAX_ON_USER_MOVE && (
          <ParallaxOnMoveEffect
            composer={composerRef.current}
            texture={currentTexture}
            depthMapTextureURL={imageUrls[currentImageIndex]}
            params={currentImageEffect.config}
          />
        )}
        {currentImageEffect.name === IMAGE_EFFECT_LIGHTNING && (
          <LightningArcEffect
            composer={composerRef.current}
            texture={currentTexture}
            depthMapTextureURL={imageUrls[currentImageIndex]}
            params={currentImageEffect.config}
          />
        )}
        {currentImageEffect.name === IMAGE_EFFECT_SHAKING_ON_SOUND && (
          <ShakingOnSoundEffect
            composer={composerRef.current}
            texture={currentTexture}
            params={currentImageEffect.config}
          />
        )}
        {currentImageEffect.name === IMAGE_EFFECT_COMBINED_3D && (
          <Combined3DEffect
            composer={composerRef.current}
            texture={currentTexture}
            params={currentImageEffect.config}
          />
        )}
        {currentImageEffect.name === IMAGE_EFFECT_COMBINED_DEPTHMAP && (
          <CombinedDepthEffect
            composer={composerRef.current}
            texture={currentTexture}
            depthMapTextureURL={imageUrls[currentImageIndex]}
            params={currentImageEffect.config}
          />
        )}
      </mesh>
    )
  );
};

export default EffectProvider;
