// ReviewIdea.tsx
import { Dialog } from "@headlessui/react";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import React from "react";

export interface ReviewIdeaProps {
  selectedIdea: string | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<string | null>>;
}

const ReviewIdea: React.FC<ReviewIdeaProps> = ({ selectedIdea, setSelectedIdea }) => {
  return (
    <>
      <Dialog.Title as="h3" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Review best idea
      </Dialog.Title>
      <AutoResizeTextArea value={selectedIdea || ""} onChange={(value) => setSelectedIdea(value)} />
    </>
  );
};

export default ReviewIdea;
