import { createEffectComponent } from "./CreateEffectComponent.ts";

const levaParams = {
  radius: { value: 0.9, min: 0.1, max: 2, step: 0.1 },
  width: { value: 0.35, min: 0, max: 1, step: 0.01 },
  noiseScale: { value: 0.04, min: 0, max: 1, step: 0.01 },
  noiseStrength: { value: 0.04, min: 0, max: 1, step: 0.01 },
  progressScale: { value: 0.66, min: 0, max: 1, step: 0.01 },
  interpolationPower: { value: 1, min: 0.1, max: 5, step: 0.1 },
};

const ZoomRadialEffect = createEffectComponent(
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader with displacement and intensity
  `
    uniform float time;
    uniform float progress;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform sampler2D displacement;
    uniform vec4 resolution;

    uniform float radius;
    uniform float width;
    uniform float noiseScale;
    uniform float noiseStrength;
    uniform float progressScale;
    uniform float interpolationPower;

    varying vec2 vUv;

    float parabola(float x, float k) {
      return pow(4.0 * x * (1.0 - x), k);
    }

    void main() {
      vec2 newUV = (vUv - vec2(0.5)) * resolution.zw + vec2(0.5);
      vec2 aspect = resolution.wz;
      vec2 center = vec2(0.5);

      float dt = parabola(progress, 1.0);
      vec4 noise = texture2D(displacement, fract(vUv + time * noiseScale));
      float prog = progress * progressScale + noise.g * noiseStrength;
      float circ = 1.0 - smoothstep(-width, 0.0, radius * distance(center * aspect, newUV * aspect) - prog * (1.0 + width));
      float intpl = pow(abs(circ), interpolationPower);

      vec4 t1 = texture2D(texture1, (newUV - 0.5) * (1.0 - intpl) + 0.5);
      vec4 t2 = texture2D(texture2, (newUV - 0.5) * intpl + 0.5);
      vec3 color = mix(t1.rgb, t2.rgb, intpl);
      float alpha = mix(t1.a, t2.a, intpl);

      gl_FragColor = vec4(color, alpha);
    }
  `,
  () => ({
    ...levaParams,
  }),
);

export default ZoomRadialEffect;
