import React, { useCallback, useEffect, useState } from "react";
import { Select } from "../catalyst/select.tsx";

interface StylePreset {
  name: string;
  prompt: string;
  negative_prompt?: string;
  model?: string;
}

interface StyleFile {
  filename: string;
  displayName: string;
}

const styleFiles: StyleFile[] = [
  { filename: "sdxl_styles_diva.json", displayName: "Diva" },
  { filename: "sdxl_styles_fooocus.json", displayName: "Fooocus" },
  { filename: "sdxl_styles_marc_k3nt3l.json", displayName: "Marc K3nt3l" },
  { filename: "sdxl_styles_mre.json", displayName: "MRE" },
  { filename: "sdxl_styles_sai.json", displayName: "SAI" },
  { filename: "sdxl_styles_twri.json", displayName: "TWRI" },
  { filename: "flux_loras.json", displayName: "FLUX LORA" },
];

interface ImageGenerationStyleSelectorProps {
  promptStyle: string;
  onPromptStyleSelected: (promptStyle: string, model?: string) => void;
}

const ImageGenerationStyleSelector: React.FC<ImageGenerationStyleSelectorProps> = ({
  promptStyle,
  onPromptStyleSelected,
}) => {
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [presets, setPresets] = useState<StylePreset[]>([]);
  const [selectedPromptStyle, setSelectedPromptStyle] = useState<string>(promptStyle);

  useEffect(() => {
    setSelectedPromptStyle(promptStyle);
  }, [promptStyle]);

  const fetchPresets = useCallback(async (filename: string) => {
    try {
      const response = await fetch(`/styles/${filename}`);
      const data = await response.json();
      setPresets(data);
      setSelectedPromptStyle("");
    } catch (error) {
      console.error("Error fetching presets:", error);
      setPresets([]);
      setSelectedPromptStyle("");
    }
  }, []);

  useEffect(() => {
    if (selectedFile) {
      fetchPresets(selectedFile);
    } else {
      setPresets([]);
      setSelectedPromptStyle("");
    }
  }, [selectedFile, fetchPresets]);

  const handleFileChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFile(event.target.value);
  };

  const handlePresetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPresetName = event.target.value;
    const selectedPreset = presets.find((preset) => preset.name === selectedPresetName);

    if (selectedPreset) {
      setSelectedPromptStyle(selectedPreset.prompt);
      onPromptStyleSelected(selectedPreset.prompt, selectedPreset.model);
    } else {
      setSelectedPromptStyle("");
      onPromptStyleSelected("");
    }
  };

  return (
    <div className="w-full space-y-4">
      <div className="flex space-x-4">
        <Select
          id="file-select"
          value={selectedFile}
          onChange={handleFileChange}
          className="flex-1"
        >
          <option value="">Select a style list</option>
          {styleFiles.map((file) => (
            <option key={file.filename} value={file.filename}>
              {file.displayName}
            </option>
          ))}
        </Select>
        {selectedFile && (
          <Select
            id="preset-select"
            value={presets.find((preset) => preset.prompt === selectedPromptStyle)?.name || ""}
            onChange={handlePresetChange}
            className="flex-1"
          >
            <option value="">Select a style preset</option>
            {presets.map((preset) => (
              <option key={preset.name} value={preset.name}>
                {preset.name}
              </option>
            ))}
          </Select>
        )}
      </div>
      {selectedPromptStyle && (
        <div className="bg-gray-200 rounded-md p-4 mt-4">
          <div className="text-sm">{selectedPromptStyle}</div>
        </div>
      )}
    </div>
  );
};

export default ImageGenerationStyleSelector;
