import React, { useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import useBackgroundMusicManager from "../../../hooks/useBackgroundMusicManager.ts";
import {
  IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC,
  IMAGE_EFFECT_REACT_FROM_VOICE,
} from "../../../constants/constant.ts";
import useDialogueManager from "../../../hooks/useDialogueManager.ts";
import { isMobile } from "react-device-detect";

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

export interface CombinedEffectProps {
  composer: EffectComposer | null;
  texture: THREE.Texture;
  reactToMusicFrom?:
    | typeof IMAGE_EFFECT_REACT_FROM_VOICE
    | typeof IMAGE_EFFECT_REACT_FROM_BACKGROUND_MUSIC;
}

const DistortionOnMoveAndThreeDimOnSoundEffect: React.FC<CombinedEffectProps> = ({
  composer,
  texture,
  reactToMusicFrom,
}) => {
  const { size } = useThree();
  const uMouse = useRef(new THREE.Vector2(-10, -10));
  const uScale = useRef(0);
  const uShift = useRef(0);
  const { getFrequencyData } =
    reactToMusicFrom === IMAGE_EFFECT_REACT_FROM_VOICE
      ? useDialogueManager()
      : useBackgroundMusicManager();

  const combinedPass = useMemo(() => {
    const combinedEffect = {
      uniforms: {
        tDiffuse: { value: texture },
        resolution: { value: new THREE.Vector2(1, size.height / size.width) },
        uMouse: { value: uMouse.current },
        uScale: { value: uScale.current },
        uShift: { value: uShift.current },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        uniform sampler2D tDiffuse;
        uniform vec2 resolution;
        varying vec2 vUv;
        uniform vec2 uMouse;
        uniform float uScale;
        uniform float uShift;

        float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
          uv -= disc_center;
          uv *= resolution;
          float dist = sqrt(dot(uv, uv));
          return smoothstep(disc_radius + border_size, disc_radius - border_size, dist);
        }

        void main() {
          vec2 p = vUv;
          float angle = 1.55;
          vec2 offset = uShift / 4.0 * vec2(cos(angle), sin(angle));

          vec2 clampedCoords = clamp(p + offset, 0.0, 1.0);
          vec4 cr = texture2D(tDiffuse, clampedCoords);
          
          clampedCoords = clamp(p, 0.0, 1.0);
          vec4 cga = texture2D(tDiffuse, clampedCoords);
          
          clampedCoords = clamp(p - offset, 0.0, 1.0);
          vec4 cb = texture2D(tDiffuse, clampedCoords);
          
          vec4 color = vec4(cr.r, cga.g, cb.b, cga.a);

          float c = circle(vUv, uMouse, 0.0, 0.2);
          vec2 distortionOffset = vec2(c * 0.1);
          float r = texture2D(tDiffuse, p + distortionOffset).x;
          float g = texture2D(tDiffuse, p + distortionOffset).y;
          float b = texture2D(tDiffuse, p + distortionOffset).z;
          vec4 distortionColor = vec4(r, g, b, 1.);

          gl_FragColor = mix(color, distortionColor, c);
        }
      `,
    };

    return new ShaderPass(combinedEffect);
  }, [size, texture]);

  useEffect(() => {
    if (composer) {
      composer.addPass(combinedPass);
    }

    return () => {
      if (composer) {
        composer.removePass(combinedPass);
      }
    };
  }, [composer, combinedPass]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      uMouse.current.x = clientX / window.innerWidth;
      uMouse.current.y = 1 - clientY / window.innerHeight;
    };

    const handleTouchMove = (event: TouchEvent) => {
      const { clientX, clientY } = event.touches[0];
      uMouse.current.x = clientX / window.innerWidth;
      uMouse.current.y = 1 - clientY / window.innerHeight;
    };

    if (isMobile) {
      window.addEventListener("touchmove", handleTouchMove);
    } else {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener("touchmove", handleTouchMove);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  useFrame((_, _delta) => {
    const frequencyData = getFrequencyData();
    if (frequencyData) {
      const bass = frequencyData.slice(0, 10).reduce((a, b) => a + b) / 10;
      const mid = frequencyData.slice(75, 150).reduce((a, b) => a + b) / 75;
      const treble = frequencyData.slice(150, 300).reduce((a, b) => a + b) / 150;

      const bassMapped = Math.log1p(bass) * 0.05;
      const midMapped = Math.sin((mid * Math.PI) / 255) * 0.1;
      const trebleMapped = Math.sqrt(treble / 255) * 0.15;

      uScale.current = lerp(uScale.current, bassMapped + trebleMapped, 0.1);
      uShift.current = lerp(uShift.current, midMapped, 0.1);
    }

    combinedPass.uniforms.uMouse.value = uMouse.current;
    combinedPass.uniforms.uScale.value = uScale.current;
    combinedPass.uniforms.uShift.value = uShift.current;
  }, 1);

  return null;
};

export default DistortionOnMoveAndThreeDimOnSoundEffect;
