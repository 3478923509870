// layoutUtils.ts

import { Vector3 } from "three";
import { IMAGE_SCALE_MODE_CONTAIN, IMAGE_SCALE_MODE_COVER } from "../../constants/constant.ts";

interface LayoutParams {
  imageAspect: number;
  viewportAspect: number;
  viewportWidth: number;
  viewportHeight: number;
  scaleMode: typeof IMAGE_SCALE_MODE_COVER | typeof IMAGE_SCALE_MODE_CONTAIN;
}

export const computeLayout = ({
  imageAspect,
  viewportAspect,
  viewportWidth,
  viewportHeight,
  scaleMode,
}: LayoutParams): { width: number; height: number; position: Vector3 } => {
  let width = 0;
  let height = 0;

  if (scaleMode === IMAGE_SCALE_MODE_COVER) {
    if (imageAspect > viewportAspect) {
      height = viewportHeight;
      width = height * imageAspect;
    } else {
      width = viewportWidth;
      height = width / imageAspect;
    }
  } else if (scaleMode === IMAGE_SCALE_MODE_CONTAIN) {
    if (imageAspect > viewportAspect) {
      width = viewportWidth;
      height = width / imageAspect;
    } else {
      height = viewportHeight;
      width = height * imageAspect;
    }
  }

  return {
    width,
    height,
    position: new Vector3(0, 0, 0),
  };
};
