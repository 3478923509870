import { createEffectComponent } from "./CreateEffectComponent.ts";

const levaParams = {
  intensity: {
    value: 50,
    min: 1,
    max: 100,
    step: 1,
  },
  angle1: {
    value: 0.25,
    min: -1,
    max: 1,
    step: 0.01,
  },
  angle2: {
    value: -0.75,
    min: -1,
    max: 1,
    step: 0.01,
  },
  displacement: {
    value: 0.1,
    min: 0,
    max: 1,
    step: 0.01,
  },
};

const ClementEffect = createEffectComponent(
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader with displacement and intensity
  `
    uniform float time;
    uniform float progress;
    uniform float intensity;
    uniform float displacement;
    uniform float angle1;
    uniform float angle2;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform vec4 resolution;
    varying vec2 vUv;
    
    mat2 rotate(float a) {
      float s = sin(a);
      float c = cos(a);
      return mat2(c, -s, s, c);
    }
    
    const float PI = 3.1415926535897932384626433832795;
    
    void main() {
      vec2 newUV = (vUv - vec2(0.5)) * resolution.zw + vec2(0.5);
      vec2 uvDivided = fract(newUV * vec2(intensity, 1.));
    
      vec2 uvDisplaced1 = newUV + rotate(angle1 * PI) * uvDivided * progress * displacement;
      vec2 uvDisplaced2 = newUV + rotate(angle2 * PI) * uvDivided * (1. - progress) * displacement;
    
      vec4 t1 = texture2D(texture1, uvDisplaced1);
      vec4 t2 = texture2D(texture2, uvDisplaced2);
    
      vec3 color = mix(t1.rgb, t2.rgb, progress);
      float alpha = mix(t1.a, t2.a, progress);
    
      gl_FragColor = vec4(color, alpha);
    }
  `,
  () => ({
    ...levaParams,
  }),
);

export default ClementEffect;
