import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../types/database.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import FormField from "../../components/admin/FormField.tsx";
import { getSupabaseImageUrl } from "../../utils/image.ts";
import { Button } from "../../components/catalyst/button.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardH1,
  CardHeader,
  CardImage,
  CardTitle,
} from "../../components/admin/Card.tsx";
import {
  AdvancedImageUploader,
  StagedImage,
} from "../../components/admin/AdvancedImageUploader.tsx";
import { Input } from "../../components/catalyst/input.tsx";
import { capitalize } from "../../utils/string.ts";
import { Avatar } from "../../components/catalyst/avatar.tsx";
import { SideNavLayout } from "../../components/admin/SideNavLayout.tsx";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";

export default function CharacterDetail() {
  const { characterId } = useParams();
  const navigate = useNavigate();
  const [character, setCharacter] = useState<Tables<"blueprint_characters"> | null>(null);
  const [characterImages, setCharacterImages] = useState<Tables<"blueprint_character_images">[]>(
    [],
  );
  const [voices, setVoices] = useState<Tables<"blueprint_voices">[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchCharacter();
    fetchCharacterImages();
    fetchVoices();
  }, [characterId]);

  const fetchCharacter = async () => {
    if (!characterId) return;

    const { data, error } = await supabase
      .from("blueprint_characters")
      .select("*")
      .eq("id", characterId)
      .single();

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setCharacter(data);
    }
  };

  const fetchVoices = async () => {
    const { data, error } = await supabase
      .from("blueprint_voices")
      .select("*")
      .order("created_at", { ascending: true });

    if (error) {
      setErrorMessage("Error fetching voices: " + error.message);
    } else {
      setVoices(data);
    }
  };

  const fetchCharacterImages = async () => {
    if (!characterId) return;

    const { data, error } = await supabase
      .from("blueprint_character_images")
      .select("*")
      .eq("blueprint_character_id", characterId);

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setCharacterImages(data);
    }
  };

  async function updateCharacter() {
    if (!character) return;
    if (characterId == undefined) return;

    const { error } = await supabase
      .from("blueprint_characters")
      .update(character)
      .eq("id", characterId);

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      navigate(-1);
    }
  }

  async function createCharacterImage(stagedImage: StagedImage) {
    if (!character) return;

    const imageData: TablesInsert<"blueprint_character_images"> = {
      image_type: stagedImage.image_type,
      image_url: stagedImage.image_url,
      blueprint_character_id: character.id,
      blueprint_story_id: character.blueprint_story_id,
      has_original: stagedImage.has_original,
      has_depth_map: stagedImage.has_depth_map,
      has_version_without_background: stagedImage.has_version_without_background,
    };

    const { error } = await supabase
      .from("blueprint_character_images")
      .insert(imageData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else {
      fetchCharacterImages();
    }
  }

  async function deleteCharacterImage(imageId: string) {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const { error } = await supabase
        .from("blueprint_character_images")
        .delete()
        .eq("id", imageId);

      if (error) {
        console.error("Error deleting context:", error);
      } else {
        fetchCharacterImages();
      }
    } else {
      console.log("image deletion cancelled by user.");
    }
  }

  const handleCancel = () => {
    navigate(-1);
  };

  if (!character) {
    return <div>Loading...</div>;
  }

  return (
    <SideNavLayout>
      <div>
        <div className="top-section sticky top-0 backdrop-blur-xl z-50 shadow-md p-6">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <h1 className="text-5xl font-bold mb-12">{capitalize(character.name)}</h1>

          {/* Container for images and buttons */}
          <div className="flex justify-between items-end w-full">
            {/* Container for images */}
            <div className="flex">
              {characterImages.map((image) => {
                return (
                  <Avatar
                    key={image.id}
                    className="size-28 ml-6"
                    src={getSupabaseImageUrl(character.blueprint_story_id, image.image_url)}
                  />
                );
              })}
            </div>
            {/* Container for buttons */}
            <div className="flex gap-2">
              <Button onClick={updateCharacter}>Save</Button>
              <Button onClick={handleCancel}>Back</Button>
            </div>
          </div>
        </div>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Basic" id="basic" />

            <FormField label="Name:">
              <Input
                type="text"
                id="name"
                value={character.name || ""}
                onChange={(e) => setCharacter({ ...character, name: e.target.value })}
              />
            </FormField>
            <FormField label="Add Character Image(s):">
              <AdvancedImageUploader
                storyId={character.blueprint_story_id}
                onUploadComplete={createCharacterImage}
              />
            </FormField>
            <FormField label="Selected Images:">
              <ul className="grid grid-cols-4 gap-4 mb-8">
                {characterImages.map((image) => {
                  return (
                    <li key={image.id}>
                      <Card className="sm:col-span-2">
                        <CardHeader className="pb-3">
                          <CardImage
                            src={
                              getSupabaseImageUrl(character.blueprint_story_id, image.image_url) ??
                              ""
                            }
                          />
                          <CardTitle>{image.image_url}</CardTitle>
                          <CardDescription className="max-w-lg text-balance leading-relaxed">
                            <span>Has original: {image.has_original.toString()} </span>
                            <br />
                            <span>Has depth map: {image.has_depth_map.toString()}</span>
                            <br />
                            <span>
                              Has no background: {image.has_version_without_background.toString()}
                            </span>
                          </CardDescription>
                        </CardHeader>
                        <CardFooter>
                          <Button onClick={() => deleteCharacterImage(image.id)}>Delete</Button>
                        </CardFooter>
                      </Card>
                    </li>
                  );
                })}
              </ul>
            </FormField>
            <FormField label="Voice:">
              <SpokableListBox
                options={voices.map((voice) => {
                  return {
                    key: voice.id,
                    value: voice.name + " | " + voice.description + " | " + voice.tts_system,
                  };
                })}
                value={{ key: character.voice_id, value: "" }}
                onChange={(value) =>
                  setCharacter({ ...character, voice_id: value ? value.key : "" })
                }
              />
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Advanced" id="Advanced" />
            <FormField label="Example Dialogues:">
              <AutoResizeTextArea
                value={character.example_dialogues || ""}
                onChange={(e) => setCharacter({ ...character, example_dialogues: e })}
              />
            </FormField>

            <FormField label="Personality Traits:">
              <AutoResizeTextArea
                value={character.personality_traits || ""}
                onChange={(e) => setCharacter({ ...character, personality_traits: e })}
              />
            </FormField>

            <FormField label="Background:">
              <AutoResizeTextArea
                value={character.background || ""}
                onChange={(e) => setCharacter({ ...character, background: e })}
              />
            </FormField>

            <FormField label="Abilities:">
              <AutoResizeTextArea
                value={character.abilities || ""}
                onChange={(e) => setCharacter({ ...character, abilities: e })}
              />
            </FormField>

            <FormField label="Goals:">
              <AutoResizeTextArea
                value={character.goals || ""}
                onChange={(e) => setCharacter({ ...character, goals: e })}
              />
            </FormField>

            <FormField label="Relationships:">
              <AutoResizeTextArea
                value={character.relationships || ""}
                onChange={(e) => setCharacter({ ...character, relationships: e })}
              />
            </FormField>

            <FormField label="Appearance:">
              <AutoResizeTextArea
                value={character.appearance || ""}
                onChange={(e) => setCharacter({ ...character, appearance: e })}
              />
            </FormField>
          </CardContent>
        </Card>
      </div>
    </SideNavLayout>
  );
}
