import { Tables, TablesInsert, TablesUpdate } from "../types/database.ts";
import { createAtom, useTable } from "./databaseTableState.ts";
import { errorAtom } from "./storyState.tsx";
import { supabase } from "../vendor/supabaseClient.ts";
import { atom, useRecoilCallback } from "recoil";

export const playerChaptersAtom = createAtom<Tables<"player_chapters">[]>("playerChaptersAtom", []);
export const startingBlueprintChaptersAtom = createAtom<Tables<"blueprint_chapters">[]>(
  "startingChaptersAtom",
  [],
);
export const chapterPromptsElementsAtom = createAtom<Tables<"view_chapter_prompt">[]>(
  "chapterPromptsAtom",
  [],
);

export const isChapterCompleteState = atom<boolean>({
  key: "isChapterComplete",
  default: false,
});

export const isGameLostState = atom<boolean>({
  key: "isGameLost",
  default: false,
});

export const isChapterStartedState = atom<boolean>({
  key: "isChapterStarted",
  default: false,
});

export const useCreatePlayerChapters = () => {
  const { createItem } = useTable<
    Tables<"player_chapters">,
    TablesInsert<"player_chapters">,
    TablesUpdate<"player_chapters">
  >("player_chapters", playerChaptersAtom, errorAtom);
  return createItem;
};

export const useUpdatePlayerChapter = () => {
  const { updateItem } = useTable<
    Tables<"player_chapters">,
    TablesInsert<"player_chapters">,
    TablesUpdate<"player_chapters">
  >("player_chapters", playerChaptersAtom, errorAtom);

  return updateItem;
};

export const useSelectStartingBlueprintChapters = () => {
  return useRecoilCallback(({ set }) => async (blueprintStoryId: string) => {
    try {
      const { data, error } = await supabase
        .from("blueprint_chapters")
        .select("*")
        .eq("blueprint_story_id", blueprintStoryId)
        .is("is_starting_chapter", true);

      if (error) {
        console.error("Error fetching data:", error);
        set(errorAtom, error.message);
        return null;
      }
      set(startingBlueprintChaptersAtom, data);
      return data;
    } catch (error) {
      console.error("Unexpected error in useSelectStartingBlueprintChapters:", error);
      set(errorAtom, "Unexpected error occurred");
      return null;
    }
  });
};

export const useSelectChaptersPrompt = () => {
  return useRecoilCallback(({ set }) => async (playerStoryId: string) => {
    try {
      const { data, error } = await supabase
        .from("view_chapter_prompt")
        .select("*")
        .eq("player_story_id", playerStoryId);

      if (error) {
        console.error("Error fetching data:", error);
        set(errorAtom, error.message);
        return null;
      }

      set(chapterPromptsElementsAtom, data);

      return data; // Returns the fetched data
    } catch (error) {
      console.error("Unexpected error in useSelectChapterPrompt:", error);
      set(errorAtom, "Unexpected error occurred");
      return null;
    }
  });
};

export const dominantColorState = atom<string>({
  key: "dominantColorState",
  default: "",
});
