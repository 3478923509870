import { createEffectComponent } from "./CreateEffectComponent.ts";

const levaParams = {
  noiseScale: { value: 0.04, min: 0, max: 1, step: 0.01 },
  noiseStrength: { value: 0.06, min: 0, max: 1, step: 0.01 },
  progressScale: { value: 0.8, min: 0, max: 1, step: 0.01 },
  progressOffset: { value: 0.05, min: 0, max: 1, step: 0.01 },
  transitionPower: { value: 10, min: 1, max: 20, step: 1 },
  direction: {
    value: 0,
    options: [0, 1],
  },
};

const WaveRightEffect = createEffectComponent(
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader with displacement and intensity
  `
    uniform float time;
    uniform float progress;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform sampler2D displacement;
    uniform vec4 resolution;
    
    uniform float noiseScale;
    uniform float noiseStrength;
    uniform float progressScale;
    uniform float progressOffset;
    uniform float transitionPower;
    uniform int direction;

    varying vec2 vUv;

    vec2 mirrored(vec2 v) {
      vec2 m = mod(v, 2.0);
      return mix(m, 2.0 - m, step(1.0, m));
    }

    void main() {
      vec2 newUV = (vUv - vec2(0.5)) * resolution.zw + vec2(0.5);
      vec4 noise = texture2D(displacement, mirrored(newUV + time * noiseScale));
      float prog = progress * progressScale - progressOffset + noise.g * noiseStrength;
      
      float xOffset = direction == 1 ? 1.0 - vUv.x : vUv.x;
      float intpl = pow(abs(smoothstep(0.0, 1.0, (prog * 2.0 - xOffset + 0.5))), transitionPower);

      vec4 t1 = texture2D(texture1, (newUV - 0.5) * (1.0 - intpl) + 0.5);
      vec4 t2 = texture2D(texture2, (newUV - 0.5) * intpl + 0.5);
      vec3 color = mix(t1.rgb, t2.rgb, intpl);
      float alpha = mix(t1.a, t2.a, intpl);
      
      gl_FragColor = vec4(color, alpha);
    }
  `,
  () => ({
    ...levaParams,
  }),
);

export default WaveRightEffect;
