import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tables } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import { Button } from "../../components/catalyst/button.tsx";

interface TransitionDetailProps {
  transitionId?: string;
  onTransitionUpdate?: (updatedTransition: Tables<"blueprint_chapter_transitions">) => void;
}

function TransitionDetail({
  transitionId: propsTransitionId,
  onTransitionUpdate,
}: TransitionDetailProps) {
  const { transitionId: paramsTransitionId } = useParams<{ transitionId: string }>();
  const [transition, setTransition] = useState<Tables<"blueprint_chapter_transitions"> | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const transitionId = propsTransitionId || paramsTransitionId;

  useEffect(() => {
    fetchTransition();
  }, [transitionId]);

  async function fetchTransition() {
    if (transitionId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_chapter_transitions")
      .select(`*`)
      .eq("id", transitionId)
      .single();

    if (error) {
      setErrorMessage("Error fetching transition: " + error.message);
    } else {
      setTransition(data);
    }
  }

  async function updateTransition() {
    if (!transition) return;
    if (transitionId == undefined) return;

    const { error } = await supabase
      .from("blueprint_chapter_transitions")
      .update({ condition: transition.condition })
      .eq("id", transitionId);

    if (error) {
      setErrorMessage("Error updating transition: " + error.message);
    } else {
      if (onTransitionUpdate) onTransitionUpdate(transition);
      setErrorMessage("Saved!");
    }
  }

  if (!transition) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {errorMessage && (
        <div
          className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
          onClick={() => setErrorMessage(null)}
        >
          {errorMessage}
        </div>
      )}

      <h1 className="text-3xl font-bold mb-4">Transition Detail</h1>

      <div className="mb-8">
        <label htmlFor="condition" className="block font-bold mb-2">
          Condition:
        </label>
        <AutoResizeTextArea
          value={transition.condition || ""}
          onChange={(e) => setTransition({ ...transition, condition: e })}
        ></AutoResizeTextArea>
      </div>

      <Button onClick={updateTransition}>Save</Button>
    </div>
  );
}

export default TransitionDetail;
